import React from "react";
import { ChallengeOutlinedButton,ChallengeFinalButton } from "../../styles/CommonStyles";
import { Link, useHistory } from "react-router-dom";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  challenge_question_dialog_footer:{
    '&.submitResponse':{
      maxWidth:'947px',
      zIndex: '2',
    }
  }
}));



export default function ChallengeQuestionDialogFooter(props) {
  const classes = useStyles();
  const history = useHistory();
  const {handlePrev,handleNext,hasCancelSubmitBtn,activeModalNumber} = props;

  if (props.hasPrevNext) {
    return (
      <>
      
        <div className={`${classes.challenge_question_dialog_footer} challenge_question_dialog_footer bottom-0 w-full flex items-center justify-between p-0 uppercase ${activeModalNumber === 9 ? "bg-concrete md:bg-transparent md:mx-auto submitResponse md:px-5 md:py-10 md:relative absolute" : activeModalNumber === 10 ? ' md:pt-8 pt-6 pb-6'  : activeModalNumber === 5 ? 'bg-white md:py-14 md:px-10 md:static absolute' : " bg-concrete md:bg-transparent md:p-10  md:static absolute" }`} >
          {
            (activeModalNumber !== 9) && (activeModalNumber !== 10) ?
            <Button onClick={() => history.push("/")} className={`MuiButton-contained lg:hidden text-bigstone w-1/2 md:w-52 uppercase font-medium inline-flex items-center justify-center bg-alabaster gesta text-base md:text-lg rounded-none py-3.5 px-3 md:px-7 tracking-normal`} >
              <CloseIcon className='mr-2 sm:mr-6' style={{ fontSize: 16 }}/>
              End audit
            </Button> : ''
          }
          {
            (activeModalNumber === 2 || activeModalNumber === 6) ? <ChallengeOutlinedButton variant="outlined" className={`MuiButton-contained md:shadow-none w-1/2 md:w-52 prev text-sm bg-alabaster text-bigstone border-0 py-3.5 px-4 md:px-7 text-base md:text-lg `} onClick={handlePrev}>
            <KeyboardArrowLeftIcon/> Previous
            
          </ChallengeOutlinedButton> :activeModalNumber !== 10 && activeModalNumber !== 9 ? <ChallengeOutlinedButton variant="outlined" className={`MuiButton-contained md:shadow-none w-1/2 md:w-52 prev text-sm bg-alabaster text-bigstone border-0 py-3.5 px-4 md:px-7 text-base md:text-lg `} onClick={handlePrev}>
          <KeyboardArrowLeftIcon/>
          {
              hasCancelSubmitBtn ? '' : 'Previous'
            }
            
          </ChallengeOutlinedButton> : activeModalNumber === 9 ? <ChallengeOutlinedButton variant="outlined" className={`MuiButton-contained md:shadow-none w-1/2 md:w-auto prev text-sm bg-alabaster text-bigstone border-0 py-3.5 px-3 md:px-7 text-base md:text-lg `} onClick={handlePrev}>
          <KeyboardArrowLeftIcon/>
          {
              hasCancelSubmitBtn ? 'Time Allocation' : ''
            }
            
          </ChallengeOutlinedButton> :" "
          }
          
          {
            hasCancelSubmitBtn && activeModalNumber !== 9 ? 
            <ChallengeOutlinedButton variant="outlined" className={`MuiButton-contained md:shadow-none w-1/2 md:w-52 next text-sm py-3.5 px-4 md:px-7 bg-bigstone text-alabaster border-0 text-base md:text-lg`} onClick={handleNext}>
                Submit 
              <KeyboardArrowRightIcon/>
            </ChallengeOutlinedButton>
            : activeModalNumber === 9 ? 
            <ChallengeOutlinedButton variant="outlined" className={`MuiButton-contained md:shadow-none w-1/2 md:w-auto next text-sm py-3.5 pl-3 pr-2 md:px-7 bg-bigstone text-alabaster border-0 text-base md:text-lg`} onClick={handleNext}>
                Submit & Continue 
              <KeyboardArrowRightIcon/>
            </ChallengeOutlinedButton>
            : activeModalNumber === 10 ? 
            <ChallengeOutlinedButton variant="outlined" className={`MuiButton-contained md:shadow-none w-1/2 md:w-52 next text-sm py-3.5 px-4 md:px-7 bg-bigstone text-alabaster border-0 text-base md:text-lg relative left-2/4 relative z-20`} style={{transform:'translateX(-50%)'}} onClick={handleNext}>
                VIEW REPORT 
            </ChallengeOutlinedButton>
            : 
            <ChallengeOutlinedButton variant="outlined" className={`MuiButton-contained md:shadow-none w-1/2 md:w-52 next text-sm py-3.5 px-4 md:px-7 bg-bigstone text-alabaster border-0 text-base md:text-lg`} onClick={handleNext}>
                NEXT 
              <KeyboardArrowRightIcon/>
            </ChallengeOutlinedButton>
          }
        </div>

      </>
    );
  }
  return (
    <div className="challenge_question_dialog_footer  flex items-center justify-center mt-10 ">
        <ChallengeFinalButton className='font-medium text-xs md:text-base' >
          <Link to='/dashboard/'>RETURN TO DASHBOARD</Link>
            
        </ChallengeFinalButton>
    </div>
  );
}
