import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/auth";
import { SessionContext } from "../context/session";
import { useHistory } from "react-router-dom";
import { useStyles } from "../styles/HomeDashboardStyles";
import backgroundImage from "../assets/imgaes/bg-home.jpg";
import { useQuery, useLazyQuery } from "@apollo/client";
import Moment from "moment";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import { StyledTableCell } from "../styles/ClientListStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import json2mq from "json2mq";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import DashboardContainer from "../components/homeDashboard/HomeDashboardContainer";

// Custom
import Sidebar from "../components/homeDashboard/Sidebar";
import { ChallengeButtonStyle } from "../styles/CommonStyles";
import PreLoader from "../components/PreLoader";
import { GET_COMPLETED_SELF_AUDIT_LIST, GET_CLIENT } from "../util/graphql";

// Coach Placeholder Image

function HomeDashboard() {
  const classes = useStyles();
  const { user, logout } = useContext(AuthContext);
  const sessionContext = useContext(SessionContext);
  const [reportsActive, setReportsActive] = useState(false);
  const [isFirstSelfAudit, setIsFirstSelfAudit] = useState(true);
  const [showDashboard, setShowDashboard] = useState(false);
  const [userSessionId, setUserSessionId] = useState(null);
  const [openDialog, setOpenDialog] = useState(true);
  
  const isMobile = useMediaQuery(
    json2mq({
      maxWidth: 767,
    })
  );

  var host = window.location.origin + "/coaches/";
  const avatar = user.coachId ? user.coachId.avatar : "";
  if (avatar && avatar !== "" && avatar.substring(0, 4) === "http") {
    host = "";
  } else if (host.substring(7, 12) === "local")
    host = "http://localhost:5001/coaches/";

  const sessionStatus = sessionContext.session.status;

  useEffect(() => {
    if (sessionStatus === "COMPLETED") {
      setReportsActive(true);
      setOpenDialog(false);
    }
  }, [sessionStatus]);

  let history = useHistory();

  const startSelfAuditHandler = () => {
    history.push("/general-instruction");
  };

  const viewReportHandler = (sessionId) => {
    setUserSessionId(sessionId);
    getClientData();
  };

  const [getClientData] = useLazyQuery(GET_CLIENT, {
    variables: { id: user.id, sessionId: userSessionId },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      history.push("/reports", { clientData: data.getClient });
    },
  });

  const { data: getClient } = useQuery(GET_CLIENT, {
    variables: { id: user.id },
    fetchPolicy: "network-only",
  });

  const [continueSelfAudit, setContinueSelfAudit] = useState(false);
  useEffect(() => {
    if (
      getClient &&
      getClient.getClient &&
      getClient.getClient.currentResultId &&
      getClient.getClient.currentResultId.questionAnsweredTill > 0 &&
      getClient.getClient.currentSessionId.status !== "COMPLETED"
    ) {
      setContinueSelfAudit(true);
    }
  }, [getClient]);

  const { data, loading: loadingSelfAuditList } = useQuery(
    GET_COMPLETED_SELF_AUDIT_LIST,
    {
      variables: { id: user.id },
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (!loadingSelfAuditList) {
      if (data && data.getCompletedSelfAuditList) {
        const sessionId = user.currentSessionId.id ?? user.currentSessionId._id
        if (
          data.getCompletedSelfAuditList.length > 1 ||
          (sessionId !== data.getCompletedSelfAuditList[0]?.id &&
            data.getCompletedSelfAuditList.length === 1)
        ) {
          setIsFirstSelfAudit(false);
          setShowDashboard(true);
        }
      }
    }
  }, [loadingSelfAuditList, data, user]);

  let welcomeText = "";
  let startSelfAuditButtonText = "";
  if (isFirstSelfAudit || reportsActive) {
    startSelfAuditButtonText = "Start your Self-Audit";
    welcomeText = (
      <h2
        className="text-dovegray gesta challenge_text mb-7 md:mb-12 text-center"
      >
        The Seven Navigation Challenges
      </h2>
    );
  } else {
    if (!continueSelfAudit) {
      startSelfAuditButtonText = "Start New Self-Audit";
      welcomeText = (
        <>
          <div className="md:pt-4">
            <h3 className="mb-7 text-black gesta-medium leading-tight" style={{fontSize: `${isMobile ? '34px' : '32px'}`}}>
              It’s time to assess your progress in your journey so far
            </h3>
            <p className=" text-black text-lg mb-8">
              Let’s see how you’ve changed by completing the self-audit again
            </p>
          </div>
        </>
      );
    } else {
      startSelfAuditButtonText = "Continue New Self-Audit";
      welcomeText = (
        <>
          <div className="md:pt-4">
            <h3 className="mb-7 text-black gesta-medium leading-tight" style={{fontSize: `${isMobile ? '34px' : '32px'}`}}>
              Looks like you’ve started a new self-audit
            </h3>
            <p className=" text-black text-lg mb-8">
              Pick up where you left off and see how much you’ve grown since your last audit
            </p>
          </div>
        </>
      );
    }
  }

  if (loadingSelfAuditList) {
    return <PreLoader />;
  }

  const closeDialogHandler = () => {
    setOpenDialog(false);
  };

  return (
    <>
      {!showDashboard ? (
        <section className="mx-auto flex justify-between w-full flex-col lg:flex-row relative">
          <Sidebar user={user} logout={logout} reportsActive={reportsActive} />

          <DashboardContainer
            bgImage={`${backgroundImage}`}
            fullScreen={true}
            showDashboard={showDashboard}
          >
            <div
              className="card justify-center sm:justify-start bottom-0 flex-wrap content-end flex-col max-w-xl basis-full grow-0 shrink-0 mx-auto"
              style={{ width: "calc(100% - 72px)" }}
            >
              <div className="flex w-full  justify-center flex-col items-center xl:items-stretch md:flex-row mb-2 lg:mb-12">
                <div
                  className={`bg-transparent  lg:top-20 lg:left-8 top-14 left-8   flex  items-center absolute justify-start  md:mb-0 lg:w-72 sm:mr-1 mr-0   ${classes.cardWhite}`}
                ></div>
                <div
                  className={`w-full bg-white lg:px-12 px-6 md:px-10 lg:py-12 pt-6 pb-9 mt-11 md:mt-0 flex items-center justify-end flex-col shadow-md text-center ${classes.cardWhite} `}
                >
                  {welcomeText}
                  {reportsActive ? (
                    <ChallengeButtonStyle
                      onClick={() => {
                        viewReportHandler(null);
                      }}
                      className="text-alabaster home_intro uppercase text-lg  gesta-medium leading-normal py-2.5 md:py-3.5 bg-bigstone cursor-pointer"
                    >
                      Go to your Report
                    </ChallengeButtonStyle>
                  ) : (
                    <ChallengeButtonStyle
                      onClick={startSelfAuditHandler}
                      className="text-alabaster home_intro uppercase text-lg  gesta-medium leading-normal py-2.5 md:py-3.5 bg-bigstone cursor-pointer w-full md:w-auto"
                      disabled={sessionStatus === "CANCELLED" ? true : false}
                    >
                      {startSelfAuditButtonText}
                    </ChallengeButtonStyle>
                  )}
                </div>
              </div>
            </div>
          </DashboardContainer>
        </section>
      ) : (
        <section className="mx-auto flex justify-between w-full flex-col lg:flex-row relative">
          <Sidebar user={user} logout={logout} reportsActive={reportsActive} />

          <Dialog
            open={openDialog}
            // TransitionComponent={Transition}
            keepMounted
            onClose={closeDialogHandler}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            maxWidth="md"
            className="homeDashboardModal z-50"
          >
            <DialogContent className="p-0">
              <div
                className={`w-full bg-white lg:px-12 px-6 md:px-10 lg:py-12 pt-6 pb-9 mt-11 md:mt-0 flex items-center justify-end flex-col text-center ${classes.cardWhite} `}
              >
                {welcomeText}
                {reportsActive ? (
                  <>
                    <ChallengeButtonStyle
                      onClick={() => {
                        viewReportHandler(null);
                      }}
                      className="text-alabaster home_intro uppercase text-lg  gesta-medium leading-normal py-2.5 md:py-3.5 bg-bigstone cursor-pointer"
                    >
                      Go to your Report
                    </ChallengeButtonStyle>
                    {isFirstSelfAudit ? (
                      ""
                    ) : (
                      <button
                        onClick={closeDialogHandler}
                        className="gesta-medium text-lg text-bigstone mt-4"
                      >
                        Go To Dashboard
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    <div className="w-full">
                      <ChallengeButtonStyle
                        onClick={startSelfAuditHandler}
                        className="text-alabaster home_intro uppercase text-lg  gesta-medium leading-normal py-4 bg-bigstone cursor-pointer w-full"
                        disabled={sessionStatus === "CANCELLED" ? true : false}
                      >
                        {startSelfAuditButtonText}
                      </ChallengeButtonStyle>
                      {isFirstSelfAudit ? (
                        ""
                      ) : (
                        <button
                          onClick={closeDialogHandler}
                          className="gesta-medium text-lg text-bigstone mt-4"
                        >
                          Go To Dashboard
                        </button>
                      )}
                    </div>
                  </>
                )}
              </div>
            </DialogContent>
          </Dialog>

          <DashboardContainer fullScreen={true} showDashboard={showDashboard}>
            <div
              style={{ background: "#f2f2f1" }}
              className={`card w-full lg:px-12 px-7 md:px-10 lg:pb-12 md:pt-6 pt-20 md:mt-0 mt-1 pb-9 lg:pt-14 flex md:items-end md:flex-row flex-col justify-between shadow-none ${classes.cardWhite} `}
            >
              <div className="uppercase md:mb-10 mb-12">
                <h6 className="text-base md:text-lg gesta-bold text-mineshaft">
                  The Seven Navigation Challenges
                </h6>
                <h2 className="text-mineshaft font-bold rajdhani challenge_text">
                  Your Journey
                </h2>
              </div>
            </div>
            <div className="lg:px-12 px-0 md:px-10 md:-mt-10 -mt-12">
              <div className="bg-white mx-7 md:mx-0 md:py-7 md:pl-12 md:pr-9 px-5 pt-6 pb-4 md:flex md:items-center md:justify-between md:flex-row">
                <div>
                  <h6
                    className="gesta-medium md:text-lg uppercase"
                    style={{ fontSize: "15px" }}
                  >
                    {reportsActive ? "Latest Self-Audit" : "Next Steps"}
                  </h6>
                  <h3 className="gesta-medium md:text-3xl text-xl mb-4 md:mb-0">
                    The Seven Navigation Challenges
                  </h3>
                </div>
                <div>
                  {reportsActive ? (
                    <ChallengeButtonStyle
                      className="text-alabaster home_intro uppercase text-lg  gesta-medium leading-normal mt-4 md:mt-0 py-2 md:py-2.5 md:px-7 md:w-auto w-full bg-bigstone cursor-pointer"
                      onClick={() => {
                        viewReportHandler(null);
                      }}
                    >
                      VIEW REPORT
                    </ChallengeButtonStyle>
                  ) : (
                    <ChallengeButtonStyle
                      onClick={startSelfAuditHandler}
                      className="text-alabaster home_intro uppercase text-lg  gesta-medium leading-normal py-4 md:py-3.5 bg-bigstone cursor-pointer w-full md:w-auto"
                      disabled={sessionStatus === "CANCELLED" ? true : false}
                    >
                      {startSelfAuditButtonText}
                    </ChallengeButtonStyle>
                  )}
                </div>
              </div>
              <div className="bg-white pt-9 md:pt-12 pb-8 md:mt-3">
                <h3 className="gesta-medium text-xl md:text-3xl mb-3 md:mb-8 px-7 md:px-0">
                  Self-Audit History
                </h3>
                <TableContainer>
                  <Table className="md:pt-4">
                    <TableBody>
                      {data.getCompletedSelfAuditList.map((list, i) => {
                        return (
                          <TableRow key={i}>
                            <StyledTableCell
                              className="md:text-lg text-base pl-7 pr-3 py-2 whitespace-normal md:px-6 md:pb-5 md:pt-4 leading-tight md:leading-normal border-concrete md:border-t md:border-solid"
                              align="left"
                            >
                              Founder's Journey Self-Audit -{" "}
                              {Moment(list.resultId.completedOn).format(
                                "MMMM D, YYYY"
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              className="md:text-lg text-base pr-7 pl-3 py-2 md:px-6 md:pb-5 md:pt-4 leading-tight md:leading-normal border-concrete md:border-t md:border-solid"
                              align="right"
                            >
                              <span
                                className="text-bigstone gesta-medium link"
                                onClick={() => {
                                  viewReportHandler(list.id);
                                }}
                              >
                                View Report
                              </span>
                            </StyledTableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </DashboardContainer>
        </section>
      )}
    </>
  );
}

export default HomeDashboard;
