import { makeStyles } from "@material-ui/core/styles";


export const OverViewStyle = makeStyles((theme) => ({
    tabButton: {
        fontFamily: "Gesta",
        fontSize: "18px",
        fontWeight: '500',
        lineHeight: "22px",
        color: "#121e31",
        position: 'relative',
        transition: 'all .30s linear',
        letterSpacing: '0',
        padding: '8px 22px',
        '&:before': {
            content: `''`,
            position: 'absolute',
            left: '50%',
            bottom: '-12px',
            width: '0%',
            height: '4px',
            backgroundColor: '#121e31',
            transition: 'all .30s linear',

        },
        '&:hover': {
            color: '#121e31',
            backgroundColor: 'transparent',
        },
        '&.active': {
            color: '#121e31',
            backgroundColor: 'transparent',
            fontWeight: '700',
            letterSpacing: '0',
            '&:before': {
                width: '100%',
                left: '0%',
            }
        },
        '@media (max-width:767px)':{
            fontSize: '14px',
            padding: '6px 12px',
            display: 'block',
            whiteSpace: 'nowrap',
            // flex: '1 1 33.33%',
            '&:last-child':{
                '&.active':{
                    // flex: '1 1 48%',
                }
            }
        }
    },
    overviewSave: {
        backgroundColor: '#636363',
        color: '#fff'
    }


}), {index: 1});