import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { UPLOAD_FILE, REMOVE_FILE } from '../util/graphql';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

export default function ImageCrop({ aChg, coach }) {
  const [img, setImg] = useState('');
  const [host, setHost] = useState(window.location.origin + '/coaches/');

  useEffect(() => {
    if (host.substring(7,12) === "local") {
      setHost('http://localhost:5001/coaches/');
    }
    if (coach.avatar) {
      if (coach.avatar.substring(0,4) === "http") {
        setHost('');
      }
      if (coach.avatar !== "") {
        setImg(coach.avatar);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coach])

  const [uploadFile] = useMutation(UPLOAD_FILE, {
    onCompleted: data => {
      setImg(data.uploadFile.url);
    }
  });

  const [removeFile] = useMutation(REMOVE_FILE, {
    onCompleted: data => {
      if (data.removeFile) {
        setImg('');
      }
    }
  });

  const onChange = e => {
    // data for submit
    const file = e.target.files[0];
    if (!file) return;
    // update avatar
    uploadFile({ variables: { file: file }});
    aChg(e.target.files[0].name);
  };

  const onClick = () => {
    // data for submit
    if (coach.id && coach.id !== '') {
      removeFile({ variables: { id: coach.id }});
    }
  };

  return (
    <>
      <div className='profileImageBox border-none my-3'>
            {
              img ? 
              <>
                <span className='h-32 w-32 bg-gray-100 rounded-full inline-flex'>
                  <img src={host + img} className='rounded-full h-32 w-32 max-w-full' alt="Coach" />
                </span>
                <button onClick={onClick}>
                  Remove
                </button>
              </> : ''
            }
          </div>
          <Box className='relative inline-flex'>
            <input type="file" 
            id='upload_file' 
            name="file"
            onChange={onChange}
            className='file_uploader absolute w-full cursor-pointer bg-transparent opacity-0 z-10' />
            <Button variant="outlined" htmlFor='upload_file' className='rounded-none  py-3 fileuploader text-xs  border border-gray-400'>Upload  Picture</Button>
          </Box>
    </>
  );
}
