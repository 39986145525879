import { useContext, useState } from "react";
import { AuthContext } from "../../context/auth";
import { SessionContext } from "../../context/session";
import { useForm } from "../../hooks/form";
import { useMutation } from "@apollo/client";

//Material-UI
import { Grid } from "@material-ui/core";
import { FormControl } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import { useStyles } from '../../styles/CompanyDetailsModalStyles';
import { DialogContent } from "../../styles/DemoDataStyles";
import SnackBarAlert from "../../components/SnackBarAlert";
import Button from '@material-ui/core/Button';

//QUERIES
import { ADD_DEMOGRAPHIC_DATA } from "../../util/graphql";

//Local content
import { genders, ethnics, ages } from "../../data/UserData";

function DemographicModal(props){
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const sessionContext = useContext(SessionContext);
  const [alert, setAlert] = useState(false);
  const [msg, setMsg] = useState("");
  //Snackbar Alert State
  const [openSnackBar, setOpenSnackBar] = useState(true);
  const {handleClose} = props;

  //Snackbar alert Close Handeler
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const demoCallback = () => {
    if (values.age.trim() === "" || values.age.trim() === "Select") {
      setAlert(true);
      setMsg("Please select your Age Group!");
      setTimeout(() => {
        setAlert(false);
      }, 3000);
      return false;
    } else if (values.ethnic.trim() === "" || values.ethnic.trim() === "Select") {
      setAlert(true);
      setMsg("Please select your Ethnicity!");
      setTimeout(() => {
        setAlert(false);
      }, 3000);
      return false;
    } else if (values.gender.trim() === "" || values.gender.trim() === "Select") {
      setAlert(true);
      setMsg("Please select your Gender!");
      setTimeout(() => {
        setAlert(false);
      }, 3000);
      return false;
    }
    addDemoData();
  };
  const { onChange, onSubmit, values } = useForm(demoCallback, {
    id: user.id,
    age: "Select",
    gender: "Select",
    ethnic: "Select",
  });

  // update Results for this session
  const [addDemoData] = useMutation(ADD_DEMOGRAPHIC_DATA, {
    variables: { input: values },
    onCompleted: (data) => {
      sessionContext.progress({...sessionContext.session, 'status': "IN_PROGRESS"});
      props.goto(true);
      handleClose(false);
    },
    onError(err) {
      setAlert(true);
      setMsg(err.message);
      setTimeout(() => {
        setAlert(false);
      }, 3000);
    },
  });

  return (
    <div>
      {alert ? (
        <SnackBarAlert
          severity="error"
          alertMessage={msg}
          handleCloseSnackbar={handleCloseSnackbar}
          openSnackBar={alert}
          vertical="top"
          horizontal="center"
          autoHideDuration={6000}
        />
      ) : (
        <></>
      )}
      <>
        <DialogContent className="flex justify-center flex-col p-0">
          <h2 className="text-3xl	mb-3 text-dovegray">Personal & Demographic Data</h2>
          <Grid container>
            <Grid item xs={12} className="py-2">
              <FormControl 
                  variant="outlined" 
                  className={classes.selectFormControl} 
                  fullWidth={true}
              >
                  <InputLabel shrink htmlFor="age" id="age">Age Group*</InputLabel>
                  <Select
                      labelId="age"
                      id="age"
                      name="age"
                      value={values.age}
                      onChange={onChange}
                      displayEmpty
                      fullWidth={true}
                      className={`${classes.selectOption} gesta-regular`}
                      label="Age Group*"
                      inputProps={{
                        name: "age",
                        id: "outlined-age-native-simple",
                      }}
                  >
                      
                      {
                      ages.map((age, i) => {
                          return (<MenuItem key={i} value={age.value}>{age.name}</MenuItem>)
                      })
                      }
                  </Select> 
              </FormControl>
            </Grid>
            <Grid item xs={12} className="py-2">
              <FormControl 
                  variant="outlined" 
                  className={classes.selectFormControl} 
                  fullWidth={true}
              >
                  <InputLabel shrink id="ethnic" htmlFor="ethnic">Ethnicity*</InputLabel>
                  <Select
                      labelId="ethnic"
                      id="ethnic"
                      name="ethnic"
                      value={values.ethnic}
                      onChange={onChange}
                      displayEmpty
                      fullWidth={true}
                      className={`${classes.selectOption} gesta-regular`}
                      label="Ethnicity*"
                      inputProps={{
                        name: "ethnic",
                        id: "ethnic",
                      }}
                  >
                    
                      {
                      ethnics.map((ethnic, i) => {
                          return (<MenuItem key={i} value={ethnic.value}>{ethnic.name}</MenuItem>)
                      })
                      }
                  </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} className="py-2">
                <FormControl 
                    variant="outlined" 
                    className={classes.selectFormControl} 
                    fullWidth={true}
                >
                    <InputLabel shrink id="gender">Gender*</InputLabel>
                    <Select
                        labelId="gender"
                        id="gender"
                        name="gender"
                        value={values.gender}
                        onChange={onChange}
                        displayEmpty
                        fullWidth={true}
                        className={`${classes.selectOption} gesta-regular`}
                        label="Gender*"
                        inputProps={{
                          name: "gender",
                          id: "outlined-gender-native-simple",
                        }}
                    >
                        
                        {
                        genders.map((gender, i) => {
                            return (<MenuItem key={i} value={gender.value}>{gender.name}</MenuItem>)
                        })
                        }
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} className="py-2 mt-6">
              <Button variant="outlined" type="submit" onClick={onSubmit} className={`${classes.resetButton} gesta bg-bigstone text-white w-full`}>
                SUBMIT & GO TO SELF AUDIT
              </Button>
            </Grid>
            <Grid item xs={12} className='py-2'>
                <div className="text-center">
                    <button onClick={props.logout} className='p-2 font-medium text-lg text-bigstone'>Logout</button>
                </div>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    </div>
  );
};

export default DemographicModal;