import { useState } from "react";
import { useMutation } from '@apollo/client';
//Material ui
import Button from "@material-ui/core/Button";
import { Box } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import Grid from "@material-ui/core/Grid";
import { challenges } from '../data/ChallengeData';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import json2mq from 'json2mq';

// User Defined
import {PdfBarStyle} from '../styles/PdfDownlodableBarStyle';
import Chip from '@material-ui/core/Chip';
import PreLoader from '../components/PreLoader';

import ChallengeReport2 from "../components/challenges/ChallengeReport2";
import KeyInsights from '../components/KeyInsights/';
import ReportBarCompare from "../components/ReportBarCompare";
import ReportAccordion from "../components/challenges/ReportAccordion";
import ChallengeQuestionBarCompare from "./challenges/ChallengeQuestionBarCompare";
import SnackBarAlert  from '../components/SnackBarAlert';
import { SEND_REPORT, DOWNLOAD_REPORT } from '../util/graphql';
import Moment from 'moment';
import { useStyles } from '../styles/ReportStyles';

import download from "../assets/imgaes/download.svg";
import share from "../assets/imgaes/share.svg";

function ClientAssessmentContent({ client }) {
  const classes = PdfBarStyle();
  const userResults = client.currentResultId;
  const userSession = client.currentSessionId;
  const [open, setOpen] = useState(false);
  const [goNext, setGoNext] = useState(true);
  const [alert, setAlert] = useState(false); 
  const [msg, setMsg] = useState('');
  const [severity, setSeverity] = useState('success');
  const [openSnackBar,setOpenSnackBar] = useState(true);

  const mobileDevice = useMediaQuery(
    json2mq({
      maxWidth: 767,
    }),
  );
  const asstClasses = useStyles();

  const completedOn = Moment(userResults.completedOn).format("DD MMMM YYYY");
  const fileName = `Founder_Journey_Report-${makeCapitalize(client.firstName) + '_' + makeCapitalize(client.lastName) + '_' + completedOn}.pdf`;
  function makeCapitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  var host = window.location.origin + '/reports/';
  if (host.substring(7,12) === "local") host = 'http://localhost:5001/reports/';

  const [sendPDFReport] = useMutation(SEND_REPORT);

  const handleClose = () => {
    setOpen(false);
  };

  const sendReport = () => {
    sendPDFReport({variables: {id: client.id, email: null, toSend: true, sessionId: client?.currentSessionId?.id }});
    setAlert(true);   
    setMsg("Report email sent successfully!");
    setTimeout(() => {setAlert(false)}, 5000);
  };

  //Snackbar alert Close Handeler
  const handleCloseSnackbar = (event, reason)=> {
    if (reason === "clickaway") {
        return;
    }
    setOpenSnackBar(false);
  }

  const priorities = [];
  if (userSession.priority1Id > 0) {
    priorities.push({
        color: challenges[userSession.priority1Id-1].color, 
        icon: challenges[userSession.priority1Id-1].icon,
        title: challenges[userSession.priority1Id-1].challenge,
        notes: userSession.priority1Note
      });
  }
  if (userSession.priority2Id > 0) {
    priorities.push({
        color: challenges[userSession.priority2Id-1].color, 
        icon: challenges[userSession.priority2Id-1].icon,
        title: challenges[userSession.priority2Id-1].challenge,
        notes: userSession.priority2Note
      });
  }
  if (userSession.priority3Id > 0) {
    priorities.push({
        color: challenges[userSession.priority3Id-1].color, 
        icon: challenges[userSession.priority3Id-1].icon,
        title: challenges[userSession.priority3Id-1].challenge,
        notes: userSession.priority3Note
      });
  }

  var averages = [];
  var importances = [];
  var keyGaps = [];
  for (let i=1; i < 8; i++) {
      let c = 'c'+i;
      averages.push([c, userResults[c].average]);
      importances.push([c, userResults[c].importance]);
      // keyGaps.push([c, (userResults[c].average - userResults[c].importance)]);
      if (userResults[c].importance > userResults[c].average) {
          keyGaps.push([c, (userResults[c].average - userResults[c].importance)]);
      }
  }
  keyGaps.sort(function(a, b){return a[1]-b[1]});
  averages.sort(function (a, b) {
    return b[1] - a[1];
  });
  const [tabberValues, setTabberValues] = useState([0, 2,'Highest']);
    const tabsList = [{
        name:"Highest",
        value:[0,2]
    },{
        name:"Lowest",
        value:[averages.length - 2,averages.length]
    },{
        name:"Greatest Opportunities",
        value:[2,averages.length - 2]
    }]

  const [downloadReport, {loading}] = useMutation(DOWNLOAD_REPORT, {
    variables: { id: client.id, sessionId : client?.currentSessionId?.id },
    onCompleted: (data) => {
        const linkSource = `data:application/pdf;base64, ${data.downloadReport}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    },
    onError(err) {
        console.log('err',err);
    }
  });
  
  const downloadReportHandler = () => {
    downloadReport();
  }
  if(loading) {
    return <PreLoader />;
  }
  

  return (
    <>
      {
      alert ? <SnackBarAlert severity={severity} alertMessage={msg} handleCloseSnackbar={handleCloseSnackbar} openSnackBar={alert} vertical='top' horizontal='center' autoHideDuration={6000} />: <></> 
      }
    <Box className="bg-white pt-4 md:pt-12 ">
      <Grid container>
        <div className="report_parent_container w-full overflow-hidden">
          <Grid container className="report_head pb-6 lg:flex px-9 md:px-12 md:pt-0 pt-4">
            <Grid item xs={12} md={6} className={` ${asstClasses.reportHeadLeft} report_head__left`}>
              <h2 className="mb-0" style={{fontSize: `${mobileDevice ? "30px" : '42px'}`}}>{client.firstName} {client.lastName}</h2>
              <p className='text-emperor text-lg'>Self-Assessment <span className="h-1 w-1 bg-emperor inline-block rounded-r-full	align-middle mx-1"></span> {completedOn}</p>
            </Grid>
            <Grid item xs={12} md={6} className={`${asstClasses.reportHeadRight} report_head__right text-right flex flex-wrap items-end md:justify-end md:pt-0 pt-3.5`}>
              <div className="share-btn flex">
                <Button className='min-w-0 p-0' onClick={sendReport}>
                  <img src={share} alt=""/>
                </Button>
                <Button className='min-w-0 p-0 ml-3.5' onClick={downloadReportHandler}>
                  <img src={download} alt=""/>
                </Button>
              </div>
              <div className='px-2 hidden md:flex bg-bigstone md:ml-4 mt-2'>
                <Chip label="Capability Rating" className={`${asstClasses.ReportHeadChip} first_chip`} />
                <Chip label="Importance Rating" className={`${asstClasses.ReportHeadChip} second_chip`} />
              </div>
            </Grid>
          </Grid>
          <Grid container className='report_body relative mb-20 md:pl-16 md:pr-10 pl-11 pr-9 md:pt-14 pt-7 bg-concrete mt-12 md:mt-0'>
            <div className='px-1 md:px-2 md:hidden flex bg-bigstone mb-14 md:mb-0'>
              <Chip label="Capability Rating" className={`${asstClasses.ReportHeadChip} first_chip`} />
              <Chip label="Importance Rating" className={`${asstClasses.ReportHeadChip} second_chip`} />
            </div>
            <div className={`tabberList ${asstClasses.tabberList}`}>
              <ul>
                {tabsList.map((item, index)=> <li key={index} className={`${tabberValues[2] === item.name ? "active tablinks" :"tablinks"}`} onClick={()=>setTabberValues([...item.value,item.name])}>{item.name}</li> )}
              </ul>
            </div>
            <ChallengeReport2 
            keyGaps={keyGaps}
            averages={averages}
            importances={importances}
            isReport={true}
            reportLavel={true}
            tabberValues={tabberValues}
            />
              
          </Grid>
        </div>

        
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <Box className='box_content w-full py-4 px-6'>
                <ChallengeQuestionBarCompare results={userResults} next={setGoNext} />      
            </Box>
        </Dialog>

        <Grid item xs={12}>
          <KeyInsights
            keyGaps={keyGaps}
            averages={averages}
            tabberValues={tabberValues}
          />
          {
            mobileDevice ? <></> : <ReportBarCompare results={userResults} next={setGoNext} />
          }   
          
          <div className={`accordion_parent px-9 md:px-12 pt-14 pb-20 md:py-14 ${classes.accordionParent}`} style={{background: '#f2f2f1'}}>
            <h5 className="gesta-medium text-xl text-mineshaft mb-1">Challenges</h5>
            <h3 className="gesta-medium font-light text-dovegray md:text-3xl text-mineshaft mb-6">Self-Audit Feedback</h3>
            <ReportAccordion
              averages={averages}
              importances={importances}
              userResults={userResults}
            />
          </div>
        </Grid>
      </Grid>
    </Box>
    </>
  );
}

export default ClientAssessmentContent;
