import React, { useReducer, createContext } from 'react';
import jwtDecode from 'jwt-decode';

const initialState = {
  user: null
};

const removeLSItems = () => {
  sessionStorage.removeItem('jwtToken');
  if (sessionStorage.getItem('authToken')) {
    sessionStorage.removeItem('authToken');
  }
  if (sessionStorage.getItem('results') || sessionStorage.getItem('session')) {
    sessionStorage.removeItem('results');
    sessionStorage.removeItem('session');
  }
}

//if (!sessionStorage.getItem('authToken')) {
  if (sessionStorage.getItem('jwtToken')) {
    const decodedToken = jwtDecode(sessionStorage.getItem('jwtToken'));
    if (decodedToken === undefined) {
      removeLSItems();
    }
  
    if (decodedToken.exp * 1000 < Date.now()) {
      removeLSItems();
    } else {
      initialState.user = decodedToken;
    }
  }
//}

const AuthContext = createContext({
  user: null,
  login: (userData) => { },
  logout: () => { }
});

function authReducer(state, action) {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        user: action.payload
      };
    case 'LOGOUT':
      return {
        ...state,
        user: null
      };
    default:
      return state;
  }
}

function AuthProvider(props) {
  const [state, dispatch] = useReducer(authReducer, initialState);

  function login(userData) {
    sessionStorage.setItem('jwtToken', userData.token);
    dispatch({
      type: 'LOGIN',
      payload: userData
    });
  }

  function logout() {
    removeLSItems();
    /* sessionStorage.removeItem('jwtToken');
    sessionStorage.removeItem('authToken'); */
    dispatch({ type: 'LOGOUT' });
    window.location.href = "/";
  }

  return (
    <AuthContext.Provider
      value={{ user: state.user, login, logout }}
      {...props}
    />
  );
}

export { AuthContext, AuthProvider };
