import { useQuery } from '@apollo/client';
import jwtDecode from 'jwt-decode';
import { useLocation } from "react-router-dom";
import PreLoader from '../components/PreLoader';
import Welcome from './Welcome';
//QUERIES
import { WELCOME_CLIENT } from '../util/graphql';

// A custom hook that builds on useLocation to parse the query string for us.
function useQueryString() {
    return new URLSearchParams(useLocation().search);
}

function Reset() {
    const query = useQueryString();
    const token = query.get("t");
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < Date.now()) {
        sessionStorage.removeItem('jwtToken');
    } else {
        sessionStorage.setItem('jwtToken', token);
    }

    const { loading, error, data } = useQuery(WELCOME_CLIENT);

    if (loading) return <PreLoader/>;
    if (error) {
        sessionStorage.removeItem('jwtToken');
        return (
            <Welcome 
            invited={false} message="Invalid or expired token! Please use forgot password again." isSet={false}
            />
        )
    }
    
    if (data) {
        if (data.welcomeClient.isPasswordReseted || token !== data.welcomeClient.token) {
            sessionStorage.removeItem('jwtToken');
            return (
                <Welcome 
                invited={false} message="You have already reseted your password!" isSet={false}
                />
            )
        }
        return (
            <Welcome
            invited={true} message={''} isSet={false} email={data.welcomeClient.email} 
            name={data.welcomeClient.firstName + ' ' + data.welcomeClient.lastName} 
            />
        )
    } 
    
}

export default Reset