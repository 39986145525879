import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import homeBgMob from "../../assets/imgaes/bg-home-mob.png";
import homeBgTab from "../../assets/imgaes/bg-home-tab.png";


const useStyles = makeStyles({
    main: {    
        width: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center bottom -40px',
        paddingTop: '8vh',
        '@media only screen and (max-width:767px)': {
          backgroundImage: `url(${homeBgTab}) !important`,
          paddingTop: '60px',
        },   
        '@media only screen and (max-width:375px)': {
          backgroundImage: `url(${homeBgMob}) !important`
        },
        '@media only screen and (min-height:767px) and (min-width:768px)': {
          paddingTop: '19vh',
          backgroundPosition: 'center bottom',
        }
    }
});

function DashboardContainer(props) {
  const classes = useStyles();

  return (
    <main
      className={`bgHome w-full h-screen ml-0 lg:ml-52 ${props.showDashboard ? "" : classes.main}`}
      style={{backgroundImage: `url(${props.bgImage})`, padding: `${props.sectionPadding}`}}
    >
      {props.children}
    </main>
  );
}

export default DashboardContainer;
