import { makeStyles } from "@material-ui/core/styles";
// import { normalizeUnits } from "moment";

export const useStyles = makeStyles((theme) => ({
    cardWhite: {
      '& h3':{
        fontSize: "28.13px",
        color: "#636363",
      },
      '& .challenge_text': {
        fontSize: '46px',
        lineHeight: 'normal',
        '@media (max-width: 767px)' : {
          fontSize: '37px',
        }
        // letterSpacing: '-1.5px'
      },
      '& .MuiLink-root': {
        color: '#0096D1',
        fontSize: '16px',
      },
      '& .home_priorities': {
        // fontSize: "14.4px",
        lineHeight: "17px",
        letterSpacing: "0.05em",
      },
      '& ol':{
        listStyle: "auto",
        '& li':{
          // fontSize: "16px",
        }
      }
    },
  
    cardBlue: {
      width: "168px",
    },
  
    colorTimelineSpan: {
      width: "124px",
      height: "9px",
  
      "@media only screen and (max-width: 767px)": {
        width: "auto !important",
      },
    },
  
    timeline: {
      transform: "translateY(-65%)",
    },
  
    timelineText: {},
  
    timelineActivitySpan: {
      width: "32px",
      height: "32px",
      position: "absolute",
      backgroundColor: "#fff",
    },
  
    timelineActivity: {
      width: "12px",
      height: "12px",
      borderRadius: "100%",
    },
  
    timelineActive: {
      background: "#31C59F",
    },
  
    timelineInactive: {
      background: "#BDBDBD",
    },
  
    colorRed: {
      color: "#EF4050",
    },
  
    bgTeal: {
      backgroundColor: "#31C59F",
    },
  
    "@media only screen and (max-width: 600px)": {
      bgTeal: {
        backgroundColor: "#ff0",
      },
    },
  }), {index: 1});