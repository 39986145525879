import { makeStyles } from '@material-ui/core/styles';

// Page Background
import pageBackground from '../assets/imgaes/challenge-bg.png';
import importance from '../assets/imgaes/importance.svg';
import capability from '../assets/imgaes/capability.svg';

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '15px'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    accordionParent: {
        background: '#f2f2f1',
    },
    challengePage: {
        background: `url(${pageBackground})`,
        
    },
    cContainer: {
    },
    challengePageHead: {
        backgroundColor: '#8A9CA9',
        '& h3': {
        },
        
    },
    challengeQuestionModal:{
        '& .completionMessage': {
            paddingTop: '50px !important',
            paddingBottom: '30px !important',
            '&:before': {
                display: 'none',
            }
        },
        '& .challengeQuestionModalClose': {
            padding: '0',
            '& button':{
                position: 'absolute',
                fontSize: '0',
                minWidth: '0',
                zIndex: '54',
                height: '48px',
                width: '48px',
                right: '5px',
                top: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'transparent',
                '& span':{
                    display: 'none',
                },
                '&:after':{
                    content:"''",
                    height: '2px',
                    width: '20px',
                    top: '0',
                    position: 'absolute',
                    left: '0',
                    bottom: '0',
                    right: '0',
                    margin: 'auto',
                    background: '#333',
                    transform: 'rotate(45deg)',
                },
                '&:before':{
                    content:"''",
                    height: '2px',
                    width: '20px',
                    top: '0',
                    position: 'absolute',
                    left: '0',
                    bottom: '0',
                    right: '0',
                    margin: 'auto',
                    background: '#333',
                    transform: 'rotate(-45deg)',
                }
            }
        }
    },
    challenge_questions_parent: {
        padding: '40px 50px 0px 47px'
    },
    challengePageContent: {
        // backgroundColor: '#fff',
    },
    reportHeadLeft: {
        '& span':{
            color: "#333",
            fontSize: "18px",
            fontWeight: 'bold',
        },
        '& h2':{
            color: "#333",
            fontWeight: '500',
            fontSize: '46px',
            textTransform: 'capitalize',
            lineHeight: 'normal',
            margin: "0 0 15px 0",
            '@media (max-width:767px)':{
                fontSize:'34px',
            }

        },
        '& p': {
            color: '#636363'
        }
    },
    reportHeadRight: {  
        '@media (max-width:767px)':{
            '& img': {
                width: '35px',
            }
        }
    },
    ReportHeadChip: {
        height: 'auto',
        color: "#F2F2F2",
        fontSize: "18px",
        borderRadius: '0',
        padding: '6px 9px 6px 9px',
        fontWeight: '700',
        fontFamily: "Gesta",
        background: '#121e31',
        transition: 'all .30s linear',
        display: 'inline-flex',
        '&:before': {
            content:"''",
            width: '18px',
            height: '18px',
            marginRight: '13px',
        },
        '& .MuiChip-label':{
            padding: '0',
        },
        '&.first_chip': {
            color: '#ffffff',
            '&:before': {
                background: `url(${capability}) no-repeat center center / 100% auto`,
                // background: `url(${pageBackground})`,
            },
        },
        '&.second_chip':{
            color: '#bdbdbd',
            '&:before': {
                background: `url(${importance}) no-repeat center center / 100% auto`,
            },
        },
        '@media (max-width:767px)':{
            fontSize: '14px',
            padding: '4px 9px 4px 9px',
            '&:before': {
                content:"''",
                width: '12px',
                height: '12px',
                marginRight: '7px',
            },
        }
    },
    challengePageFooter: {
        height: '72px',  
    },
    reportScale:{
        position: "absolute",
        width: "100%",
        height: "100%",
        '& .ruler': {
            position: "absolute",
            height: "2px",
            width: "100%",
            background: "#DADBE1",
            '&.ruler_0': {
                bottom: "0px",
            },
            '&.ruler_1': {
                bottom: "38px",
            },
            '&.ruler_2': {
                bottom: "78px",
            },
            '&.ruler_3': {
                bottom: "118px",
            },
            '&.ruler_4': {
                bottom: "158px",
            },
            '&.ruler_5': {
                bottom: "198px",
            },
            '&.ruler_6': {
                bottom: "238px",
            },
            '&.ruler_7': {
                bottom: "278px",
            },
        }
        ,
        '& .scale_number': {
            color: "#777",
            fontSize: "14px",
            position: "relative",
            top: "-16px",
            left: "-10px",
        },
        // '&.smallscale': {
        //     '& .ruler': {
        //         position: "absolute",
        //         height: "1px",
        //         width: "100%",
        //         background: "#DADBE1",
        //         '&.ruler_0': {
        //             bottom: "0px",
        //         },
        //         '&.ruler_1': {
        //             bottom: "30px",
        //         },
        //         '&.ruler_2': {
        //             bottom: "60px",
        //         },
        //         '&.ruler_3': {
        //             bottom: "90px",
        //         },
        //         '&.ruler_4': {
        //             bottom: "120px",
        //         },
        //         '&.ruler_5': {
        //             bottom: "150px",
        //         },
        //         '&.ruler_6': {
        //             bottom: "180px",
        //         },
        //         '&.ruler_7': {
        //             bottom: "210px",
        //         },
    
        //     }
        // }
    },

    reportMountainContainer: {
        height: "282px",
        '&.smallmountain': {
            height: "210px",
        },

    },
    chipWrapper: {
        left: '50%',
        transform: 'translate(-50%, -5px)',
        marginBottom: '8px',
        '&:before':{
            content:'""',
            position: 'absolute',
            top: '100%',
            left: '0',
            right: '0',
            height: '0',
            width: '0',
            border: '6px solid transparent',
            borderTopColor: '#121e31',
            borderTopWidth: '9px',
            margin: 'auto',
        }
    },
    Chip: {
        // backgroundColor: '#0096D1',
        color: '#F2F2F2',
        fontFamily: "gesta",
        fontSize: "14px",
        lineHeight: "1.2",
        fontWeight: '500',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: "0",
        height: 'auto',
        zIndex: "9",
        transition: 'all .30s linear',
        bottom: 0,
        background: 'transparent',
        padding: '6px',
        '&:before':{
            content:"''",
            height: '9px',
            width: '9px',
            marginRight:'3px',
            background: 'no-repeat center center / 100% auto'
        },
        '& svg': {
            color: "#F2F2F2",
            fontSize: '16px'
        },
        '&.mountain_label':{
            color: '#fff',
            marginTop: "0px",
            fontFamily: 'Gesta',
            fontWeight: '700',
            '&:before':{
                backgroundImage: `url(${capability})`,
            },
            '&.smallmountain':{
                marginTop: "0px",
            }
        },
        '&.shadow_label': {
            color: '#bdbdbd',
            fontFamily: 'gesta',
            fontWeight: '700',
            bottom: '0px',
            '&:before':{
                backgroundImage: `url(${importance})`,
            },
            '& svg': {
                color: '#ffffff',
                
            },
            '&.smallmountain':{
                marginTop: "0px",
            }
        },
        '& .MuiChip-label':{
            padding: 0
        }
        
    },
    singleReportMountain: {
        flex: "0 0 14.28%",
        maxWidth: "14.28%",
        position: "relative",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        '&:nth-child(n + 8)' :{
            display: 'none',
        },
        '@media (max-width:767px)': {
            flex: '0 0 33.33% !important',
            maxWidth: '33.33%',
        },
        '& .sicon': {
            transition: 'all .30s linear',
            width: "100%",
            opacity: '1',
            position: "absolute",
            height: "50px",
            bottom: 0,
            left: '50%',
            transformOrigin: "bottom left",
            transform: "scale(0.7,1) translateX(-50%)",
            '@media (max-width: 767px)':{
                transform: "scale(0.7,1) translate(-50%, 43px)",
            }
        },
        '& .icon-report_shadow': {
            transform: "scale(1,0.7) translateX(-50%)",
            marginBottom: '0',
            
            '@media (max-width: 767px)':{
                transform: "scale(0.7,0.7) translate(-50%, 8px)",
            }
        },
        '&.smallmountain': {
            '& .sicon': {
                // height: "30px",
            }
        },
        '&:hover':{
            '& .mountain_text':{
                visibility: 'visible',
                display: 'block'
            }
        }
    },
    tabberList:{
        display: 'none',
        position: 'absolute',
        bottom: '100%',
        width: '100%',
        background: '#fff',
        left: '0',
        right: '0',
        fontSize: '14px',
        fontWeight: '500',
        '@media (max-width:767px)':{
            display: 'block'
        },
        '& ul':{
            display: 'flex',
            '& li':{
                borderBottom: '5px solid #bdbdbd',
                padding: '12px',
                whiteSpace: 'nowrap',
                flex:'1 1 100%',
                textTransform: 'uppercase',
                textAlign: 'center',
                '&.active':{
                    borderColor: '#121e31',
                    fontWeight: '700',
                }
            }
        }
    },
    mountainText: {
        fontFamily: "gesta",
        fontSize: "14px",
        fontWeight: '500',
        lineHeight: "normal",
        color: '#000',
        width: "129px",
        maxWidth: '100%',
        textAlign: "center",
        position: "absolute",
        top: "calc(100% + 18px)",
    }
    
}), {index: 1});