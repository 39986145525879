import {useEffect } from "react";

// Material Ui
import ChallengeQuestionDialogHead from "./ChallengeQuestionDialogHead";

// User Defined
import { challengeStyles } from "../../styles/ChallengeQuestionModalStyle";
import { challenges } from "../../data/ChallengeData";

export default function ChallengeQuestionDarkBar({ results, next }) {
  const classes = challengeStyles();

  // runf only first time
  useEffect(() => {
    next(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  return (
    <div
      className={`${classes.challengeDialogContent} challenge_dialog_inner_content spendtime `}
    >
      <ChallengeQuestionDialogHead
        headingTitle="How do you currently spend your time?"
        headingContent=""
        hasHeadingContent={false}
      />
      <div className="challenge_question_dialog_body text-center mt-12">
        <div className={`challenge_bar_parent flex items-end  border-b border-gray-50 dark  ${classes.challenge_bar_parent}` }>
          {challenges && challenges.map((item, i) => {
              var cNum = item.id
              return (
                <div
                  className="single_challenge_bar justify-center flex items-end pb-5 mb-10 flex-1 relative border-b border-gray-300"
                  key={i}
                >
                  {/* darker bar */}
                  <span className="parcent darker absolute " style={{
                    bottom: `${(results['c'+(cNum)].does * 2.4) + 30}px`,
                    right: '24px'
                  }}>
                    {results['c'+(cNum)].does}%
                  </span>
                  <span
                    className={`bar darker bg-${item.color}`}
                    style={{
                      display: "inline-block",
                      height: `${results['c'+(cNum)].does * 2.4}px`,
                      width: "20px",
                    }}
                  ></span>
                </div>
              );
            })
          }
        </div>
        <div className={`challenge_bar_label_indicator flex items-end pb-20 ${classes.challengeBarLabelIndicator}`}>
          {challenges &&
            challenges.map((item, i) => {
              //cNum = item.id;
              return (
                <div className="single_indicator flex items-start flex-col flex-1 relative" key={i}>
                  <span className={`indicator block  bg-${item.color}`}></span>
                  <span className="text">{item.challenge}</span>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
}
