import { useContext } from "react";
import { AuthContext } from "../context/auth";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
// User Defined
import Sidebar from "../components/homeDashboard/Sidebar";
import GeneralInstructionCard from "../components/GeneralInstructionCard/";
import bg from "../assets/imgaes/bg-home.jpg";
import { makeStyles } from '@material-ui/core/styles';
import homeBgMob from "../assets/imgaes/bg-home-mob.png";
import homeBgTab from "../assets/imgaes/bg-home-tab.png";

const useStyles = makeStyles({
  main: {     
      '@media only screen and (max-width:767px)': {
        backgroundImage: `url(${homeBgTab}) !important`
      },   
      '@media only screen and (max-width:375px)': {
        backgroundImage: `url(${homeBgMob}) !important`
      },
      '@media only screen and (min-height:767px) and (min-width:768px)': {
        backgroundPosition: 'center bottom !important',
      }

  }
});

function GeneralInstruction() {

  const { user, logout } = useContext(AuthContext);
  const history = useHistory();
  const classes = useStyles();


  
  return (
    <Box
      component="section"
      className="mx-auto flex justify-between w-100 flex-col lg:flex-row relative"
    >
      <Sidebar user={user} logout={logout} />
      <Box 
      style={{
        background: `url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: 'center bottom -40px',
      }} 
      className={`bgHome relative min-h-screen pt-20 md:mt-0 md:pt-10 2xl:pt-20 pb-20 2xl:px-10 px-9 md:px-5 ml-0 lg:ml-52 md:w-full ${classes.main}`}>
   
          <h3 className='text-alabaster gesta leading-tight mb-2 md:mb-3 font-bold md:font-normal md:text-4xl md:mb-4 leading-7' style={{fontSize:'32px'}}>
          As you scale your company, begin your journey to understand “The Seven Navigation Challenges”.
          </h3>
           {/* <p  className="text-alabaster gesta-bold md:text-3xl text-lg md:text-xl" >
            The Seven Navigation Challenges
          </p>  */}

          <GeneralInstructionCard/>
          
          <Box component="div" className="mx-auto flex justify-between absolute md:static left-0 bottom-0 w-full" style={{maxWidth:'900px'}}>
            <Button onClick={() => history.push("/dashboard")} variant="contained" className={`text-bigstone w-1/2 md:w-auto uppercase inline-flex items-center justify-center bg-alabaster gesta-medium text-base md:text-lg rounded-none py-3.5 px-5 sm:px-6 tracking-normal  `}>
              <KeyboardArrowLeftIcon />
              Dashboard
            </Button>
            <Button onClick={() => history.push("/navigation-challenges")} variant="contained" className={`bg-bigstone w-1/2 md:w-auto uppercase inline-flex items-center justify-center text-alabaster gesta-medium text-base md:text-lg rounded-none py-3.5 px-5 sm:px-6 tracking-normal  `}>
              definitions
              <KeyboardArrowRightIcon />
            </Button>
          </Box>
      
      </Box>
    </Box>
  );
}

export default GeneralInstruction;
