//Material-UI
import Card from '@material-ui/core/Card';

//Import local components
import ChallengeCardContent from '../../components/challenges/ChallengeCardContent';
import { marks, marks2 } from '../../data/ChallengeData';
import { useStyles } from '../../styles/ChallengeQuestionStyles';

export default function ChallengeQuestion(props) {
    const classes = useStyles();
    const qData = props.qData;
    const activeChallenge = props.activeChallenge;
    const importanceValue = props.vals['c' + (activeChallenge + 1)].importance;
    const importanceQuesStatus = props.questionStatus['c' + (activeChallenge + 1)].importance;
    const isMarkSelected = props.questionAnsweredTill >= (activeChallenge + 1) ? true : false;

    let mainQuestion = 
            <div className="challenge_card__question pt-3 pb-5 md:bg-concrete bg-white">
                <h6 className={`${classes.challengeTitleBold} gesta text-lg md:text-2xl text-emperor tracking-tight`}>
                    {qData.questions.main}
                </h6>
            </div>;

    let mainContent = '';
    if(qData.questions.quest){
        mainContent = 
            <>
                {
                    qData.questions.quest.map((data, i) => {
                        let value = props.vals['c' + (activeChallenge + 1)]['q' + (i + 1)];
                        let quesStatus = props.questionStatus['c' + (activeChallenge + 1)]['q' + (i + 1)];
                        return (
                            <ChallengeCardContent
                                key={i} challengeType={1} type='quest' qData={data}
                                activeChallenge={activeChallenge}
                                chg={props.chg} vals={value}
                                name={'q' + (i + 1)} label={marks[value - 1].label}
                                isMarkSelected={isMarkSelected}
                                quesStatus={quesStatus}
                                showCardError={props.showCardError}
                                errorQuestionNumber={props.errorQuestionNumber}
                                showCardErrorTime={props.showCardErrorTime}
                            />
                        )
                    })
                }
            </>;
        };

    let secondaryQuestion = 
            <div className="challenge_card__question pt-8 pb-5 md:bg-concrete bg-white">
                <h6 className={`${classes.challengeTitleBold} gesta text-lg md:text-2xl text-emperor tracking-tight`}>
                {qData.questions.secondary}
                </h6>
            </div>;
    
    let secondaryContent = '';
    if(qData.questions.secondary){
        secondaryContent = 
                <ChallengeCardContent
                    challengeType={2} type='secondary' qData={qData.questions.secondary}
                    activeChallenge={activeChallenge}
                    chg={props.chg} vals={importanceValue}
                    name="importance" label={marks2[importanceValue - 1].label}
                    isMarkSelected={isMarkSelected}
                    quesStatus={importanceQuesStatus}
                    showCardError={props.showCardError}
                    errorQuestionNumber={props.errorQuestionNumber}
                    showCardErrorTime={props.showCardErrorTime}
                />;
    }

    return (
        <>
            <div className=" ">
                <div>
                    <Card className={`${classes.challengeCard} challenge_card rounded-none shadow-none`}>
                        <div className={`${classes.challengeCardContent}  rounded-none`}>
                            {mainQuestion}
                            {mainContent}
                            {secondaryQuestion}
                            {secondaryContent}
                        </div>
                    </Card>
                </div>
            </div>
        </>
    )
}