import {useEffect} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import useMediaQuery from "@material-ui/core/useMediaQuery";

import json2mq from "json2mq";

import { ChallengeButtonStyle as ChallengeBtn, CssTextField } from "../styles/CommonStyles";

const useStyles = makeStyles((theme) => ({
    errorBorder: {
        '& .MuiInput-input': {
            borderColor: '#ef4050'
        }
    }
}));

export default function ClientSignUpStep(props) {

    const isMobile = useMediaQuery(
        json2mq({
          maxWidth: 767,
        })
    );
    
    useEffect(() => {
        if (isMobile) {
        }
    }, [isMobile]);

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h4 className='font-regular text-black text-lg'>
                            Personal Details
                        </h4>
                    </Grid>
                    {/* Client First Name */}
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard" className="w-full">
                            <CssTextField
                                id="firstName"
                                type="text"
                                name="firstName"
                                label="First Name*"
                                value={props.vals.firstName}
                                onChange={props.chg}
                                fullWidth={true}
                                className={props.isFirstNameError ? classes.errorBorder : ""}
                            />
                        </FormControl>
                    </Grid>
                    {/* Client Last Name */}
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard" className="w-full">
                            <CssTextField
                                id="lastName"
                                type="text"
                                name="lastName"
                                label="Last Name*"
                                value={props.vals.lastName}
                                onChange={props.chg}
                                fullWidth={true}
                                className={props.isLastNameError ? classes.errorBorder : ""}
                            />
                        </FormControl>
                    </Grid>
                
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard" className="w-full">
                            <CssTextField
                                id="phone"
                                type="text"
                                name="phone"
                                label="Contact Number"
                                value={props.vals.phone}
                                onChange={props.chg}
                                fullWidth={true}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
            <Box className='md:mb-0 mb-12'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h4 className='font-regular text-black text-lg pt-4'>Account Information</h4>
                    </Grid>
                    {/* Client First Name */}
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard" className="w-full">
                            <CssTextField
                                id="email"
                                type="text"
                                name="email"
                                label="Email*"
                                value={props.vals.email}
                                onChange={props.chg}
                                fullWidth={true}
                                className={props.isEmailError ? classes.errorBorder : ""}
                            />
                        </FormControl>
                    </Grid>
                    {/* Client Last Name */}
                    <Grid item xs={12} md={6}>
                        <FormControl variant="standard" className="w-full">
                            <CssTextField
                                id="confirmEmail"
                                type="text"
                                name="confirmEmail"
                                label="Confirm Email*"
                                value={props.vals.confirmEmail}
                                onChange={props.chg}
                                fullWidth={true}
                                className={props.isConfirmEmailError ? classes.errorBorder : ""}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Box>
                            <p className='text-black text-base'>
                            Client will receive an invitation email with a link to set their password. 
                            </p>
                        </Box>
                    </Grid>
                    
                </Grid>
            </Box>
            <Box component="div" className="flex justify-between fixed md:static left-0 right-0 bottom-0 w-auto md:w-full mt-2 md:mt-10">
                <ChallengeBtn
                    onClick={props.handleClose}
                    variant="contained"
                    className={`text-bigstone w-1/2 shadow-none md:w-auto uppercase font-medium inline-flex items-center justify-center bg-alabaster gesta text-base rounded-none py-3 px-4 md:px-7 tracking-wider`}
                >
                    <KeyboardArrowLeftIcon />
                    {isMobile ? 'Dashboard' : 'Back to Clients'}
                </ChallengeBtn>
                <ChallengeBtn
                    onClick={(e) => {props.sub(e)}}
                    variant="contained"
                    className={`bg-bigstone w-1/2 shadow-none md:w-auto uppercase font-medium inline-flex items-center justify-center text-alabaster gesta text-base rounded-none py-3 px-4 md:px-7 tracking-wider`}
                >
                    Create Client
                    <KeyboardArrowRightIcon />
                </ChallengeBtn>
            </Box>
        </div>
    );
}
