import { makeStyles } from "@material-ui/core/styles";


export const PdfBarStyle = makeStyles((theme) => ({

    pdfBarBtn: {
        border: '1px solid #333333',
        color: '#333333',
        height: '33px',
        width: '33px',
        minWidth: '33px',
        borderRadius: '0',
        padding: '0',
        '& svg': {
            height: '18px '
        },
        '&.pdf_download': {
            marginLeft: '10px'
        }
    }

}), {index: 1});