export const menuItem = [
    {
        text: 'Clients',
        link: '/dashboard/clients',
        isActive: false
    },
    {
        text: 'Coaches',
        link: '/dashboard/coaches',
        isActive: false
    }
];

export const tabMenuItem = [
    {
        text: "overview",
        id: 1,
    },
    {
        text: "Assessment",
        id: 2,
    },
    {
        text: "Founder Details",
        id: 3,
    }
];

/* {
    text: 'Companies',
    link: '/dashboard/companies',
    isActive: false
},
{
    text: 'Dashboard',
    link: '/dashboard',
    isActive: true
}, */


export const sectionList = [];
for (let index = 1; index <= 26; index++) {
    sectionList.push(`section-${index}`)
}

export const termsTitleList = [
    "Founder’s Journey",
    "Information Provided by You",
    "Eligibility",
    "Your Warranties and Undertakings",
    "Founder’s Journey App Access",
    "Reports.",
    "Ownership.",
    "Submissions and Ideas",
    "Trademarks, Copyrights, and Notices.",
    "Hyperlinks.",
    "DISCLAIMER",
    "Assumption of Risk.",
    "Security.",
    "Limitation of Liability",
    "Indemnification.",
    "Termination and Survival",
    "Notice to California Residents",
    "Third-Party Products and Services",
    "International Use",
    "Additional Terms",
    "Changes to Terms of Service",
    "Complete Agreement; Governing Language.",
    "Governing Law",
    "Proper Notice",
    "Terminology",
    "Questions",

]




