import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    error: {
        background: 'red',
        '& .error_page':{
            paddingBottom: '100px',
            '& picture':{
                '& img':{
                    width: '100%',
                    maxHeight: '336px',
                    objectFit: 'cover',
                    '@media (max-width: 767px)':{
                        marginTop: '60px',
                        maxWidth: 'none',
                        objectFit: 'none',
                    }
                }
            },
            '@media (max-width: 767px)':{
                paddingBottom: '40px',
                minHeight: 'calc(100vh - 60px) !important'
            }
        },
        '& .exclamation-icon':{
            background: '#f2f2f2',
            transform: 'translate(0, -50%)',
            padding: '18px',
            borderRadius: '50%',
            '& img': {
                background: 'rgba(219, 219, 219, 0.38)',
                borderRadius: '50%',
                padding: '18px',
            },
            '@media (max-width: 767px)':{
                padding: '10px',
                '& img':{
                    width: '110px',
                    padding: '12px',
                }
            }
        },
        '& h1':{
            fontSize:'55px',
            fontWeight: '500',
        }
        
    },
}), {index: 1});