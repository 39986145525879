import { useState } from "react";
import { useQuery } from "@apollo/client";

import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

//Material ui
import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

//User defined
import Sidebar from "./Sidebar";
import AdminTopTabMenu from "../components/AdminTopTabMenu";
import {tabMenuItem} from '../data/Localdata';
import ClientOverViewContent from '../components/ClientOverViewContent';
import ClientAssessmentContent from '../components/ClientAssessmentContent';
import ClientFounderDetailsContent from '../components/ClientFounderDetailsContent';
// QUERIES
import { GET_CLIENT } from "../util/graphql";
import PreLoader from '../components/PreLoader';

export default function ClientOverview(props) {
  const clientId = props.location.state.clientId;
  const [activeTabItem,setActiveTabItem] = useState(1);

  const handleTab = (itemnumber) => {
    refetch({ id: clientId, sessionId: null });
    setActiveTabItem(itemnumber);
  }

  const viewReport = (sessionId, tabNumber) => {
    refetch({ id: clientId, sessionId: sessionId });
    setActiveTabItem(tabNumber);
  }

  // Get Client info
  const { data: fetchedData , refetch, loading } = useQuery(GET_CLIENT, { variables: { id: clientId, sessionId: null }, notifyOnNetworkStatusChange: true });

  const data = loading ? null : fetchedData;

  if(loading){
    return <PreLoader/>
  }

  return (
    <Layout>
      <Helmet>
        <title>Client Overview</title>
      </Helmet>

      <Sidebar logout={props.logout} />
      <Box className="lg:ml-52 bg-white w-auto h-screen">
        <Grid container className="">
          <Grid item xs={12}>
                <AdminTopTabMenu tabMenuItem={tabMenuItem} handleTab={handleTab} activeTabItem={activeTabItem} userProgress={data.getClient.currentSessionId.status}/>
                {
                    activeTabItem === 1 ? 
                    data && <ClientOverViewContent client={data.getClient} viewReport={viewReport} /> : 
                    activeTabItem === 2 ? <ClientAssessmentContent client={data.getClient} /> : 
                    <ClientFounderDetailsContent client={data.getClient}/>
                }
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
}
