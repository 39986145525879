import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import ImageCrop from "../ImageCrop";



export default function CoachSignUpStep1({ chg, aChg, vals, coach }) {
  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              <h4 className="font-light text-gray-500 text-lg opacity-100 pt-5 pb-10">
                Profile Details
              </h4>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          className="border-2 border-dashed border-gray-300  bg-gray-50 "
        >
          <Grid
            item
            xs={3}
            className="border-r-2 border-dashed border-gray-300 p-4"
          >
            <ImageCrop  aChg={aChg} coach={coach} />
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={2} className='p-4'>
              <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth={true}>
                  <TextField
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    onChange={chg}
                    value={vals.firstName || '' }
                    variant="outlined"
                    fullWidth={true}
                    InputLabelProps={{
                      shrink: vals.firstName && vals.firstName ? true: false
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" fullWidth={true}>
                  <TextField
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    onChange={chg}
                    value={vals.lastName || ''}
                    variant="outlined"
                    fullWidth={true}
                    InputLabelProps={{
                      shrink: vals.lastName ? true: false
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth={true}>
                  <TextField
                    id="role"
                    name="role"
                    label="Title"
                    onChange={chg}
                    value={vals.role || ''}
                    variant="outlined"
                    fullWidth={true}
                    InputLabelProps={{
                      shrink: vals.role ? true: false
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth={true}>
                  <TextField
                    multiline
                    id="bio"
                    name="bio"
                    onChange={chg}
                    value={vals.bio || ''}
                    variant="outlined"
                    rows={5}
                    aria-label="empty textarea"
                    placeholder="Biography"
                    fullWidth={true}
                    InputLabelProps={{
                      shrink:  vals.role ? true: false
                    }}
                  />
                </FormControl>
              </Grid>
              
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
