import { useState, useContext, Fragment, useEffect } from "react";
import { useMutation } from '@apollo/client';
import { AuthContext } from "../context/auth";
import { ResultsContext } from "../context/result";
import { SessionContext } from "../context/session";

// Material
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Sidebar from "../components/homeDashboard/Sidebar";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";

//Challenges card
import { useStyles } from "../styles/ReportStyles";
import ChallengeReport2 from "../components/challenges/ChallengeReport2";
import ChallengeQuestionModal from "../components/challenges/ChallengeQuestionModal";

// media
import SendIcon from "@material-ui/icons/Send";
import GetAppIcon from "@material-ui/icons/GetApp";
import SnackBarAlert  from '../components/SnackBarAlert';

//QUERIES
import { SEND_REPORT } from '../util/graphql';
//import json2mq from "json2mq";

export default function Report3(props) {
  const classes = useStyles(props);
  const { user, logout } = useContext(AuthContext);
  const resultsContext = useContext(ResultsContext);
  const sessionContext = useContext(SessionContext);
  const [startClosure, setClosure] = useState(false);
  const status = sessionContext.session.status;
  const [alert, setAlert] = useState(false); 
  const [msg, setMsg] = useState('');
  const [severity, setSeverity] = useState('success');
  
  const [openSnackBar,setOpenSnackBar] = useState(true);
  
  const userResults = resultsContext.results;
  
  var host = window.location.origin + '/reports/';
  const sessionId = sessionContext.session.id || sessionContext.session._id;
  if (host.substring(7,12) === "local") host = 'http://localhost:5001/reports/';
    console.log(setSeverity, openSnackBar, sessionId);

  const [sendPDFReport] = useMutation(SEND_REPORT);

  useEffect(() => {
    sendPDFReport({variables: {id: user.id, toSend: false}});
    // to set page scroll position from the top
    window.scrollTo(0, 0)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  var averages = [];
  var importances = [];
  for (let i = 1; i < 8; i++) {
    let c = "c" + i;
    averages.push([c, userResults[c].average]);
    importances.push([c, userResults[c].importance]);
  }

  averages.sort(function (a, b) {
    return b[1] - a[1];
  });

  const handleChallengeCancel = () => {
    setClosure(false);
  };

  const sendReport = () => {
    sendPDFReport({variables: {id: user.id}});
    setAlert(true);   
    setMsg("Report email sent successfully!");
    setTimeout(() => {setAlert(false)}, 5000);
  };

  //Snackbar alert Close Handeler
  const handleCloseSnackbar = (event, reason)=> {
    if (reason === "clickaway") {
        return;
    }
    setOpenSnackBar(false);
  }

  return (
    <>
      <Fragment>
        {
        alert ? <SnackBarAlert severity={severity} alertMessage={msg} handleCloseSnackbar={handleCloseSnackbar} openSnackBar={alert} vertical='top' horizontal='center' autoHideDuration={6000} />: <></> 
        }
        {startClosure ? (
          <ChallengeQuestionModal
            user={user}
            startAfterChallenges={false}
            handleChallengeCancel={handleChallengeCancel}
            isModalOpen={startClosure}
          />
        ) : (
          ""
        )}

        <Container
          maxWidth={false}
          disableGutters={true}
          className={`${classes.challengePage}  min-h-screen bg-cover  mx-auto flex justify-between w-100 flex-col lg:flex-row relative`}
        >
          <Sidebar user={user} logout={logout} />
          <Grid container className={`ml-0 lg:ml-52`}>
            <Grid item xs={12}>
              <main className={"challenge_page overflow-hidden"}>
                <div
                  className={`challenge_page__head hidden lg:flex items-center  justify-between py-7 px-3 ${classes.challengePageHead}`}
                >
                  <h3 className="gesta font-normal text-white text-base lg:text-2xl">
                    The Seven Navigation Challenges
                  </h3>
                </div>
                <div
                  className={`${classes.challengePageContent} challenge_page__content flex flex-wrap px-8 md:pt-28 pt-16 pb-0  `}
                >
                  <div className="report_parent_container w-full">
                    <Grid
                      container
                      className="report_head pb-20 hidden lg:flex"
                    >
                      <Grid
                        item
                        xs={12}
                        md={6}
                        className={` ${classes.reportHeadLeft} report_head__left `}
                      >
                        <span className="uppercase">
                            The Seven Navigation Challenge
                        </span>
                        <h2 className="uppercase">Your journey</h2>
                        <p>
                          This mountain range shows a summary of your self-ratings 
                          and your importance scores together. 
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        className={`${classes.reportHeadRight}  report_head__right text-right flex items-end justify-center flex-col`}
                      >
                        <Chip
                          label="Your Capability Rating"
                          className={`${classes.ReportHeadChip} first_chip mb-3`}
                        />

                        <Chip
                          label="Your Importance Rating"
                          className={`${classes.ReportHeadChip} second_chip`}
                        />
                        {
                          (status === "PROGRAM" || status === "FOLLOWUP") ?
                          <Box className=" items-center justify-end pt-7 pr-2 lg:inline-flex">
                            <Button
                              onClick={sendReport}
                              className={`send_mail  border border-mineshaft bg-mineshaft border-solid h-10 w-10 min-w-min rounded-none mr-2`}
                            >
                              <SendIcon className="text-base text-white" />
                            </Button>
                            <Button
                              href={host + "report" + sessionContext.session.id + ".pdf"} 
                              download
                              target="download"
                              className={`pdf_download border border-mineshaft bg-mineshaft border-solid h-10 w-10 min-w-min rounded-none`}
                            >
                              <GetAppIcon className="text-base text-white" />
                            </Button>
                          </Box> : <></>
                        }
                      </Grid>
                    </Grid>

                    <Box className="pb-24">
                      <ChallengeReport2
                        averages={averages}
                        importances={importances}
                        isReport={true}
                        reportLavel={true}
                      />
                    </Box>
                  </div>
                </div>
                {/*  */}


                <div
                  className={`${classes.challengePageFooter} challenge_page__footer flex items-center justify-between lg:justify-end`}
                >
                  <Box className="inline-flex items-center justify-end mt-3 lg:hidden">
                    <Button
                      className={`send_mail  border border-mineshaft bg-mineshaft border-solid h-8 w-8 min-w-min rounded-none mr-2`}
                    >
                      <SendIcon className="text-base text-white" />
                    </Button>
                    <Button
                      className={`pdf_download border border-mineshaft bg-mineshaft border-solid h-8 w-8 min-w-min rounded-none`}
                    >
                      <GetAppIcon className="text-base text-white" />
                    </Button>
                  </Box>
                </div>
              </main>
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    </>
  );
}
