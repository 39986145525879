
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    
    challengeTitleBold: {
    },
    challengeTitleRegular: {
    },
    challengeCardContent: {
        background: '#fff',
        border: 'solid 1px rgba(151, 151, 151, 0.25)',
        marginTop: -1,        
        '@media only screen and (max-width:767px)':{
            background: '#f2f2f2',
            border: 'none',
            marginTop: '4px',
            padding: '1.5rem 3rem', 
        },   
        '@media only screen and (max-width:575px)':{
            padding: '1.5rem', 
        },
    },
    challenge_slider_wrapper:{
        maxWidth: '600px',
        margin: '0 auto',
        '&.very_right':{
            '& .MuiSlider-valueLabel':{
                transform: 'translate(-70%,-10px) !important',
            }
        }
    },
    challengeCardError: {
        border: 'solid 1px #ef4050 !important',
        position: 'relative'
    }
    
}), {index: 1});

export const ChallengeSliderRange = withStyles({
    root: {
        color: '#828282',
        height: 80,
        counterReset: 'rating',
        padding: 0,
        display: 'block',
        marginBottom: 0,
        '& .MuiSlider-mark': {
            '&:before': {
                counterIncrement: 'rating',
                content: 'counter(rating)',
                height: '40px',
                width: '40px',
                position: 'absolute',
                transform: 'translate(-50%, -25%)',
                textAlign: 'center',
                border: '1px solid rgba(130, 130, 130, 0.5)',
                lineHeight: '38px',
                borderRadius: '50%',
                color: '#828282',
                '@media (max-width: 767px)':{
                    height: '35px',
                    width: '35px',
                    lineHeight: '35px',
                    fontSize: '14px',
                    background: '#fff',
                },
            },
        },
        '&.selected':{
            '& .MuiSlider-mark': {
                '&.MuiSlider-markActive': {
                    '&:before':{
                        background:'#121e31',
                        color:'#f9f9f9',
                    }
                }
            }
        }
    },
    thumb: {
        display:'none', 
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:before': {
            content: '""',
            position: "absolute",
            background: "transparent",
            borderTop: "10px solid #007073",
            borderLeft: "5px solid transparent",
            borderRight: "5px solid transparent",
            bottom: "25px",
        },
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% - 4px)',
        top: '-38px',
        '@media only screen and (max-width:639px)':{
            top: '-35px',
        },
        transform: 'transform: scale(1) translate(-50%,-10px)',
        '& *': {
            transform: 'none',
            background: '#007073',
            color: '#fff',
            height: 'max-content',
            width: 'max-content',
            padding: '5px 5px 5px 5px',
            borderRadius: '0px',
            fontSize: '12px',
            letterSpacing: "0",
            fontFamily: "Gesta",
            fontWeight: '400',
            textTransform: 'uppercase',
            '@media only screen and (max-width:639px)':{
                fontSize: '10px',
            }
        },
    },

    markLabel: {
        width: '59px',
        whiteSpace: 'normal',
        fontSize: '14px',
        top: '36px',
        fontFamily: "gesta",
        fontWeight: '500',
        color: "#4f4f4f",
        letterSpacing: "0",
        textAlign: 'center',    
            '@media only screen and (max-width:767px)':{
                fontSize: '12px',
                top: '32px'
            },
            '@media only screen and (max-width:575px)':{
                fontSize: '11px',
            },
            '@media only screen and (max-width:360px)':{
                fontSize: '10px',
            }
    },
    track: {
        height: 0   ,
       
    },
    rail: {
        height: 6,
        borderRadius: 4,
        backgroundColor: 'transparent',
        border: '1px solid #DADBE1',
        opacity: '1',
        display: 'none'
    },
    mark: {
        backgroundColor: 'transparent',
      },
      markActive: {
        opacity: 1,
        backgroundColor: 'transparent',
      }
      
})(Slider);