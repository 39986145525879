/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, Fragment } from 'react';
import { useHistory } from "react-router-dom";
import { AuthContext } from '../context/auth';
import { ResultsContext } from '../context/result';
import { SessionContext } from '../context/session';
import { useResult } from '../hooks/result.js';
import { useMutation, useQuery } from '@apollo/client';
import CloseIcon from '@material-ui/icons/Close';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import Sidebar from '../components/homeDashboard/Sidebar';
//Challenges card
import {challenges} from '../data/ChallengeData';
import ChallengeQuestion from '../components/challenges/ChallengeQuestion';
import {useStyles} from '../styles/ChallengeStyles';
import { GET_QUESTION_ANSWERED_TILL, UPDATE_RESULTS } from '../util/graphql';
// Confirmation Modal
import ConfirmationModal from '../components/ConfirmationModal';
import ChallengeQuestionModal from '../components/challenges/ChallengeQuestionModal'
import bg from "../assets/imgaes/mountain-header.png";
import challengeOneIcon from "../assets/challenges/stat-logo-color.svg";
import challengeTwoIcon from "../assets/challenges/user-icon-color.svg";
import challengeThreeIcon from "../assets/challenges/hand-icon-color.svg";
import challengeFourIcon from "../assets/challenges/brain-icon-color.svg";
import challengeFiveIcon from "../assets/challenges/bookmark-icon-color.svg"
import challengeSixIcon from "../assets/challenges/building-icon-color.svg";
import challengeSevenIcon from "../assets/challenges/culture-icon-color.svg";
import BgStat from "../assets/imgaes/stat-bg-icon.svg";
import BgUser from "../assets/imgaes/user-bg-icon.svg";
import BgHand from "../assets/imgaes/hand-bg-icon.svg";
import BgBrain from "../assets/imgaes/brain-bg-icon.svg";
import BgBookmark from "../assets/imgaes/bookmark-bg-icon.svg";
import BgBuilding from "../assets/imgaes/building-bg-icon.svg";
import BgCulture from "../assets/imgaes/culture-bg-icon.svg";

export default function Challenges(props) {
    const classes = useStyles(props);
    const { user, logout } = useContext(AuthContext);
    const resultsContext = useContext(ResultsContext);
    const sessionContext = useContext(SessionContext);
    const userResults = resultsContext.results;
    const [activeChallenge, setActiveChallenge] = useState(0);
    const [challengeType, setChallengeType] = useState(1);
    const questCount = challenges[activeChallenge].questions.count;
    const [openConfirmationModal,setOpenConfirmationModal] = useState(false);
    const [message, setMessage] = useState('');
    const history = useHistory();
    const [startClosure, setClosure] = useState(false);
    const [questionAnsweredTill, setQuestionAnsweredTill] = useState(0);
    const userSessionId = sessionContext.session.id;
    const [activeModalNumber, setActiveModalNumber] = useState(0);
    const [showCardError, setShowCardError] = useState(false);
    const [errorQuestionNumber, setErrorQuestionNumber] = useState("");
    const [showCardErrorTime, setShowCardErrorTime] = useState(0);

    const userStatus = sessionContext.session.status;
    useEffect(() => {
        if (userStatus === "COMPLETED" && activeModalNumber === 0) {
            props.history.push('/reports');
        }
    }, [userStatus, activeModalNumber]);
        
    const { data } = useQuery(GET_QUESTION_ANSWERED_TILL, {
        variables: { sessionId: userSessionId },
        fetchPolicy: "no-cache"
      });

      useEffect(() => {
        window.scroll(0, 0);
      }, []);

      useEffect(() => {
          if(data){
              setQuestionAnsweredTill(data.getQuestionAnsweredTill);    
          }
      }, [data]);


    var resultId = user.currentResultId.id;
    if (user.currentResultId.id === undefined) {
         resultId = user.currentResultId._id;
    }

    //Confirmation Modal Handeller
    const handleConfirmationModal = () => {
        setOpenConfirmationModal(false);
    }

    const [resultData, setResultData] = useState({
        id: resultId,
        order: (activeChallenge + 1),
        q1: userResults['c'+(activeChallenge + 1)].q1,
        q2: userResults['c'+(activeChallenge + 1)].q2,
        q3: userResults['c'+(activeChallenge + 1)].q3,
        q4: (questCount > 3 ? userResults['c'+(activeChallenge + 1)].q4 : 0 ),
        q5: (questCount < 4 ? userResults['c'+(activeChallenge + 1)].q5 : 0 ),
        average: 1,
        importance: userResults['c'+(activeChallenge + 1)].importance
    });

    // INITIALIZE RESULTS
    const { onChange, values, questionDone } = useResult(userResults);

    const checkAllQuestionsDone = () => {
        let allQuestionDone = true;
        setErrorQuestionNumber(
            Object.keys(questionDone["c" + (activeChallenge + 1)]).filter(function(el) {
                return questionDone["c" + (activeChallenge + 1)][el].toString().toLowerCase() !== "Y".toLowerCase();
            })[0]
        );
        const totalQuestions = questCount + 1;
        let questionAnswered = Object.values(questionDone["c" + (activeChallenge + 1)]).reduce((a, value) => a + (value.toString().toLowerCase() === "Y".toLowerCase() ? 1 : 0), 0)
        if(totalQuestions !== questionAnswered){
            allQuestionDone = false;
        }
        if(questionDone["c" + (activeChallenge + 1)].importance.toString().toLowerCase() !== "Y".toLowerCase()){
            allQuestionDone = false;
        }
        return allQuestionDone;
    };

    useEffect(() => {
        setResultData({ 
            ...resultData, 
            'order': (activeChallenge + 1), 
            'q1': values['c' + (activeChallenge + 1)].q1, 
            'q2': values['c' + (activeChallenge + 1)].q2, 
            'q3': values['c' + (activeChallenge + 1)].q3, 
            'q4': values['c' + (activeChallenge + 1)].q4, 
            'q5': values['c' + (activeChallenge + 1)].q5, 
            'average': values['c' + (activeChallenge + 1)].average,
            'importance': values['c' + (activeChallenge + 1)].importance });
    }, [activeChallenge, challengeType, values])

    // update Results for this session
    const [updateResults] = useMutation(UPDATE_RESULTS, {
        variables: {input: resultData},
        update(_, { data: { updateResults: resultsData } }) {
            // UPDATE RESULTS IN CONTEXT
            resultsContext.change(values);
        },
        onError(err) {
          console.log('err',err);
        }
    });

    const handleNext = () => {
        if (!(activeChallenge === 6)){
            setActiveChallenge(activeChallenge+1);
        } 
        if (activeChallenge === 6) {
            setClosure(true);
        }  
    };

    const checkNext = () => {
        const answeredTill = questionAnsweredTill;
        if((activeChallenge + 1) > answeredTill){
            if (!checkAllQuestionsDone()) {
                setMessage(`You must answer every question before moving to the next navigation challenge`);
                setOpenConfirmationModal(true);
                setShowCardError(true);
                setShowCardErrorTime(showCardErrorTime + 1);
                return;
            }     
        }
        updateResults();
        handleNext();
        setQuestionAnsweredTill((activeChallenge + 1) > questionAnsweredTill ? questionAnsweredTill + 1 : questionAnsweredTill);
        setOpenConfirmationModal(false);
        setShowCardError(false);
        setShowCardErrorTime(0);
        window.scroll(0, 0);
    };

    const handleChallengeCancel = (e) => {
        setActiveChallenge(0);
        setChallengeType(1);
        setClosure(false);
        props.history.push('/challenges');
    }

    const handlePrev = () => {
        if (activeChallenge === 0) {
            props.history.push('/navigation-challenges')
        } else {
            setActiveChallenge(activeChallenge-1);
        }    
        window.scroll(0, 0);
    }

    const getActiveModalNumber = (value) => {
        setActiveModalNumber(value);
    }

    const Challenge = challenges[activeChallenge];

    let challengeIcon = "";
    if(Challenge.id === 1){
        challengeIcon = <img src={challengeOneIcon} className="md:block hidden" alt="" width="64" />;
    }
    else if(Challenge.id === 2){
        challengeIcon = <img src={challengeTwoIcon} className="md:block hidden" alt="" width="64" />;
    }
    else if(Challenge.id === 3){
        challengeIcon = <img src={challengeThreeIcon} className="md:block hidden" alt="" width="64" />;
    }
    else if(Challenge.id === 4){
        challengeIcon = <img src={challengeFourIcon} className="md:block hidden" alt="" width="64" />;
    }
    else if(Challenge.id === 5){
        challengeIcon = <img src={challengeFiveIcon} className="md:block hidden" alt="" width="64" />;
    }
    else if(Challenge.id === 6){
        challengeIcon = <img src={challengeSixIcon} className="md:block hidden" alt="" width="64" />;
    }
    else{
        challengeIcon = <img src={challengeSevenIcon} className="md:block hidden" alt="" width="64" />;
    }

    return (
        <Fragment>
            <Container maxWidth={false} disableGutters={true} className={`${classes.challengePage} min-h-screen bg-cover  mx-auto flex justify-between w-100 flex-col lg:flex-row relative`}>
                <Sidebar user={user} logout={logout} />
                <Grid container className={`ml-0 lg:ml-52  bg-concrete overflow-auto`}>
                    <Grid item xs={12} >
                    {
                    !startClosure ?
                        <main className={'challenge_page bg-contain bg-no-repeat bg-center-top bg-white md:bg-concrete mobile:bg-none md:mt-0 mt-14 relative'} style={{backgroundImage: `url(${bg})`}} >
                            {openConfirmationModal ? <ConfirmationModal message={message} handleConfirmationModal={handleConfirmationModal} /> : <></>}
                            <div className={`challenge_page__head lg:flex hidden items-center justify-between py-8 px-10 ${classes.challengePageHead}`}>
                                <h3 className='gesta font-normal text-alabaster text-4xl'>
                                    The Seven Navigation Challenges
                                </h3>
                                <Button 
                                onClick={() => history.push("/")}
                                className='gesta font-medium text-bigstone bg-alabaster text-lg leading-tight border border-transparent border-solid py-3 px-6 tracking-normal rounded-none' >
                                    End the Audit
                                </Button>
                            </div>
                            <div  className={`${classes.challengePageContent} challenge_page__content flex flex-wrap justify-end relative mx-auto px-5 `} style={{maxWidth: '945px'}}>
                                <div className={`${classes.challengeRight}  challenge_page__right full w-full z-40 w-full relative  `}>
                                    <Box component="div" className={`md:mx-auto md:bg-white md:border-b-2 border-${Challenge.color} border-solid mb-6 md:p-10 -mx-5 p-9 bg-concrete relative ${classes.card}`} >
                                        <Box component="div" className="md:absolute top-0 left-0 md:overflow-x-hidden inline-flex flex-row w-full h-2.5 md:mb-0" style={{marginBottom:'6.6%'}}>
                                            
                                            <Box component="div" className={`relative h-2.5 ${ Challenge.id > 0 ? "bg-lavenderPurple" : "bg-customgray"} flex`} style={{ width: "calc(100% / 7)" }}>
                                                {Challenge.id === 1 && <img src={BgStat} alt="" className='md:hidden w-full max-w-none absolute top-1/2' style={{transform: 'translate(0,-50%)'}} />}
                                            </Box>                                            
                                            <Box component="div" className={`relative ${ Challenge.id > 1 ? "bg-pacificBlue" : "bg-customgray"} h-2.5 flex`} style={{ width: "calc(100% / 7)" }}>
                                                {Challenge.id === 2 && <img src={BgUser} alt="" className='md:hidden w-full max-w-none absolute top-1/2' style={{transform: 'translate(0,-50%)'}} />}
                                            </Box>
                                            <Box component="div" className={`relative h-2.5 ${ Challenge.id > 2 ? "bg-cabaret" : "bg-customgray"} flex`} style={{ width: "calc(100% / 7)" }}>
                                                {Challenge.id === 3 && <img src={BgHand} alt="" className='md:hidden w-full max-w-none absolute top-1/2' style={{transform: 'translate(0,-50%)'}} />}
                                            </Box>
                                            <Box component="div" className={`relative h-2.5 ${ Challenge.id > 3 ? "bg-blueBell" : "bg-customgray"}`} style={{ width: "calc(100% / 7)" }}>
                                                {Challenge.id === 4 && <img src={BgBrain} alt="" className='md:hidden w-full max-w-none absolute top-1/2' style={{transform: 'translate(0,-50%)'}} />}
                                            </Box>
                                            <Box component="div" className={`relative h-2.5 ${ Challenge.id > 4 ? "bg-fireBush" : "bg-customgray"}`} style={{ width: "calc(100% / 7)" }}>
                                                {Challenge.id === 5 && <img src={BgBookmark} alt="" className='md:hidden w-full max-w-none absolute top-1/2' style={{transform: 'translate(0,-50%)'}} />}
                                            </Box>
                                            <Box component="div" className={`relative h-2.5 ${ Challenge.id > 5 ? "bg-blueRibbon" : "bg-customgray"}`} style={{ width: "calc(100% / 7)" }}>
                                                {Challenge.id === 6 && <img src={BgBuilding} alt="" className='md:hidden w-full max-w-none absolute top-1/2' style={{transform: 'translate(0,-50%)'}} />}
                                            </Box>
                                            <Box component="div" className={`relative h-2.5 ${ Challenge.id > 6 ? "bg-ronchi" : "bg-customgray"}`} style={{ width: "calc(100% / 7)" }}>
                                                {Challenge.id === 7 && <img src={BgCulture} alt="" className='md:hidden w-full max-w-none absolute top-1/2' style={{transform: 'translate(0,-50%)'}} />}
                                            </Box>
                                        </Box>
                                        <div className='md:flex items-center md:mb-6'>
                                            <div className='md:mb-0 mb-3 md:mr-6'>
                                                {challengeIcon}
                                            </div>
                                            <div className=''>
                                            <p className='text-emperor mb-1 text-sm md:text-base'>Challenge {Challenge.id} of {challenges.length}</p>
                                                <h2 className="text-xl md:text-2xl gesta-bold mb-6 md:mb-0 text-emperor">{Challenge.challenge}</h2>
                                            </div>
                                        </div>
                                        <p className='text-base mb-6 text-black'>{Challenge.key_attr}</p>
                                        <p className='text-lg text-emperor mb-2'>Key Attributes</p>
                                        <ul className='text-black text-base pl-8 list-disc'>
                                            {Challenge.attributes.map((attr, i) => (<li key={i}>{attr}</li>))}
                                        </ul>
                                    </Box>
                                    <Paper elevation={0} className={`${classes.challenge_questions_parent} bg-concrete challenge_questions_parent mx-auto `}>
                                        <ChallengeQuestion 
                                        chg={onChange} vals={values}
                                        qData={Challenge} challengeType={challengeType} activeChallenge={activeChallenge} 
                                        questionAnsweredTill={questionAnsweredTill} questionStatus={questionDone} 
                                        showCardError={showCardError} errorQuestionNumber={errorQuestionNumber} showCardErrorTime={showCardErrorTime}/>
                                    </Paper>
                                </div>
                            </div>
                            <div className={`${classes.challengePageFooter} challenge_page__footer flex items-center justify-between mt-8 mx-auto md:mb-24 md:px-5`} style={{maxWidth: '945px'}}>
                                <Box className='challenge_button_parent inline-flex items-stretch justify-between flex-row md:flex-row flex-grow'>
                                    <Button onClick={() => history.push("/")} className={`${classes.challenge_button} MuiButton-contained lg:hidden text-bigstone w-1/2 md:w-52 uppercase font-medium inline-flex items-center justify-center bg-alabaster gesta text-base md:text-lg rounded-none py-3.5 px-3 md:px-7 tracking-normal`} >
                                        <CloseIcon className='mr-2 sm:mr-6' style={{ fontSize: 16 }}/>
                                        End audit
                                    </Button>
                                    <Button 
                                    onClick={handlePrev}
                                    variant="contained" 
                                    className={`${classes.challenge_button} text-bigstone w-1/2 md:w-52 uppercase font-medium inline-flex items-center justify-center bg-alabaster gesta text-base md:text-lg rounded-none py-3.5 px-4 md:px-7 tracking-normal`}
                                    >
                                        <KeyboardArrowLeftIcon className='mr-1.5' />
                                        {(activeChallenge === 0 && challengeType === 1) ? 'Previous ' : 'Previous'}
                                    </Button>
                                    <Button 
                                    onClick={checkNext}
                                    variant="contained" 
                                    className={`${classes.challenge_button} bg-bigstone w-1/2 md:w-52 md:w-52 uppercase font-medium inline-flex items-center justify-center text-alabaster gesta text-base md:text-lg rounded-none py-3.5 px-4 md:px-7 tracking-normal`}
                                    >
                                        {(activeChallenge === 6 && challengeType === 2) ? 'Next' : 'Next'}
                                        <KeyboardArrowRightIcon className='ml-1.5' />
                                    </Button>
                                </Box>
                            </div>
                        </main>
                        : 
                        <main className={`${classes.challenge_page} challenge_page bg-contain bg-no-repeat bg-center-top bg-white md:bg-concrete mobile:bg-none md:mt-0 mt-14 relative ${activeModalNumber === 5 ? 'SubmitScreen' : activeModalNumber === 9 ? 'SuccessScreen' : ''}`}>
                            { activeModalNumber !== 9 && activeModalNumber !==10 ?   
                            <div className={`challenge_page__head lg:flex hidden items-center justify-between pt-9 pb-4 px-10 ${classes.challengePageHead}`}>
                                <h3 className='gesta font-normal text-alabaster text-4xl'>
                                </h3>
                                                          
                                <Button 
                                    onClick={() => history.push("/")}
                                    className='gesta font-medium text-bigstone border border-bigstone leading-tight border-solid bg-transparent text-lg py-3 px-6 tracking-normal rounded-none' >
                                    End the Audit
                                </Button>
                            </div>
                            : ""
                            }
                            <div>
                                <ChallengeQuestionModal user={user} startAfterChallenges={true} handleChallengeCancel={handleChallengeCancel}  isModalOpen={startClosure} 
                                    getActiveModalNumber={getActiveModalNumber}
                                />
                            </div>
                        </main>
                        }
                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    )

}