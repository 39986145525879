
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

export const ColorButton = withStyles((theme) => ({
    root: {
        color: '#fff',
        backgroundColor: '#000000',
        fontSize: '14px',
        '&:hover': {
            backgroundColor: '#737D85', 
        },
    },
}))(Button);

export const CustomDialogTitle = withStyles(() => ({
    root: {
        padding: '10px 10px 10px 10px',
        backgroundColor: '#fff',
        borderBottom: "2px solid #DFE6ED",

    }
}))(DialogTitle)


export const ChallengeButtonStyle = withStyles((theme) => ({
    root: {
        borderRadius: '0',
        textTransform: 'uppercase',
        letterSpacing: '0',
        fontFamily: 'gesta',
        fontWeight: '400',
        padding: '6px 33px 6px 33px',
        '&.next_challenge_button': {
            background: '#BDBDBD',
            '& svg': {
                margin: '0 0 0 45px',

            }
        },
        '&.previous_challenge_button': {
            background: '#828282',
            '& svg': {
                margin: '0 45px 0 0px',

            }
        },
    },
}))(Button);

export const ChallengeOutlinedButton = withStyles((theme) => ({
    root: {
        borderRadius: '0',
        color: "#636363",
        fontSize: '16px',
        fontFamily: 'Gesta',
        border: "1px solid #636363",
        padding: "10px 48px",
        textTransform: 'uppercase',
        letterSpacing: '0',
        transition: 'all .30s linear',
        '&.prev': {
            '& + .next': {
                marginLeft: '20px',
                '@media (max-width: 767px)':{
                    marginLeft: '0',
                },
            },
        }
    },
}))(Button);

export const ChallengeFinalButton = withStyles((theme) => ({
    root: {
        borderRadius: '0',
        color: "#FFFFFF",
        fontSize: '16px',
        fontWeight: '500',
        fontFamily: 'Gesta',
        padding: "10px 48px",
        textTransform: 'uppercase',
        letterSpacing: '0',
        transition: 'all .30s linear',
        background: '#121e31',
        width: '100%',
        maxWidth: '400px',
        '&:hover':{
            background: '#121e31',
        }
    },
}))(Button);

export const CssTextField = withStyles({
    root: {
        // backgroundColor: '#fff',
        '& label': {
            fontFamily: "gesta",
            color: '#4F4F4F',
            lineHeight: 'normal',
            position: 'static',
            transform: 'translate(0,0)',
            letterSpacing: '0'
        },
        '& label.Mui-focused': {
            // color: '#EF4050',
            color: '#4F4F4F',
        },
        '& label + .MuiInput-formControl': {
            marginTop: '5px'
        },
        '& .MuiInput-underline:after, & .MuiInput-underline:before': {
            content: 'none'
        },
        '& .MuiOutlinedInput-root': {
            fontFamily: "gesta",            
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#EF4050',
            },
        },
        '& .MuiInput-input': {
            backgroundColor: '#fff',
            padding: '13px 20px',
            fontFamily: 'Gesta',
            lineHeight: '1.25',
            fontSize: '1rem',
            height: 'auto',
            letterSpacing: '0', 
            border: '1px solid #bdbdbd',
            color: '#000',
            width: '100%',
            '&[readonly]':{
                color: '#828282'
            }
        }
    },
})(TextField);

