import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from '../hooks/form';

// Material UI
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

// User Defined
import { headCounts, fundings, countries, counties } from '../data/CompanyData';
import { ColorButton, CustomDialogTitle } from '../styles/CompanySignUpModalStyles';
import { ADD_COMPANY, GET_COMPANIES } from '../util/graphql';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const CompanySignUpModal = forwardRef((props, ref) => {

    // Errors and Open states
    const [open, setOpen] = useState(false);
    const [errors, setErrors] = useState();
    console.warn(errors);

    //Form input handling
    const { onChange, onSubmit, values } = useForm(addCompanyCallback, {
        name: '',
        foundYear: '',
        headCount: 'Select',
        funding: 'Select',
        zip: '',
        country: '',
        state: '',
        city: '',
        address: ''
    });

    // Add company mutation // Values from the form
    const [addCompany] = useMutation(ADD_COMPANY, {
        variables: { input: values },
        refetchQueries: [{ query: GET_COMPANIES, variables: props.variables }],
        onError(err) {
            console.log('err', err);
            setErrors(err.graphQLErrors[0].extensions.exception.errors);
        }
    });

    // Once form submitted, Company is created then closes the modal
    function addCompanyCallback() {
        addCompany();
        setOpen(false);
    }

    // Modal control
    useImperativeHandle(ref, () => ({
        getModal() {
            setOpen(true);
        }
    }));

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <CustomDialogTitle >Create Company</CustomDialogTitle>
            <DialogContent>
                <Box>
                    <form action="">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box pb={2}>
                                    <label htmlFor="name">
                                        <b>Company Details</b>
                                    </label>
                                </Box>
                                <TextField id="name" name="name" label="Company name" onChange={onChange} variant="outlined" fullWidth={true} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <FormControl variant="outlined" fullWidth={true} >
                                    <TextField id="foundYear" name="foundYear" label="Year founding" onChange={onChange} variant="outlined" fullWidth={true} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl variant="outlined" fullWidth={true} >
                                    <InputLabel htmlFor="headCount">Company headcount</InputLabel>
                                    <Select
                                        native
                                        value={values.headCount}
                                        onChange={onChange}
                                        label="Head Count"
                                        inputProps={{
                                            name: 'headCount',
                                            id: 'headCount',
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        {
                                            headCounts && headCounts.map( (hc,index) => {
                                                return (<option value={hc.value} key={index}>{hc.name}</option>)
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl variant="outlined" fullWidth={true}>
                                    <InputLabel htmlFor="funding">Less than 50M</InputLabel>
                                    <Select
                                        native
                                        value={values.funding}
                                        onChange={onChange}
                                        label="Funding"
                                        inputProps={{
                                            name: 'funding',
                                            id: 'funding',
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        {
                                            fundings && fundings.map((f,index) => {
                                                return (<option value={f.value} key={index}>{f.name}</option>)
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box pt={4}>
                                    <h4>Company's Main Office </h4>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth={true}>
                                    <TextField id="zip" name="zip" label="Zip Code" onChange={onChange} defaultValue="" variant="outlined" />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl variant="outlined" fullWidth={true}>
                                    <InputLabel htmlFor="country">Select a Country</InputLabel>
                                    <Select
                                        native
                                        value={values.country}
                                        onChange={onChange}
                                        label="Age"
                                        inputProps={{
                                            name: 'country',
                                            id: 'country',
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        {
                                            countries && countries.map((c,index) => {
                                                return (<option value={c} key={index}>{c}</option>)
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl variant="outlined" fullWidth={true}>
                                    <InputLabel htmlFor="state">Select a State</InputLabel>
                                    <Select
                                        native
                                        value={values.state}
                                        onChange={onChange}
                                        label="State"
                                        inputProps={{
                                            name: 'state',
                                            id: 'state',
                                        }}
                                    >
                                        <option aria-label="None" value="" />
                                        {
                                            counties && counties.map((s,index ) => {
                                                return (<option value={s.value} key={index}>{s.name}</option>)
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth={true}>
                                    <TextField id="city" name="city" label="City" onChange={onChange} defaultValue="" variant="outlined" />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth={true}>
                                    <TextField id="address" name="address" label="Address" onChange={onChange} defaultValue="" variant="outlined" />
                                </FormControl>
                            </Grid>
                        </Grid>

                    </form >
                </Box>
                <Box
                    display='flex'
                    justifyContent="space-between"
                    pt={3}
                    pb={3}
                >
                    <Button onClick={handleClose} color="primary" variant="outlined" >
                        Cancel
                    </Button>
                    <ColorButton
                        onClick={onSubmit}
                        variant="contained"
                        bgcolor="text.secondary" >
                        Create Company Profile


                    </ColorButton>
                </Box>
            </DialogContent>
        </Dialog>
    )
});

export default CompanySignUpModal;