import { useEffect } from "react";

// Material Ui

import TextField from "@material-ui/core/TextField";
import Box from '@material-ui/core/Box';
// User Defined
import ChallengeQuestionDialogHead from "./ChallengeQuestionDialogHead";
import { challengeStyles } from "../../styles/ChallengeQuestionModalStyle";

export default function ChallengeQuestionLastChance(props) {
  const classes = challengeStyles();

  useEffect(() => {
    props.next(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

 

  return (
    <div
      className={`${classes.challengeDialogContent} challenge_dialog_inner_content spendtime submitResponse md:p-0 pb-0 px-9 pt-5`}
    >
      <ChallengeQuestionDialogHead
        headingTitle="What did you learn about yourself as you took this self-audit?"
        headingContent=""
        hasHeadingContent={false}
      />
      <Box>
        
      </Box>
      <div className="challenge_question_dialog_body md:text-center md:mx-auto" style={{maxWidth: '907px'}}>
        <Box className="center">
        <h4 className="text-black gesta text-base md:text-lg gesta md:text-center">
          You will not be able to edit your answers after clicking the “Submit & Continue” button below. 
        </h4>
        </Box>
        <Box className="mt-6 md:mt-20 " >
          <TextField
            className={`gesta  ${classes.fieldParent} `}
            id="filled-multiline-flexible"
            label="Your answer"
            name="notes"
            value={props.vals ?? ""}
            onChange={props.chg}
            multiline
            maxRows={4}
            placeholder="Enter feedback…"
            variant="outlined"
            fullWidth={true}
            autoresize='true'
            InputLabelProps={{
              shrink: true,
            }}
          />
      </Box>
      </div>
    </div>
  );
}
