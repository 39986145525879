import React, { useReducer, createContext } from 'react';

const initialState = {
  results: null
};

const storedResults = sessionStorage.getItem('results');
initialState.results = JSON.parse(storedResults);

const ResultsContext = createContext({
  results: null,
  create: (resultsData) => { },
  change: (resultsData) => { },
  empty: () => { }
});

function resultsReducer(state, action) {
  switch (action.type) {
    case 'CREATE':
      return {
        ...state,
        results: action.payload
      };
    case 'CHANGE':
      return {
        ...state,
        results: action.payload
      };
    case 'EMPTY':
      return {
        ...state,
        results: null
      };
    default:
      return state;
  }
}

function ResultsProvider(props) {
  const [state, dispatch] = useReducer(resultsReducer, initialState);

  function create(resultsData) {
    sessionStorage.setItem('results', JSON.stringify(resultsData));
    dispatch({
      type: 'CREATE',
      payload: resultsData
    });
  }

  function change(resultsData) {
    sessionStorage.removeItem('results');
    sessionStorage.setItem('results', JSON.stringify(resultsData));
    dispatch({
      type: 'CHANGE',
      payload: resultsData
    });
  }

  function empty() {
    sessionStorage.removeItem('results');
    dispatch({ type: 'EMPTY' });
  }

  return (
    <ResultsContext.Provider
      value={{ results: state.results, create, change, empty }}
      {...props}
    />
  );
}

export { ResultsContext, ResultsProvider };
