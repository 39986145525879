import { makeStyles } from '@material-ui/core/styles';

export const cModalStyle = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    attention:{
        position: 'fixed',
        width: 'calc(100% - 208px)',
        top: '0',
        zIndex: '99',
        '& .MuiBackdrop-root':{
            background: 'transparent'
        },
        '& .MuiDialog-scrollPaper':{
            display: 'block',
            transition: 'none !important',
        },
        '& .MuiPaper-root':{
            maxWidth: 'none',
            flex: '0 0 calc(100% - 208px)',
            margin: '0',
            marginLeft: '208px',
            boxShadow: 'none',
        },
        '& .MuiTypography-body1':{
            fontFamily: 'inherit',
        },
        '@media (max-width: 1023px)': {
            width: '100%',
            top: '60px',
            '& .MuiPaper-root':{
                flex: '0 0 100%',
                margin: '0',
            },
        },
        '@media (max-width: 767px)': {
            '& .MuiPaper-root':{
                marginTop: '60px'
            }
        }
    },
}), {index: 1});