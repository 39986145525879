import React from "react";
import {challengeStyles} from '../../styles/ChallengeQuestionModalStyle'


export default function ChallengeQuestionDialogHead({headingTitle,headingContent,hasHeadingContent}) {
  const classes = challengeStyles();
  return (
    <div className={`challenge_question_dialog_head flex-col text-center gesta font-normal ${classes.headingStyle}`}>
      <h2 className={`gesta md:font-medium font-bold`}>
          {headingTitle}
      </h2>
      {
          hasHeadingContent ? <p className="gesta text-sm md:text-lg md:pt-3 text-emperor">
          {
              headingContent
          }
        </p> : ''
      }
      
    </div>
  );
}
