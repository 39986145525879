import { Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: 'gesta',
        overflow: 'hidden',
        '& .MuiInputLabel-root': {
            fontFamily: "gesta",
            fontWeight: '400',
            transform: 'none',
            position: 'static',
            fontSize: '16px',
            color: '#4f4f4f',
            lineHeight: 'normal',
            marginBottom: '5px',
        },
        '& .MuiSelect-select': {
            fontWeight: '400',
            height: 'auto',
            padding: '14px 20px',
            fontSize: '1rem',
            fontFamily: 'Gesta',
            lineHeight: '1.25',
            letterSpacing: '0;',
            border: '0',
            color: '#000'
        },
        '& legend':{
            display: 'none'
        },
        '& fieldset' :{
            top: '0',
            borderColor: '#bdbdbd !important',
            borderRadius: '0',
        },
        '& .MuiSelect-select:focus': {
           backgroundColor: 'transparent'
        }
    },
    paper: {
        textAlign: 'center',
      },
    control: {
        padding: theme.spacing(2),
      },
    dialog: {
        '& .MuiDialog-scrollPaper':{
            pointerEvents: 'none',
            alignItems: 'flex-start',
            paddingTop: '66px',
            '@media (min-width: 768px) and (min-height: 767px)':{
                paddingTop: '166px'
            }
        },
        '& .MuiBackdrop-root':{
            background: 'transparent',
            pointerEvents: 'none',
        },        
        '& .MuiDialog-paper': {
            width: '632px',
            maxWidth: '100%',
            transition: 'all .30s linear',
            boxShadow: 'none',
            background: '#f9f9f9',
            padding: '1rem',
            margin: '0',
            maxHeight: 'calc(100vh - 100px)',
            overflow: 'auto',
            pointerEvents: 'all',
            '@media only screen and (max-width:767px)': {
                background: '#fff',
                padding: '0 2.25rem 2rem',
            }
        },
        '& .dialog_inner': {
            maxWidth: '403px',
            margin: '1.875rem auto',
        },
        '& .MuiInputLabel-formControl':{
            fontSize: '16px !important',
        },
        '& legend':{
            display: 'none'
        },
        '& fieldset' :{
            top: '0',
            borderColor: '#bdbdbd !important',
            borderRadius: '0',
        },
        '& .MuiOutlinedInput-root': {
            fontFamily: 'gesta',
            height: "auto",
            borderRadius: 0,
            '& input':{
                fontSize: '16px',
                fontFamily: 'gesta',
                color: '#000',
                padding: '14px 20px',
                lineHeight: '1.25',
                height: 'auto',
                letterSpacing: 0,
            }
        }
    },
    selectOption: {
        textAlign: 'left',
        fontFamily: "gesta",
        fontWeight: '400',
        fontSize: '18px',
        color: '#000',
        letterSpacing: 0,
        '& .MuiSelect-select': {
            fontWeight: '400',
            height: 'auto',
            padding: '14px 20px',
            fontSize: '1rem',
            fontFamily: 'Gesta',
            lineHeight: '1.25',
            letterSpacing: '0',
            border: '0',
            color: '#000',
            boxSizing: 'border-box',
            maxWidth: 'calc(100vw - 72px)',
        },
        '& .MuiSelect-select:focus': {
           backgroundColor: 'transparent'
        },
        '& legend':{
            display: 'none'
        },        
        '& fieldset' :{
            top: '0',
            borderColor: '#bdbdbd !important',
            borderRadius: '0',
        },
    },
    selectFormControl:{
        fontFamily: 'gesta',
        '& .MuiInputLabel-root': {
            fontFamily: "gesta",
            fontWeight: '400',
            transform: 'none',
            position: 'static',
            fontSize: '16px',
            color: '#4f4f4f',
            lineHeight: 'normal',
            marginBottom: '5px',
        },
        '& legend': {
            maxWidth: '1000px',
            transition: 'max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms'
        }
    },
    resetTitle: {
        fontSize: '35px',
        fontFamily: "gesta",
        fontWeight: '300',
        color: '#636363',
        letterSpacing: '-0.01em',
        borderBottom: '1px solid  rgba(0,0,0,.1)',

    },
    passwordField: {
        '& label': {
            fontSize: '16px',
            fontFamily: "gesta",
            fontWeight: '400',
            letterSpacing: '0px',
        },
        // '& input': {
        //     width: '290px'
        // },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'rgba(0,0,0,.1)',
            },
            '&:hover fieldset': {
                borderColor: '#0096D1',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#0096D1',
            },
        },
    },
    chekBoxParent: {
        borderBottom: '1px solid rgba(0,0,0,.1)'
    },

    resetButton: {
        color: '#fff',
        fontSize: '18px',
        fontFamily: "Gesta",
        fontWeight: '500',
        padding: '15px 47px',
        borderRadius: '0px',
        border: '0',
        backgroundColor: '#121e31',
        letterSpacing: '0',
        lineHeight: '1',
        '&:hover':{
            backgroundColor: '#121e31',
        },
        '& .MuiTouchRipple-root': {
            display: 'none'
        },
        '@media (max-width: 767px)':{
            padding: '15px 20px'
        }
    }
    
}));
