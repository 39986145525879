import { useContext, useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";

// Material
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import json2mq from "json2mq";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import rhrLogo from "../assets/imgaes/rhrLogo.svg";
import { useStyles } from "../styles/AsideStyles";
import ClearIcon from "@material-ui/icons/Clear";
import MenuIcon from "@material-ui/icons/Menu";
import userIcon from "../assets/imgaes/user.png";
import { AuthContext } from '../context/auth';

function Sidebar(props) {
  const classes = useStyles();
  const [sidebNav, setsidebNav] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const pathname = window.location.pathname;
  const [userPopup, setUserPopup] = useState(false);

  

  const isMobile = useMediaQuery(
    json2mq({
      maxWidth: 1023,
    })
  );

  function handleLogout() {
    logout();
    history.push("/");
  }

  let history = useHistory();

  let confirm = () => {
    window.location.reload(history.push("/dashboard/home"));
     };

  let confirmClients = () => {
    window.location.reload(history.push("/dashboard/clients"));
    };

  useEffect(() => {
    if (isMobile) {
    }
  }, [isMobile]);

  const toggleDrawer = () => {
    let currentStatus = sidebNav;
    setsidebNav(!currentStatus);
  };

  const toggleAdmin = () => {
    setUserPopup(!userPopup);
  }

  useEffect(() => {
    if(!user){
        logout();
    }
  }, [user, logout]);

  return (
    <>
      <Box
        component="div" className="flex items-center fixed md:static lg:hidden px-3 py-3.5 w-full top-0 bg-white" style={{zIndex: '52'}}>
        <Box component="div" className="">
          <Button
            className="text-dovegray text-base shadow rounded-none  min-w-min w-8 h-8 mr-3"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </Button>
        </Box>
        <Link to="/dashboard">
          <img src={rhrLogo} className="block mx-auto  " width="57" alt="" />
        </Link>
      </Box>
      {isMobile ? (
        <>
          <Drawer
            open={sidebNav}
            onClose={toggleDrawer}
            component="div"
            className={`w-60 md:h-screen bg-gray-50 flex flex-col items-center justify-between overflow-x-hidden md:fixed left-0 top-0 ${classes.mobileSidebar} `}
          >
            <Box component="div" className="  items-left w-60">
              <Box
                component="div"
                className="flex items-center px-3 py-3 justify-center"
              >
                <Link to="/dashboard">
                  <img src={rhrLogo} className=" " width="57" alt="" />
                </Link>
                <Button
                  className="text-dovegray text-base shadow rounded min-w-min w-6 h-6 hover:bg-red-500 hover:text-white absolute right-3 top-3"
                  onClick={toggleDrawer}
                >
                  <ClearIcon className="text-base" />
                </Button>
              </Box>
              <Box>
                {
                  user ? 
                    <h3 className={`uppercase rajdhani font-bold pt-2 p-5 text-center text-xl md:text-2xl 2xl:text-3xl gesta-bold border-b border-gray-200 mb-5`}>Founder's <br /> Journey</h3> 
                  : ""
                }
              </Box>
              {
                user ? <Box component="div" className="md:py-12 text-left w-full ">
                <MenuList className={`${classes.homeSidebarMenu} uppercase`}>
                  <MenuItem className="gesta-regula text-dovegray mb-4 pl-10 ">
                    <Link
                      onClick={confirm}
                      className={`flex  w-full gesta-medium  ${
                        pathname === "/dashboard/home" || pathname === "/dashboard/home" ? " active text-brandColor " : "text-bigstone  "
                      }`}
                    >
                      Dashboard
                    </Link>
                  </MenuItem>
                  <MenuItem
                    className=" text-dovegray mb-4 pl-10 "
                  >
                    <Link onClick={confirmClients} className={`flex  w-full gesta-medium  ${
                        pathname === "/dashboard/clients" || pathname === "/dashboard/clients" ? " active text-brandColor " : "text-bigstone  "
                      }`}>
                    CLIENTS
                    </Link>
                  </MenuItem>
                  {/* {
                    user && user.isAdmin ?
                        <MenuItem
                        className=" text-dovegray mb-4 pl-10 "
                        >
                        <Link to="/dashboard/coaches" className={`flex  w-full gesta-medium  ${
                            pathname === "/dashboard/coaches" || pathname === "/dashboard/coaches" ? " active text-brandColor " : "text-bigstone  "
                            }`}>
                        COACHES
                        </Link>
                        </MenuItem>
                    : <></>
                    } */}
                </MenuList>
              </Box>: ""
              }
              



            </Box>

            <Box className="flex flex-col justify-center items-start rounded-full w-full">
              {user ? (
                <>
                  <ul className={`${classes.adminPopup} px-4 py-2 justify-start`} style={{maxWidth:'240px'}}>
                    <li className="relative">
                      <span className="leading-6 text-dovegray gesta-regular capitalize flex whitespace-normal block items-center cursor-pointer" onClick={toggleAdmin}>
                        <img src={userIcon} alt="" className="mr-3"/>
                        <span className="whitespace-normal block">{user.firstName} {user.lastName}</span>
                      </span>
                      {userPopup ? 
                        <ul className={`absolute bottom-full bg-full -left-2 bg-white shadow-lg text-center mb-5 }`}>
                          <li className="text-dovegray py-2 px-4 whitespace-nowrap cursor-pointer border-b border-gray-200">
                            <Link to="/changePassword">Change Password</Link>
                          </li>
                          <li className="text-dovegray py-2 px-4 whitespace-nowrap cursor-pointer" onClick={() => handleLogout()}>Logout</li>
                        </ul>
                        : ''
                      }
                    </li>
                  </ul>
                </>
              ) : (
                ""
              )}
            </Box>
          </Drawer>
        </>
      ) : (
        <>
          <Box
            component="div"
            className={`md:w-52 w-full md:h-screen bg-gray-50 flex flex-col items-center overflow-x-hidden md:fixed left-0 top-0 justify-between pb-10`}
          >
            <Box component="div" className="flex flex-row md:flex-col w-full md:w-full justify-between items-center">
              <Box component="div" className="w-full">
                <Link to="/">
                  <img src={rhrLogo} className="block mx-auto pt-5 md:pt-9" alt="" width="57" />
                </Link>
                <h3 className={`uppercase rajdhani font-bold  p-5 text-center text-xl md:text-2xl 2xl:text-3xl gesta-bold md:border-b md:border-gray-200  md:pb-9`}>
                  Founder's
                  <br />
                  Journey
                </h3>
              </Box>
              <Box component="div" className="md:py-8 mt-1 text-left w-full ">
                <MenuList className={`${classes.homeSidebarMenu} uppercase`}>
                  <MenuItem className="gesta-regula text-dovegray mb-4 pl-10 ">
                    <Link
                      onClick={confirm}
                      className={`flex  w-full gesta-medium  ${
                        pathname === "/dashboard/home" || pathname === "/dashboard/home" ? " active text-brandColor " : "text-bigstone  "
                      }`}
                    >
                      Dashboard
                    </Link>
                  </MenuItem>
                  <MenuItem
                    className=" text-dovegray mb-4 pl-10 "
                  >
                    <Link onClick={confirmClients} className={`flex  w-full gesta-medium  ${
                        pathname === "/dashboard/clients" || pathname === "/dashboard/clients" ? " active text-brandColor " : "text-bigstone  "
                      }`}>
                    CLIENTS
                    </Link>
                  </MenuItem>
                </MenuList>
              </Box> 
              

            </Box>

            <Box className="w-full items-center rounded-full">
                {user && user.isAdmin ? (
                    <>
                        <ul className={`${classes.adminPopup} px-6 py-2`}>
                            <li className="relative">
                            <span className="leading-6 text-dovegray gesta-regular capitalize flex whitespace-normal block items-center cursor-pointer" onClick={toggleAdmin}>
                                <img src={userIcon} alt="" className="mr-3"/>
                                {user.firstName} {user.lastName}
                            </span>
                            {userPopup ? 
                                <ul className={`absolute bottom-full bg-full -left-2 bg-white shadow-lg text-center mb-5 }`}>
                                <li className="text-dovegray py-2 px-4 whitespace-nowrap cursor-pointer border-b border-gray-200">
                                    <Link to="/changePassword">Change Password</Link>
                                </li>
                                <li className="text-dovegray py-2 px-4 whitespace-nowrap cursor-pointer" onClick={() => handleLogout()}>Logout</li>
                                </ul>
                                : ''
                            }
                            </li>
                        </ul>
                    </>
                ) : ""}
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default Sidebar;
