import React, { useState, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { Waypoint } from 'react-waypoint'

//Material-UI 
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';

// Page specific
import { useStyles, StyledTableCell, StyledTableRow, StyledPaper, TableStyle, ColorButton } from '../styles/ClientListStyles';
import { GET_COMPANIES } from '../util/graphql';
import CompanySignUpModal from './CompanySignUpModal';


function CompanyList({ searchFor }) {
    const classes = useStyles();
    // Sorting and Searching states, create company modal open state etc
    const [sortFor, setSortFor] = useState('_id');
    const [sortDir, setSortDir] = useState(-1);
    const childRef = useRef();

    

    // Get companies as infinite scroll with fetchMore
    const { data, fetchMore } = useQuery(GET_COMPANIES, {
        variables: {
            limit: 10,
            searchFor,
            sortFor,
            sortDir
        },
    });

    // Show loading if needed
    if (!data || !data.companies) return <div>Loading...</div>;

    // Sets sorting column and direction
    function handleSorting(col) {
        setSortFor(col);
        setSortDir(prev => prev = (prev === 1) ? -1 : 1);
    }

    return (
        <StyledPaper className={classes.root}>

            {/* Modal */}
            <CompanySignUpModal ref={childRef} variables={{
                limit: 10,
                searchFor,
                sortFor,
                sortDir
            }}
            />
            {/* MOdal */}


            <Box
                display="flex"
                justifyContent="space-between"
                elevation={0}
                ml={4} mr={4} pt={3} pb={3}
                borderBottom={1}
                borderColor="grey.500" >
                <h3>Company</h3>
                <ColorButton
                    onClick={() => childRef.current.getModal()}
                    variant="contained"
                    bgcolor="text.secondary" >
                    New Company

                    <AddIcon />
                </ColorButton>
            </Box>

            {/* table */}

            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table" style={TableStyle}>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell  >
                                <IconButton component="button" onClick={() => handleSorting('name')}>
                                    {sortDir === 1 ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                                </IconButton>
                                Company
                            </StyledTableCell>
                            <StyledTableCell align="center" >
                                <IconButton component="button" onClick={() => handleSorting('clients')}>
                                    {sortDir === 1 ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                                </IconButton>
                                Clients
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <IconButton component="button" onClick={() => handleSorting('state')}>
                                    {sortDir === 1 ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                                </IconButton>
                                Location
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <IconButton component="button" onClick={() => handleSorting('status')}>
                                    {sortDir === 1 ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                                </IconButton>
                                Status
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/*  */}

                        {data.companies.companyFeed.map((company, i) => {
                            return (

                                <StyledTableRow key={company.id} >
                                    <StyledTableCell >{company.name}</StyledTableCell>
                                    <StyledTableCell align="center">{company.clients}</StyledTableCell>
                                    <StyledTableCell align="center">{company.state}, {company.country}</StyledTableCell>
                                    <StyledTableCell align="center">{company.status}</StyledTableCell>


                                    {data.companies.hasNextPage && i === data.companies.companyFeed.length - 10 && (
                                        <Waypoint
                                            onEnter={() => {
                                                fetchMore({
                                                    variables: {
                                                        first: 10,
                                                        cursor:
                                                            data.companies.companyFeed[data.companies.companyFeed.length - 1].id
                                                    },
                                                    updateQuery: (pv, { fetchMoreResult }) => {
                                                        if (!fetchMoreResult) {
                                                            return pv;
                                                        }

                                                        return {
                                                            companies: {
                                                                __typename: "CompanyFeed",
                                                                companyFeed: [
                                                                    ...pv.companies.companyFeed,
                                                                    ...fetchMoreResult.companies.companyFeed
                                                                ],
                                                                hasNextPage: fetchMoreResult.companies.hasNextPage
                                                            }
                                                        };
                                                    }
                                                })
                                            }
                                            }
                                        />
                                    )}
                                </StyledTableRow >
                            )
                        })
                        }

                    </TableBody>
                </Table>
            </TableContainer>
        </StyledPaper>
    )
}

export default CompanyList;