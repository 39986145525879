
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import lock from '../../assets/imgaes/lock.svg';



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
    },
    chekBoxParent: {
        // borderBottom: '1px solid rgba(0,0,0,.1)'
    },
    confirmTitle: {
        color: '#00A086',
        fontSize: '22.5px',

    },
    confirmPara: {
        color: '#636363',
        opacity: '1',
        fontSize: '18px'
    }
}));

export default function ResetConfirmation(props) {
    const classes = useStyles();


    return (
        <div className="text-center">

            <div className={ `${classes.chekBoxParent} pt-10 text-center`} >
                <img className="inline-block mt-2 mb-9 md:mb-11"  src={lock} alt="" height="103" width="103" />
                <h2 className={`${classes.confirmTitle} mb-2 text-3xl text-center gesta-medium text-black`}>Password Saved!</h2>
            </div>
            <p className={`${classes.confirmPara} gesta-regular mb-24 md:mb-14`}>
                Thank you for setting your password.
            </p>

        </div>
    )
}