import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import json2mq from 'json2mq';

import { AuthContext } from '../context/auth';
import { useForm } from '../hooks/form';
import { CssTextField } from '../styles/LoginStyles';
import { useStyles } from '../styles/ChangePasswordStyles';
import { CHANGE_PASSWORD } from '../util/graphql';
import passwordValidate from '../util/validators';
import PreLoader from '../components/PreLoader';
import SidebarClient from '../components/homeDashboard/Sidebar';
import SidebarAdmin from '../dashboard/Sidebar';
import SnackBarAlert  from '../components/SnackBarAlert';

function ChangePassword(props) {
    const [alert, setAlert] = useState(false); 
    const [msg, setMsg] = useState('');
    const [severity, setSeverity] = useState('error');
    const classes = useStyles(props);
    const { user, logout } = useContext(AuthContext);
    const email = user ? user.email : null;
    const sessionStatus = user.currentSessionId ? user.currentSessionId.status : "";
    const [reportsActive, setReportsActive] = useState(false);

    useEffect(() => {
        if (sessionStatus === "COMPLETED") {
          setReportsActive(true);
        }
      }, [sessionStatus]);

    useEffect(
    function logoutOnUserChange() {
        if (!email) {
            logout();
        }
    }, [email, logout]);

    const mobileDevice = useMediaQuery(
        json2mq({
            maxWidth: 700,
        }),
    );

    const { onChange, onSubmit, values } = useForm(changePasswordClickHandler, {
        email: email,
        oldPassword: '',
        newPassword: '',
        reEnterPassword: ''
    });

    function changePasswordClickHandler() {
        const oldPassword = values.oldPassword.trim();
        if (oldPassword === '' || !passwordValidate(oldPassword)) {
            setSnackBarAlertDetails(
                true, 
                'error', 
                "Please enter a valid old password with at least 8 characters which should contain at least one numeric digit, one uppercase, one lowercase letter and one special character(not '<' and '>'!", 
                5000);
            return false;
        }
        const newPassword = values.newPassword.trim();
        if (newPassword === '' || !passwordValidate(newPassword)) {
            setSnackBarAlertDetails(
                true, 
                'error', 
                "Please enter a valid new password with at least 8 characters which should contain at least one numeric digit, one uppercase, one lowercase letter and one special character(not '<' and '>'!", 
                5000);
        }
        const reEnterPassword = values.reEnterPassword.trim();
        if (reEnterPassword === '' || !passwordValidate(reEnterPassword)) {
            setSnackBarAlertDetails(
                true, 
                'error', 
                "Please enter a valid re enter password with at least 8 characters which should contain at least one numeric digit, one uppercase, one lowercase letter and one special character(not '<' and '>'!", 
                5000);
            return false;
        }
        if (newPassword !== reEnterPassword) {
            setSnackBarAlertDetails(
                true, 
                'error', 
                "New and re enter password does not match!", 
                5000);
            return false;
        }
        if (oldPassword === newPassword) {
            setSnackBarAlertDetails(
                true, 
                'error', 
                "Please provide different old and new password!", 
                5000);
            return false;
        }
        changePassword();
    }

    const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD, {
        variables: { ...values, confirmPassword: values.reEnterPassword },
        update(_, { data: { changePassword: userData } }) {
            if(userData.email){
                setSnackBarAlertDetails(true, 'success', "Password changed successfully!", 5000);
                values.oldPassword = '';
                values.newPassword = '';
                values.reEnterPassword = '';
                return
            }
            setSnackBarAlertDetails(true, 'error', "Something went wrong!", 5000);
        },
        onError(err) {
            setSnackBarAlertDetails(true, 'error', err.graphQLErrors[0].extensions.errors.general, 5000);
        }
    });

    const setSnackBarAlertDetails = (alert, severity, msg, timeOutInSec) => {
        setAlert(alert);   
        setSeverity(severity);
        setMsg(msg);
        setTimeout(() => {setAlert(!alert)}, timeOutInSec);
    }

    if (loading) return <PreLoader/>;

    return (
        <>
        {
            alert ? <SnackBarAlert severity={severity} alertMessage={msg} openSnackBar={alert} vertical='top' horizontal='center' />: <></> 
        }
            <Container maxWidth={false} disableGutters={true} className={`${classes.changePassword} mx-auto flex justify-between w-100 flex-col lg:flex-row relative`}>
                {
                    user.isAdmin ? 
                    <SidebarAdmin user={user} logout={logout} /> : 
                    <SidebarClient user={user} logout={logout} reportsActive={reportsActive} />
                }
                <Grid container className={`ml-0 lg:ml-52  bg-concrete overflow-auto`}>
                    <Grid item xs={12} >
                        <main className={'changePassword_page bg-no-repeat bg-center-top min-h-screen flex items-center md:pt-0 pt-14 bg-white'} >
                            <Box className='flex items-center justify-center min-h-full flex-col w-full '>
                                <Box className='w-full lg:w-2/4 flex items-center justify-center flex-col'>
                                    <div className="form-container md:bg-alabaster pt-8 md:pt-12 pb-8 md:px-28 px-9  w-full" style={{maxWidth:'632px'}}>
                                        <div className="form-container-top mb-10 text-black text-center">
                                            <h3 className='gesta-medium text-3xl mb-2.5' >
                                                Change Password
                                            </h3>
                                            <p className='text-lg'>
                                                Please enter your password details below
                                            </p>
                                        </div>
                                        <form
                                            className="w-full"
                                            noValidate 
                                            onSubmit={onSubmit}
                                            >
                                            <div className="w-full">
                                                <CssTextField
                                                    id="oldPassword"
                                                    type="password"
                                                    name="oldPassword"
                                                    label="Enter Old Password"
                                                    defaultValue={values.oldPassword}
                                                    onChange={onChange}
                                                    fullWidth={true}
                                                    size={mobileDevice ? 'small': 'medium'}
                                                />
                                            </div>
                                            <div className="w-full mt-4">
                                                <CssTextField
                                                    id="newPassword"
                                                    type="password"
                                                    name="newPassword"
                                                    label="Enter New Password"
                                                    defaultValue={values.newPassword}
                                                    onChange={onChange}
                                                    fullWidth={true}
                                                    size={mobileDevice ? 'small': 'medium'}
                                                />
                                            </div>
                                            <div className="w-full mt-4">
                                                <CssTextField
                                                    id="reEnterPassword"
                                                    type="password"
                                                    name="reEnterPassword"
                                                    label="Re-enter New Password"
                                                    defaultValue={values.reEnterPassword}
                                                    onChange={onChange}
                                                    fullWidth={true}
                                                    size={mobileDevice ? 'small': 'medium'}
                                                />
                                            </div>
                                            <Button
                                                className='capitalize w-full mt-12 rounded-none text-sm md:gesta-regular text-white text-center py-2.5 bg-bigstone text-lg'
                                                variant="contained"
                                                color="primary"
                                                type="submit" >
                                                Submit
                                            </Button>
                                        </form>
                                    </div>
                                </Box>
                            </Box>
                        </main>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default ChangePassword;   