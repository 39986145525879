import { useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

// Report Accordion
import {marks,marks2} from "../../data/ChallengeData";
import { challenges } from "../../data/ChallengeData";
import { ReportAccordionStyle } from "../../styles/ReportAccordionStyle";
// import SvgIcon from "../../components/SvgIcon";

import itemOneIcon from "../../assets/challenges/stat-logo-color.svg"; 
import itemTwoIcon from "../../assets/challenges/user-icon-color.svg";
import itemThreeIcon from "../../assets/challenges/hand-icon-color.svg";
import itemFourIcon from "../../assets/challenges/brain-icon-color.svg";
import itemFiveIcon from "../../assets/challenges/bookmark-icon-color.svg"
import itemSixIcon from "../../assets/challenges/building-icon-color.svg";
import itemSevenIcon from "../../assets/challenges/culture-icon-color.svg";


export default function ReportAccordion(props) {
  const [expanded, setExpanded] = useState(1);
  const classes = ReportAccordionStyle();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const {userResults} = props;
  return (
    <>
      {challenges &&
        challenges.map((item, i=1) => {
          let itemIcon = "", height = "", width = "";
          if(item.id === 1){
            itemIcon = itemOneIcon;
            height = '24';
            width = '28';
          }
          else if(item.id === 2){
            itemIcon = itemTwoIcon;
            height = '17';
            width = '27';
          }
          else if(item.id === 3){
            itemIcon = itemThreeIcon;
            height = '17';
            width = '27';
          }
          else if(item.id === 4){
            itemIcon = itemFourIcon;
            height = '24';
            width = '22';
          }
          else if(item.id === 5){
            itemIcon = itemFiveIcon;
            height = '24';
            width = '24';
          }
          else if(item.id === 6){
            itemIcon = itemSixIcon;
            height = '24';
            width = '26';
          }
          else if(item.id === 7){
            itemIcon = itemSevenIcon;
            height = '24';
            width = '25';
          }
          return (
            <Accordion
              expanded={expanded === item.id}
              onChange={handleChange(item.id)}
              className={`report_accordion ${classes.reportAccordion}`}
              key={i}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${item.id}bh-content `}
                id={`panel${item.id}bh-header`}
                className={`report_accordion_head flex items-center relative pl-16`}
                transitionprops={{ unmountOnExit: true }}
              >
                <span
                  className={`question_border absolute left-0 top-0 h-full bg-${item.color}`}
                ></span>

                <Typography className={`question_heading gesta-medium py-4`}>
                    <img src={itemIcon} alt="" className="accordion_icon absolute left-5" height={height} width={width} />
                  {item.challenge}
                </Typography>
                
              </AccordionSummary>
              <AccordionDetails className={`report_accordion_body ${classes.questionListAnswers}`}>
                <List className={`w-full p-0 pt-1`}>
                {
                    item.questions.quest.map((data,index=1)=>{
                        return(
                            <ListItem key={index} className='bg-white md:p-6 py-5 px-6 flex flex-wrap' disableGutters={true}>
                                <span className="inner_question_title inline-flex text-black">{data}</span>
                                <span className="inner_question_capability text-center inline-flex items-center md:justify-end">
                                    <span className='text-bigstone gesta-medium capitalize'>{marks[userResults['c'+(i+1)]['q'+(index+1)]-1].label}</span>
                                </span>
                            </ListItem>
                        )
                    })
                }{
                  <ListItem  className='bg-white md:p-6 py-5 px-6 flex flex-wrap' disableGutters={true}>
                      <span className="inner_question_title inline-flex text-black">{item.questions.secondary}</span>
                      <span className="inner_question_importance text-center inline-flex items-center md:justify-end">
                        <span className='text-bigstone gesta-medium capitalize'>{marks2[userResults['c'+(i+1)].importance-1].label}</span>
                      </span>
                  </ListItem>
                }
                </List>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </>
  );
}
