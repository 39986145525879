import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    challengePageFooter: {
      height: '72px',
      '& button': {
          borderRadius: '0',
          color: '#F2F2F2',
          fontSize: '18px',
          
          fontFamily: 'gesta',
          fontWeight: '500',
          textTransform: 'uppercase',
          letterSpacing: '0',
          '&:hover':{
            //   backgroundColor: '#30C19C !important',
          },
          '&.next_challenge_button':{
              background: '#BDBDBD',
              '& svg': {
                  margin: '0 0 0 50px',
  
              }
          },
          '&.previous_challenge_button':{
              background: '#828282',
              '& svg': {
                  margin: '0 50px 0 0px',
  
              }
          }
      }
    },
  
    card: {
      maxWidth: '905px',
      width: '100%',
  
      '@media only screen and (max-width: 700px)': {
          width: '100%'
      }
    }
}), {index: 1});