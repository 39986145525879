import { useContext, Fragment, useEffect, useState } from 'react';
import { AuthContext } from '../context/auth';
import { ResultsContext } from '../context/result';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import json2mq from 'json2mq';
import Moment from 'moment';
// Material
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Sidebar from '../components/homeDashboard/Sidebar';
import ChallengeQuestionWellDone from '../components/challenges/ChallengeQuestionWellDone';
//Challenges card
import ChallengeReport2 from '../components/challenges/ChallengeReport2'
import { useStyles } from '../styles/ReportStyles';
import KeyInsights from '../components/KeyInsights/';
import ReportAccordion from "../components/challenges/ReportAccordion";
import ReportBarCompare from "../components/ReportBarCompare";
import SnackBarAlert  from '../components/SnackBarAlert';
import download from "../assets/imgaes/download.svg";
import share from "../assets/imgaes/share.svg";

import { useMutation } from '@apollo/client';
import { SEND_REPORT, DOWNLOAD_REPORT } from '../util/graphql';
import { Button } from '@material-ui/core';
import PreLoader from '../components/PreLoader';
import { SessionContext } from '../context/session';

export default function Report(props) {

    const mobileDevice = useMediaQuery(
        json2mq({
          maxWidth: 767,
        }),
    );

    let clientData = null;
    if(props.location.state && props.location.state.clientData){
        clientData = props.location.state.clientData
    }

    const [goNext, setGoNext] = useState(true);
    const classes = useStyles(props);
    const { user, logout } = useContext(AuthContext);
    const resultsContext = useContext(ResultsContext);
    const userResults = clientData?.currentResultId ?? resultsContext.results;
    const [alert, setAlert] = useState(false); 
    const [msg, setMsg] = useState('');
    const [severity, setSeverity] = useState('error');
    const [open, setOpen] = useState(false);
    const [alternativeEmail, setAlternativeEmail] = useState("");
    const [sendPDFReport] = useMutation(SEND_REPORT);
    const sessionContext = useContext(SessionContext);

    const userStatus = clientData?.currentSessionId?.status ?? sessionContext.session.status;
    if (userStatus !== "COMPLETED") {
        props.history.push('/dashboard');
    }

    const completedOn = Moment(userResults.completedOn).format("DD MMMM YYYY");
    const fileName = `Founder_Journey_Report-${makeCapitalize(user.firstName) + '_' + makeCapitalize(user.lastName) + '_' + completedOn}.pdf`;
    function makeCapitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    var averages = [];
    var importances = [];
    var keyGaps = [];
    for (let i=1; i < 8; i++) {
        let c = 'c'+i;
        averages.push([c, userResults[c].average]);
        importances.push([c, userResults[c].importance]);
        // keyGaps.push([c, (userResults[c].average - userResults[c].importance)]);
        if (userResults[c].importance > userResults[c].average) {
            keyGaps.push([c, (userResults[c].average - userResults[c].importance)]);
        }
    }
    averages.sort(function(a, b) {return b[1] - a[1]});
    keyGaps.sort(function(a, b){return a[1]-b[1]});

    // useEffect
    useEffect(() => {
        // to set page scroll position from the top
        window.scrollTo(0, 0)
    }, []);
    
    const handleClickOpen = () => {
      sendReport();
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    const onAlternativeEmailChange = (event) => {
        setAlternativeEmail(event.target.value);
    }

    const reSendReport = () => {
        sendPDFReport({variables: {id: user.id, email: alternativeEmail, toSend: true, sessionId: clientData?.currentSessionId?.id}});
        setAlert(true);
        setSeverity("success");
        setMsg("Report sent to " + alternativeEmail);
        setTimeout(() => {setAlert(false)}, 5000);
    };

    const sendReport = () => {
        sendPDFReport({variables: {id: user.id, email: null, toSend: true, sessionId: clientData?.currentSessionId?.id}});
        setAlert(true);
        setSeverity("success");
        setMsg("Report email sent successfully!");
        setTimeout(() => {setAlert(false)}, 5000);
    };

    const [downloadReport, {loading}] = useMutation(DOWNLOAD_REPORT, {
        variables: { id: user.id, sessionId: clientData?.currentSessionId?.id },
        onCompleted: (data) => {
            const linkSource = `data:application/pdf;base64, ${data.downloadReport}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        },
        onError(err) {
            console.log('err',err);
        }
    });
    const [tabberValues, setTabberValues] = useState([0, 2,'Highest']);
    const tabsList = [{
        name:"Highest",
        value:[0,2]
    },{
        name:"Lowest",
        value:[averages.length - 2,averages.length]
    },{
        name:"Greatest Opportunities",
        value:[2,averages.length - 2]
    }]
    const downloadReportHandler = () => {
        downloadReport();
    }

    if(loading) {
        return <PreLoader />;
    }

    return (
    <>
    {
        alert ? <SnackBarAlert severity={severity} alertMessage={msg} openSnackBar={alert} vertical='top' horizontal='center' />: <></> 
    }
    <Fragment>
    <Container maxWidth={false} disableGutters={true} className={`${classes.challengePage}  min-h-screen bg-cover  mx-auto flex justify-between w-100 flex-col lg:flex-row relative`}>
        <Sidebar  user={user} logout={logout} reportsActive={true} />

        <Dialog open={open} onClose={handleClose} className={`${classes.challengeQuestionModal}`}>
            <DialogTitle className='challengeQuestionModalClose'>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <ChallengeQuestionWellDone 
                        user={user} 
                        next={setGoNext} 
                        results={resultsContext.results} 
                        chg={onAlternativeEmailChange} 
                        vals={alternativeEmail} 
                        sender={reSendReport} />
                </DialogContentText>
            </DialogContent>
        </Dialog>

        <Grid container className={`ml-0 lg:ml-52`}>
            <Grid item xs={12} >
                <main className={'challenge_page overflow-hidden'} >
                    <div className={`${classes.challengePageContent} challenge_page__content  flex flex-wrap bg-white md:pt-12 pt-16`}>
                        <div className="report_parent_container w-full">
                            <Grid container className="report_head pb-6 lg:flex px-9 md:px-12 md:pt-0 pt-4">
                                <Grid item xs={12} md={6} className={` ${classes.reportHeadLeft} report_head__left`}>
                                    <span>The Seven Navigation Challenges</span>
                                    <h2 className='font-bold rajdhani'>
                                        Your journey
                                    </h2>
                                    <p className='text-base md:text-lg text-emperor'>
                                        {
                                            mobileDevice ? "Use the tabs below to navigate through the results from your self audit and the icons to send, download or export your report" : "This mountain range shows a summary of your self ratings"
                                        }
                                    </p>
                                </Grid>
                                <Grid item xs={12} md={6} className={`${classes.reportHeadRight} report_head__right text-right flex flex-wrap items-end md:justify-end md:pt-0 pt-3.5`}>
                                    <div className="share-btn flex">
                                        <Button className='min-w-0 p-0' onClick={handleClickOpen}>
                                            <img src={share} alt=""/>
                                        </Button>
                                        <Button className='min-w-0 p-0 ml-3.5' onClick={downloadReportHandler}>
                                            <img src={download} alt=""/>
                                        </Button>
                                    </div>
                                    <div className='px-2 hidden md:flex bg-bigstone md:ml-4 mt-2'>
                                        <Chip label="Capability Rating" className={`${classes.ReportHeadChip} first_chip`} />
                                        <Chip label="Importance Rating" className={`${classes.ReportHeadChip} second_chip`} />
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container className='report_body relative mb-20 md:pl-16 md:pr-10 pl-11 pr-9 md:pt-14 pt-7 bg-concrete mt-12 md:mt-0'>
                                
                                <div className='px-1 md:px-2 md:hidden flex bg-bigstone mb-14 md:mb-0'>
                                    <Chip label="Capability Rating" className={`${classes.ReportHeadChip} first_chip`} />
                                    <Chip label="Importance Rating" className={`${classes.ReportHeadChip} second_chip`} />
                                </div>
                                <div className={`tabberList ${classes.tabberList}`}>
                                    <ul>
                                        {tabsList.map((item, index)=> <li key={index} className={`${tabberValues[2] === item.name ? "active tablinks" :"tablinks"}`} onClick={()=>setTabberValues([...item.value,item.name])}>{item.name}</li> )}
                                    </ul>
                                </div>
                                <ChallengeReport2 
                                keyGaps={keyGaps}
                                averages={averages}
                                importances={importances}
                                isReport={true}
                                reportLavel={true}
                                tabberValues={tabberValues}
                                />
                                
                            </Grid>
                        </div>
                        <KeyInsights
                            keyGaps={keyGaps}
                            averages={averages}
                            tabberValues={tabberValues}
                        />
                        {
                            mobileDevice ? <></> : <ReportBarCompare results={userResults} next={setGoNext} />
                        }   
                        
                        <div className={`accordion_parent px-9 md:px-12 pt-14 pb-20 md:py-14 ${classes.accordionParent}`} style={{background: '#f2f2f1'}}>
                            <h5 className="gesta-medium text-xl text-mineshaft mb-1">Challenges</h5>
                            <h3 className="gesta-medium font-light text-dovegray md:text-3xl text-mineshaft mb-6">Self-Audit Feedback</h3>
                            <ReportAccordion
                                averages={averages}
                                importances={importances}
                                userResults={userResults}
                            />
                        </div>
                        
                    </div>
                </main>
            </Grid>
        </Grid>
    </Container>
    </Fragment>
    </>
    )
}