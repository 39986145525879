import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useQuery, useLazyQuery } from "@apollo/client";
import { CSVLink } from "react-csv";

//Material ui
import Button from '@material-ui/core/Button';
import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

// User Defined
import DashboardPieChart from '../components/DashboardPieChart';
import ClientUpcomingList from '../components/ClientUpcomingList';
import PreLoader from '../components/PreLoader';
import Layout from '../components/Layout';
import Sidebar from './Sidebar';
import { GET_REPORTS, GET_CSV } from "../util/graphql";
import Error from "../pages/Error";

export default function Home(props) {
    var host = window.location.origin + '/csv/';
    if (host.substring(7,12) === "local") host = 'http://localhost:5001/csv/';
    const [csvData, setCsvData] = useState([]);
    const [fileDownloadReady, setFileDownloadReady] = useState(false);
    const csvLink = useRef();

    useEffect(() => {
        if (csvLink && csvLink.current && fileDownloadReady) {
            csvLink.current.link.click();
            setFileDownloadReady(false);
            setCsvData([]);
        }
    }, [fileDownloadReady]);
  
    const [loadCsvData] = useLazyQuery(GET_CSV, {
        fetchPolicy: 'network-only', 
        onCompleted: (data) => {
            setCsvData(JSON.parse(data.getCsv));
            setFileDownloadReady(true);
        }
    });

    const lastUpdateActions = {
        "INVITED": "Joined Founder's Journey",
        "IN_PROGRESS": "Setting up the account and taking the self-audit",
        "COMPLETED": "Finished the self-audit",
    };

    // Get all Reports we need
    const { loading, error, data } = useQuery(GET_REPORTS);

    if (loading) return <PreLoader/>;
    if (error) return <Error />

    return(
        <Layout>
            <Helmet>
                <title>Dashboard Home</title>
            </Helmet>
            {/* Sidebar */}
            <Sidebar logout={props.logout}/>

            {/* Cotnent area */}
            <Box className="lg:ml-52 bg-concrete w-auto h-screen pt-24">
            <Grid container spacing={2} className='pl-3'>
                <Grid item xs={12} md={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button 
                                onClick={loadCsvData}
                                variant="outlined" 
                                className='gesta py-2 font-regular text-gray-800 w-full bg-white rounded-none hover:border-gray-50 hover:bg-shamrock hover:text-white ' 
                            >
                                DOWNLOAD CSV         
                            </Button>
                            <CSVLink
                                data={csvData}
                                filename='output.csv'
                                className='hidden'
                                ref={csvLink}
                                target='_blank'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box className='bg-white text-center py-4 shadow'>
                                <p className='text-dovegray text-base pb-5 '>
                                    New Clients
                                </p>
                                <h4 className='rajdhani font-bold text-bigstone text-3xl'>{data.dashReports.reportValues[0]}</h4>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box className='bg-white text-center py-4 shadow'>
                                <p className='text-dovegray text-base pb-5 '>
                                    Total Clients
                                </p>
                                <h4 className='rajdhani font-bold text-bigstone text-3xl'>{data.dashReports.reportValues[1]}</h4>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box className='bg-white center p-4 h-full max-h-full '>
                        <p className="text-dovegray text-base pb-5">Results</p>
                        <DashboardPieChart vals={data.dashReports.reportValues} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={3} className='relative'>
                    <Box className='bg-white center pt-0 p-3 w-full max-h-full overflow-scroll absolute'>
                        <p className='text-dovegray text-base pb-5 sticky  pt-3 top-0 bg-white z-10  '>
                            Last Updates
                        </p>
                        <Box>
                            <ul>
{
data.dashReports.lastUpdates && data.dashReports.lastUpdates.map((item,i)=>{
    var name = "";
    if (item.clientId) {
        name = item.clientId.firstName + " " + item.clientId.lastName
    }
    return(
        <li key={i} className='my-1 p-2 bg-concrete'>
            <p className='gesta font-normal text-sm text-gray-800'>
                <strong>{name}</strong>
                <Link to='#' className='font-medium gesta text-gray-800'></Link> 
                &nbsp; {lastUpdateActions[item.status]}
            </p>
        </li>
    )
}) 
}          
                            </ul>
                        </Box>
                    </Box>
                </Grid>
            </Grid> 
            <Grid container className='pl-3 mt-3'>
                <Grid item xs={12}>
                    <ClientUpcomingList showData={data.dashReports.upcomingSessions} />
                </Grid>
            </Grid>
            </Box>
        </Layout>
    )
}