export const genders = [
    {
        "name": "Select",
        "value": "Select"
    },
    {
        "name": "Male",
        "value": "MALE"
    },
    {
        "name": "Female",
        "value": "FEMALE"
    },
    {
        "name": "Non-binary",
        "value": "NONBINARY"
    },
    {
        "name": "Prefer to self-identify",
        "value": "SELFID"
    },
    {
        "name": "Prefer not to answer",
        "value": "OTHER"
    }
]

export const ethnics = [
    {
        "name": "Select",
        "value": "Select"
    },
    {
        "name": "African American / Black",
        "value": "BLACK"
    },
    {
        "name": "Latinx / Hispanic",
        "value": "HISPANIC"
    },
    {
        "name": "Asian American / Pacific Islander",
        "value": "PACIFIC"
    },
    {
        "name": "Indigenous",
        "value": "NATIVE"
    },
    {
        "name": "White",
        "value": "WHITE"
    },
    {
        "name": "Prefer to self-identify",
        "value": "SELFID"
    },
    {
        "name": "Prefer not to answer",
        "value": "OTHER"
    }
]

export const ages = [
    {
        "name": "Select",
        "value": "Select"
    },
    {
        "name": "21 - 29",
        "value": "TWENTIES"
    },
    {
        "name": "30 - 39",
        "value": "THIRTIES"
    },
    {
        "name": "40 - 49",
        "value": "FOURTIES"
    },
    {
        "name": "50 - 59",
        "value": "FIFTIES"
    },
    {
        "name": "60 or older",
        "value": "SIXTIES"
    },
    {
        "name": "Prefer not to answer",
        "value": "OTHER"
    }
]

export const roles = [
    {
        "name": "Founder",
        "value": "Founder"
    },
    {
        "name": "Manager",
        "value": "MANAGER"
    },
    {
        "name": "Head of Department",
        "value": "HEAD"
    },
    {
        "name": "Other",
        "value": "OTHER"
    }
]

export const numberOfSessions = [
    {
        "name": "2 Sessions",
        "value": 2
    },
    {
        "name": "3 Sessions",
        "value": 3
    },
    {
        "name": "4 Sessions",
        "value": 4
    },
    {
        "name": "5 Sessions",
        "value": 5
    },
    {
        "name": "6 Sessions",
        "value": 6
    },
    {
        "name": "7 Sessions",
        "value": 7
    },
    {
        "name": "8 Sessions",
        "value": 8
    }
]