// User Defined

function NavigationChallengeCard({ number, color, title, text, imgIcon }) {
  if(number === 2) {
    return (
      <div className="xl:w-1/4 md:w-1/3 sm:w-1/2 pr-8 mb-6 md:pt-2 xl:mr-2">
        <div
          className={`bg-white px-5 pb-9 pt-6 relative h-full w-full border-${color}`}
        >
          <div className="flex items-center">
            <img src={imgIcon} alt="" />
            <h3 className="text-emperor gesta text-lg text-sm pl-3" style={{flex:'1 1 calc(100% - 30px)'}}>
              {title}
            </h3>
          </div>
          <p className="text-base text-sm text-black gesta font-normal pt-4">
            {text}
          </p>
          <span
            className={`custom_border absolute top-0 h-2 left-0 w-full  bg-${color}`}
          ></span>
        </div>
      </div>
    );
  }
  return (
    <div className="xl:w-1/4 md:w-1/3 sm:w-1/2 pr-8 mb-6 md:pt-2">
      <div
        className={`bg-white px-5 pb-9 pt-6 relative h-full w-full border-${color}`}
      >
        <div className="flex items-center">
          <img src={imgIcon} alt="" />
          <h3 className="text-emperor gesta text-lg text-sm pl-3" style={{flex:'1 1 calc(100% - 30px)'}}>
            {title}
          </h3>
        </div>
        <p className="text-base text-sm text-black gesta font-normal pt-4">
          {text}
        </p>
        <span
          className={`custom_border absolute top-0 h-2 left-0 w-full  bg-${color}`}
        ></span>
      </div>
    </div>
  );
}

export default NavigationChallengeCard;
