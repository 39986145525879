import React, { useReducer, createContext } from 'react';

const initialState = {
  session: null
};

const storedSession = sessionStorage.getItem('session');
initialState.session = JSON.parse(storedSession);

const SessionContext = createContext({
  session: null,
  initiate: (sessionData) => { },
  progress: (sessionData) => { },
  blankout: () => { }
});

function sessionReducer(state, action) {
  switch (action.type) {
    case 'INITIATE':
      return {
        ...state,
        session: action.payload
      };
    case 'PROGRESS':
      return {
        ...state,
        session: action.payload
      };
    case 'BLANKOUT':
      return {
        ...state,
        session: null
      };
    default:
      return state;
  }
}

function SessionProvider(props) {
  const [state, dispatch] = useReducer(sessionReducer, initialState);

  function initiate(sessionData) {
    sessionStorage.setItem('session', JSON.stringify(sessionData));
    dispatch({
      type: 'INITIATE',
      payload: sessionData
    });
  }

  function progress(sessionData) {
    sessionStorage.setItem('session', JSON.stringify(sessionData));
    dispatch({
      type: 'PROGRESS',
      payload: sessionData
    });
  }

  function blankout() {
    sessionStorage.removeItem('session');
    dispatch({ type: 'BLANKOUT' });
  }

  return (
    <SessionContext.Provider
      value={{ session: state.session, initiate, progress, blankout }}
      {...props}
    />
  );
}

export { SessionContext, SessionProvider };
