import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    challengeCard: {
        
    },
    challengeCardContent: {
        background: '#fff',
    },
    
}), {index: 1});