import { useState, useEffect } from "react";
// Material 
import LinearProgress from "@material-ui/core/LinearProgress";
// User Defined
import {PreloaderStyle} from '../styles/PreLoaderStyle';


function PreLoader(props) {
  const classes = PreloaderStyle();
  const { loaderType } = props;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  if (loaderType === "circle") {
    
  }
  
  return (
    <div className="h-full w-full flex items-center justify-center z-30 fixed bg-white top-0 left-0 ">
      <div className={` ${classes.root} max-w-5xl`}>
        <LinearProgress variant="determinate" value={progress} />
      </div>
      <span className='gesta-regular text-base text-dovegray'>
      &nbsp;&nbsp;
        {
          Math.round(progress) 
        }
         %
      </span>
    </div>
  );
}

export default PreLoader;
