import { useState } from "react";
import { useMutation } from '@apollo/client';
import { useForm } from '../hooks/form';
//Material ui
import FormControl from '@material-ui/core/FormControl';
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';

//Local content
import { genders, ethnics, ages } from "../data/UserData";

// User defined
import {ChallengeFinalButton, CssTextField} from '../styles/CommonStyles';

import { UPDATE_USER } from '../util/graphql';
import SnackBarAlert from './SnackBarAlert';
import { headCounts, fundings, industrySectors } from '../data/CompanyData'
import {emailValidate} from "../util/validators"
import json2mq from "json2mq";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useStyles } from '../styles/CompanyDetailsModalStyles';

function ClientFounderDetailsContent({ client }) {
  const classes = useStyles();
  const companyHeadCounts = {};
  headCounts.map((hc) => {
     return companyHeadCounts[hc.value] = hc.name;
  });
  const companyFundings = {};
  fundings.map((f) => {
    return companyFundings[f.value] = f.name;
  });
  const companyIndustrySectors = {};
  industrySectors.map((f) => {
    return companyIndustrySectors[f.value] = f.name;
  });
  const clientName = client.firstName + ' ' + client.lastName;
  const company = client.companyId;
  const [alert, setAlert] = useState(false); 
  const [msg, setMsg] = useState('');
  const [severity, setSeverity] = useState("error");
  //Snackbar Alert State
  const [setOpenSnackBar] = useState(true);

  const isMobile = useMediaQuery(
    json2mq({
      maxWidth: 767,
    })
  );

  //Snackbar alert Close Handeler
  const handleCloseSnackbar = (event, reason)=> {
      if (reason === "clickaway") {
          return;
      }
      setOpenSnackBar(false);
  }

    //Form input handling
  const { onChange, onSubmit, values } = useForm(updateClientCallback, {
    id: client.id,
    firstName: client.firstName,
    lastName: client.lastName,
    email: client.email,
    phone: client.phone,
    gender:client.gender,
    age: client.age,
    ethnic: client.ethnic,
    companyName: company.name,
    foundYear: company.foundYear,
    headCount: company.headCount,
    funding: company.funding,
    address: company.address,
    currentSeries: company.currentSeries,
    industrySectors: company.industrySectors ? company.industrySectors : ""
  });


  // Add client mutation // Values from the form
  const [updateClient] = useMutation(UPDATE_USER, {
    variables: {
        input: {
            id: values.id,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            gender: values.gender,
            age: values.age,
            ethnic: values.ethnic
        }
    },
    onCompleted: (data) => {
        setSeverity("success");
        setAlert(true);
        setMsg("Founder's details updated successfully!");
        setTimeout(() => {setAlert(false)}, 3000);
    },});

  // Once form submitted, Client is created then closes the modal
  function updateClientCallback() {
    setSeverity("error");
    if (values.firstName.trim() === '') {
        setAlert(true);   
        setMsg("Please enter a first name for the founder!");
        setTimeout(() => {setAlert(false)}, 3000);
        return false;
    }
    if (values.lastName.trim() === '') {
        setAlert(true);   
        setMsg("Please enter a last name for the founder!");
        setTimeout(() => {setAlert(false)}, 3000);
        return false;
    }
    if (values.email.trim() === '') {
        setAlert(true);   
        setMsg("Please enter an email for the founder!");
        setTimeout(() => {setAlert(false)}, 3000);
        return false;
    }
    if (!emailValidate(values.email)) {
        setAlert(true);   
        setMsg("Please enter a correct email for the founder!");
        setTimeout(() => {setAlert(false)}, 3000);
        return false;
    }
    updateClient();
  }

  return (
    <>
    {
    alert ? <SnackBarAlert severity={severity} alertMessage={msg} handleCloseSnackbar={handleCloseSnackbar} openSnackBar={alert} vertical='top' horizontal='center' autoHideDuration={6000} />: ''
    }
    <Box className="bg-white pl-9 pr-9 md:py-12 py-9">

      {/* Head */}
      <Box className="flex items-center justify-between md:border-b md:border-concrete pb-6 ">
        <Box className="inline-flex items-center justify-between w-full">
          <div className="span client_avatar_content">
            <h2 className="gesta-medium  inline-flex  text-mineshaft tracking-tight leading-none"  style={{fontSize:`${isMobile ? '30px' : '42px'}`}}>
              {clientName}
            </h2>
          </div>
        </Box>
      </Box>
      {/* Head */}

      <Box className="w-full xl:w-11/12 md:w-full m-auto">
        <Grid container>
          <Grid item xs={12} md={6} className="md:pt-12 xl:px-10 md:px-4">
            <Grid container spacing={2} className="items-end">
              <Grid item xs={12} className="hidden md:block">
                <Box className="">
                  <h3 className="md:text-2xl text-lg md:font-medium">Personal Details</h3>
                </Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <Box className="text-left md:mt-2 md:mb-0">
                  <FormControl variant="standard" className="w-full">
                    <CssTextField
                      id="name"
                      type="text"
                      name="firstName"
                      label="First Name*"
                      defaultValue={values.firstName}
                      onChange={onChange}
                      fullWidth={true}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <Box className="text-left md:mt-2 md:mb-0">
                  <FormControl variant="standard" className="w-full">
                    <CssTextField
                      id="name"
                      type="text"
                      name="lastName"
                      label="Last Name*"
                      defaultValue={values.lastName}
                      onChange={onChange}
                      fullWidth={true}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className="text-left md:my-0">
                  <FormControl 
                    variant="outlined" 
                    className={classes.selectFormControl} 
                    fullWidth={true}
                  >
                    <InputLabel shrink id="gender">Gender*</InputLabel>
                    <Select
                      labelId="gender"
                      id="gender"
                      name="gender"
                      value={values.gender}
                      onChange={onChange}
                      displayEmpty
                      fullWidth={true}
                      className={classes.selectOption}
                      label="Gender*"
                    >
                      
                      {
                        genders.map((gender, i) => {
                          return (<MenuItem key={i} value={gender.value}>{gender.name}</MenuItem>)
                        })
                      }
                    </Select>
                  </FormControl> 
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className="text-left md:my-0">
                  <FormControl 
                    variant="outlined" 
                    className={classes.selectFormControl} 
                    fullWidth={true}
                  >
                    <InputLabel shrink id="age">Age Group*</InputLabel>
                    <Select
                      labelId="age"
                      id="age"
                      name="age"
                      value={values.age}
                      onChange={onChange}
                      displayEmpty
                      fullWidth={true}
                      className={classes.selectOption}
                      label="Age Group*"
                    >
                      
                      {
                        ages.map((age, i) => {
                          return (<MenuItem key={i} value={age.value}>{age.name}</MenuItem>)
                        })
                      }
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="text-left md:my-0">
                  <FormControl 
                    variant="outlined" 
                    className={classes.selectFormControl} 
                    fullWidth={true}
                  >
                    <InputLabel shrink id="ethnic">Ethnicity*</InputLabel>
                    <Select
                      labelId="ethnic"
                      id="ethnic"
                      name="ethnic"
                      value={values.ethnic}
                      onChange={onChange}
                      displayEmpty
                      fullWidth={true}
                      className={`${classes.selectOption} gesta-regular`}
                      label="Ethnicity*"
                      inputProps={{
                        name: "ethnic",
                        id: "ethnic",
                      }}
                    >
                      
                      {
                        ethnics.map((ethnic, i) => {
                          return (<MenuItem key={i} value={ethnic.value}>{ethnic.name}</MenuItem>)
                        })
                      }
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className="text-left md:my-0">
                  <FormControl variant="standard" className="w-full">
                    <CssTextField
                      id="email"
                      type="email"
                      name="email"
                      label="Email*"
                      defaultValue={values.email.toLowerCase()}
                      onChange={onChange}
                      fullWidth={true}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className="text-left md:my-0">
                  <FormControl variant="standard" className="w-full">
                    <CssTextField
                      id="phone"
                      type="text"
                      name="phone"
                      label="Contact Number*"
                      defaultValue={values.phone}
                      onChange={onChange}
                      fullWidth={true}
                    />
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className="md:pt-12 pt-8 xl:px-10 md:px-4">
            <Grid container spacing={2} className="items-end">
              <Grid item xs={12}>
                <Box className="">
                  <h3 className="md:text-2xl text-lg md:font-medium">Company Details</h3>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box className="text-left md:mt-2 md:mb-0">
                  <FormControl variant="standard" className="w-full">
                    <CssTextField
                      id="company"
                      type="text"
                      name="company"
                      label="Company Name*"
                      defaultValue={values.companyName === "None" ? "" : values.companyName}
                      onChange={onChange}
                      fullWidth={true}
                      InputProps={{
                        readOnly: true,
                    }}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Box className='text-left md:my-0'>
                  <FormControl variant="standard" className="w-full">
                    <CssTextField
                      id="foundYear"
                      type="text"
                      name="foundYear"
                      label="Founding Year*"
                      defaultValue={values.foundYear}
                      onChange={onChange}
                      fullWidth={true}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Box className='text-left md:my-0'>
                  <FormControl variant="standard" className="w-full">
                    <CssTextField
                      id="headCount"
                      type="text"
                      name="headCount"
                      label="Company Headcount*"
                      defaultValue={values.headCount === "Select" ? "" : companyHeadCounts[values.headCount]}
                      onChange={onChange}
                      fullWidth={true}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className='text-left md:my-0'>
                  <FormControl variant="standard" className="w-full">
                    <CssTextField
                      id="currentSeries"
                      type="text"
                      name="currentSeries"
                      label="Current Series / Stage of Funding*"
                      defaultValue={values.currentSeries}
                      onChange={onChange}
                      fullWidth={true}
                      InputProps={{
                        readOnly: true,
                    }}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Box className='text-left md:my-0'>
                  <FormControl variant="standard" className="w-full">
                    <CssTextField
                      id="funding"
                      type="text"
                      name="funding"
                      label="Funding to this Point*"
                      defaultValue={values.funding === "Select" ? "" : companyFundings[values.funding]}
                      onChange={onChange}
                      fullWidth={true}
                      InputProps={{
                        readOnly: true,
                    }}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Box className='text-left md:my-0'>
                  <FormControl variant="standard" className="w-full">
                    <CssTextField
                      id="address"
                      type="text"
                      name="address"
                      label={`${isMobile ? 'Location of HQ*' : 'Location of Headquarters*'}`}
                      defaultValue={values.address}
                      onChange={onChange}
                      fullWidth={true}
                      InputProps={{
                        readOnly: true,
                    }}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className='text-left md:my-0'>
                  <FormControl variant="standard" className="w-full">
                    <CssTextField
                      id="industrySectors"
                      type="text"
                      name="industrySectors"
                      label="Company Description"
                      defaultValue={companyIndustrySectors[values.industrySectors]}
                      onChange={onChange}
                      fullWidth={true}
                      InputProps={{
                        readOnly: true,
                    }}
                    />
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} >
            <Box className='text-center pt-5 md:pt-16'>
              <ChallengeFinalButton onClick={onSubmit} className="w-100 mt-3">save edits</ChallengeFinalButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  </>
  );
}

export default ClientFounderDetailsContent;
