import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';


export const ColorButton = withStyles((theme) => ({
    root: {
        color: '#fff',
        backgroundColor: '#000000',
        fontSize: '14px',
        '&:hover': {
            backgroundColor: '#737D85',
        },
    },
}))(Button);


export const CustomDialogTitle = withStyles(() => ({
    root: {
        padding: '10px 10px 10px 10px',
        backgroundColor: '#fff',
        borderBottom: "2px solid #DFE6ED",

    }
}))(DialogTitle)