import { useContext, useState, forwardRef } from 'react';
import { AuthContext } from '../../context/auth';
import { Redirect } from "react-router-dom";
import { useForm } from '../../hooks/form';
import { useMutation } from '@apollo/client';

//Material-UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { useStyles } from '../../styles/CompanyDetailsModalStyles';
import SnackBarAlert from '../../components/SnackBarAlert';
// QUERIES
import { ADD_USER_COMPANY } from '../../util/graphql';
import { headCounts, fundings, industrySectors } from '../../data/CompanyData';
import DemographicModal from '../challenges/DemographicModal';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export default function CompanyDetailsModal(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [alert, setAlert] = useState(false); 
    const [msg, setMsg] = useState('');
    //Snackbar Alert State
    const [openSnackBar,setOpenSnackBar] = useState(true);
    const { user } = useContext(AuthContext);
    const [showPersonalInfo,setShowPersonalInfo] = useState(false);
    const years = [];
    for (let i = new Date().getFullYear(); i > 2004; i--) {
        years.push(i.toString());
    }    
    console.warn(openSnackBar)

    //Form input handling
    const { onChange, onSubmit, values } = useForm(addUserCompanyCallback, {
        userId: user.id,
        name: '',
        foundYear: '',
        headCount: 'Select',
        funding: 'Select',
        zip: '',
        country: '',
        state: '',
        city: '',
        address: '',
        industrySectors: '',
        currentSeries: ''
    });

    // Add company mutation // Values from the form
    const [addUserCompany] = useMutation(ADD_USER_COMPANY, {
        variables: { input: values },
        update(_,{ data: { createUserCompany: coData }}) {
            props.setCo(coData.name);
            props.setJourney(true); // So that after clicking on create company profile button we can see seven navigation challenges page
            //setShowPersonalInfo(true); //As per client requirements we don't need demographic page
          },
        onError(err) {
            setAlert(true);   
            setMsg("Company could not be registered at this time!");
            setTimeout(() => {setAlert(false)}, 3000);
        }
    });

    // Once form submitted, Company is created then closes the modal
    function addUserCompanyCallback() {     
        let tmp = values.name.trim();
        if (tmp === '') {
            setAlert(true);   
            setMsg("Please enter Company Name!");
            setTimeout(() => {setAlert(false)}, 3000);
            return false;
        } else if (values.foundYear.trim() === '') {
            setAlert(true);   
            setMsg("Please enter the Company Founding Year!");
            setTimeout(() => {setAlert(false)}, 3000);
            return false;
        } else if (values.headCount.trim().length < 3 || values.headCount.trim() === "Select") {
            setAlert(true);   
            setMsg("Please select Company Headcount!");
            setTimeout(() => {setAlert(false)}, 3000);
            return false;
        } else if (values.currentSeries.trim() === '') {
            setAlert(true);   
            setMsg("Please enter the Current Series or Stage of Funding!");
            setTimeout(() => {setAlert(false)}, 3000);
            return false;
        } else if (values.funding.trim().length < 3 || values.funding.trim() === "Select") {
            setAlert(true);   
            setMsg("Please select Funding to this Point!");
            setTimeout(() => {setAlert(false)}, 3000);
            return false;
        } else if (values.address.trim().length < 3) {
            setAlert(true);   
            setMsg("Please enter Location of HQ!");
            setTimeout(() => {setAlert(false)}, 3000);
            return false;
        } else if (values.industrySectors.trim().length < 2 || values.industrySectors.trim() === "Select") {
            setAlert(true);   
            setMsg("Please enter Company Description!");
            setTimeout(() => {setAlert(false)}, 3000);
            return false;
        }
        addUserCompany();
        //setShowPersonalInfo(true); //As per client requirements we don't need demographic page
    }

    //Snackbar alert Close Handeler
    const handleCloseSnackbar = (event, reason)=> {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false);
    }

    const handleClose = (e) => {
        setOpen(false);
        return <Redirect to="/dashboard"/>
    };

    return (
        <>
            {
            alert ? <SnackBarAlert severity='error' alertMessage={msg} handleCloseSnackbar={handleCloseSnackbar} openSnackBar={alert} vertical='top' horizontal='center' autoHideDuration={6000} />: <></> 
            }
            <Dialog
                TransitionComponent={Transition}
                className={classes.dialog}
                onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <div className="dialog_inner relative">
                    {
                        !showPersonalInfo ?  <>
                        <div className='mb-7' onClose={handleClose}>
                            <h2 className="text-3xl	mb-2 font-medium text-dovegray">
                                Company Details
                            </h2>
                            <p className="text-black text-lg gesta">
                                Can you give us some information about your company?
                            </p>
                        </div>
                        <div>
                            <Grid container  className={classes.root} spacing={2}>
                                {/* Company Name */}
                                <Grid item xs={12} className="py-2">                                   
                                    <FormControl 
                                    variant="outlined" 
                                    className={classes.selectFormControl} 
                                    fullWidth={true}
                                    >
                                        <TextField
                                            className={`classes.passwordField text-black gesta lg:text-lg`}
                                            label="Company Name*"
                                            id="name"
                                            name="name"
                                            type="text"
                                            fullWidth={true}
                                            value={values.name}
                                            onChange={onChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </Grid>
                                {/* Company Year Found */}
                                <Grid item xs={6} className="py-2">
                                    <FormControl 
                                    variant="outlined" 
                                    className={classes.selectFormControl} 
                                    fullWidth={true}
                                    >
                                        {/* <InputLabel shrink id="foundingYear-label">Founding Year</InputLabel> */}
                                        <TextField
                                            className={`classes.passwordField text-black gesta lg:text-lg`}
                                            label="Founding Year*"
                                            id="foundYear"
                                            name="foundYear"
                                            type="text"
                                            fullWidth={true}
                                            value={values.foundYear}
                                            onChange={onChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                        {/* <Select
                                            labelId="foundingYear-label"
                                            id="foundYear"
                                            name="foundYear"
                                            value={values.foundYear}
                                            onChange={onChange}
                                            displayEmpty
                                            fullWidth={true}
                                            label="Year of Founding*"
                                            className={classes.selectOption}
                                            variant='outlined'
                                        >
                                            <MenuItem value="">
                                                Select A Year
                                            </MenuItem>
                                            {
                                                years && years.map((y,i) => {
                                                    return (<MenuItem key={i} value={y}>{y}</MenuItem>)
                                                })
                                            }
                                            <MenuItem value="2004">
                                                2004 or before
                                            </MenuItem>
                                        </Select> */}
                                    </FormControl>
                                </Grid>
                                {/* Company Head Count */}
                                <Grid item xs={6} className="py-2">
                                    <FormControl 
                                        variant="outlined" 
                                        className={classes.selectFormControl} 
                                        fullWidth={true}
                                    >
                                        <InputLabel shrink id="companyHeadCount-label">Company Headcount*</InputLabel>
                                        <Select
                                            labelId="companyHeadCount-label"
                                            id="headCount"
                                            name="headCount"
                                            value={values.headCount}
                                            onChange={onChange}
                                            displayEmpty
                                            fullWidth={true}
                                            className={classes.selectOption}
                                            label="Company Headcount*"
                                        >
                                            {/* <MenuItem value="">
                                            Select
                                            </MenuItem> */}
                                            {
                                            headCounts && headCounts.map((hc,i) => {
                                                return (<MenuItem key={i} value={hc.value}>{hc.name}</MenuItem>)
                                            })
                                            }
                                        </Select>
                                    </FormControl>    
                                </Grid>

                                {/* Current Series or Stage of Funding */}
                                <Grid item xs={12} className="py-2">                                   
                                    <FormControl 
                                    variant="outlined" 
                                    className={classes.selectFormControl} 
                                    fullWidth={true}
                                    >
                                        <TextField
                                            className={`classes.passwordField text-black gesta lg:text-lg`}
                                            label="Current Series or Stage of Funding*"
                                            id="currentSeries"
                                            name="currentSeries"
                                            type="text"
                                            fullWidth={true}
                                            value={values.currentSeries}
                                            onChange={onChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </Grid>
                                {/* Company Year Found */}

                                {/* Company Funding */}
                                <Grid item xs={6} className="py-2">
                                <FormControl 
                                    variant="outlined" 
                                    className={classes.selectFormControl} 
                                    fullWidth={true}
                                    >
                                        <InputLabel shrink id="funding-label">Funding to this Point*</InputLabel>
                                        <Select
                                            labelId="funding-label"
                                            id="funding"
                                            name="funding"
                                            value={values.funding}
                                            onChange={onChange}
                                            displayEmpty
                                            fullWidth={true}
                                            className={`${classes.selectOption} gesta-regular`}
                                            label="Funding*"
                                        >
                                            
                                            {
                                            fundings && fundings.map((f,i) => {
                                                return (<MenuItem key={i} value={f.value}>{f.name}</MenuItem>)
                                            })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {/* Company address */}
                                <Grid item xs={6} className="py-2">
                                <FormControl 
                                    variant="outlined" 
                                    className={classes.selectFormControl} 
                                    fullWidth={true}
                                    >
                                    <TextareaAutosize
                                        className={`${classes.passwordField} gesta-regular`}
                                        label="Location of HQ*"
                                        id="address"
                                        name="address"
                                        value={values.address}
                                        minRows={8}
                                        onChange={onChange}
                                        fullWidth={true}
                                        // defaultValue={values.address}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                    />
                                    </FormControl>
                                </Grid>

                                {/* Company Description*/}
                                <Grid item xs={12} className="py-2">
                                    <FormControl 
                                        variant="outlined" 
                                        className={classes.selectFormControl} 
                                        fullWidth={true}
                                    >
                                        <InputLabel shrink id="funding-label">Company Description</InputLabel>
                                        <Select
                                            labelId="funding-label"
                                            id="industrySectors"
                                            name="industrySectors"
                                            value={values.industrySectors}
                                            onChange={onChange}
                                            displayEmpty
                                            fullWidth={true}
                                            className={`${classes.selectOption} gesta-regular`}
                                            label="Funding*"
                                        >
                                            <MenuItem value="">
                                            Select
                                            </MenuItem>
                                            {
                                            industrySectors && industrySectors.map((f,i) => {
                                                return (<MenuItem key={i} value={f.value}>{f.name}</MenuItem>)
                                            })
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {/* Company Name */}
                                <Grid item xs={12} className='py-2 mt-5'>
                                    <Button 
                                    variant="outlined"
                                    onClick={onSubmit}
                                    className={`${classes.resetButton} gesta bg-bigstone text-white w-full`}>
                                           Create Company Profile
                                    </Button>
                                </Grid> 
                                <Grid item xs={12} className='py-2'>
                                    <div className="text-center">
                                        <button onClick={props.logout} className='p-2 font-medium text-lg text-bigstone'>Logout</button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        </> : <DemographicModal handleClose={handleClose} goto={props.setJourney} logout={props.logout} />
                    }
                </div>
            </Dialog>
        </>
    )
}