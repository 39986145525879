import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: '15px'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    challengePage: {
        
    },
    cContainer: {
    },
    challengePageHead: {
        backgroundColor: 'transparent',
        position: 'relative',
        zIndex: '2',
        '& h3': {
            fontSize: "28px",
        },
        '& button': {

        },

    },
    challenge_page: {
        // '&.SubmitScreen': {
        //     background: 'white !important',
        //     '& .challenge_dialog_inner': {
        //         background: 'white !important',
        //     }
        // },
        // '&.SuccessScreen': {
        //     background: 'white !important',
        //     '& .challenge_dialog_inner': {
        //         background: 'white !important',
        //     }
        // },
        
    },
    challenge_questions_parent: {
    },
    challengePageContent: {
        '@media only screen and (max-width:1023px)': {
        },
        '& .challenge_page__left': {
            flex: '0 0 40%',
            maxWidth: '40%',
        },
        
    },
    challengeRight: {
    },
    challengeMountain: {

    },
    challenge_button:{
       
        '@media only screen and (min-width:1200px)':{
            fontSize: '18px',
        }
    },
    interactiveMountain: {
        flex: "0 0 50%",
        maxWidth: "50%",
        position: "relative",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        '& .sicon': {
            transition: 'all .30s linear',
            width: "100%",
            position: "absolute",
            height: "50px",
            bottom: 0,
            left: '50%',
            transformOrigin: "bottom left",
            transform: "scale(1,1) translateX(-50%)",
            
        },
        '&.m_mountain': {
            '& svg' : {
                marginLeft: "50px",
            }
        }
    },
}), {index: 1});