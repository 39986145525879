import React, { useState, useContext, useRef } from 'react';
import { AuthContext } from '../context/auth';
import { useQuery } from '@apollo/client';
import { Waypoint } from 'react-waypoint'
// Material Ui
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

// User Defined
import { useStyles, StyledTableCell, StyledTableRow, StyledPaper, TableStyle } from '../styles/ClientListStyles';
import { ColorButton } from '../styles/CommonStyles';
import { GET_COACHES } from '../util/graphql';
import CoachSignUpModal from './CoachSignUpModal';

function ClientList({ searchFor }) {
    const { user, logout } = useContext(AuthContext);
    // KILLER
    if (!user.isAdmin) logout();
    
    const classes = useStyles();
    // Sorting and Searching states, create company modal open state etc
    const [sortFor, setSortFor] = useState('_id');
    const [sortDir, setSortDir] = useState(-1);
    const [coach, setCoach] = useState({});
    const childRef = useRef();

    // Get coaches as infinite scroll with fetchMore
    const { data, fetchMore } = useQuery(GET_COACHES, {
        variables: {
            limit: 10,
            searchFor,
            sortFor,
            sortDir
        },
    });

    // Sets sorting column and direction
    function handleSorting(col) {
        setSortFor(col);
        setSortDir(prev => prev = (prev === 1) ? -1 : 1);
    }

    function handleCoachUpdate(coach) {
        setCoach(coach);
        childRef.current.getModal();
    }

    return (
        <StyledPaper className={classes.root}>
            {/* Modal */}
            <CoachSignUpModal ref={childRef} coach={coach} variables={{
                limit: 10,
                searchFor,
                sortFor,
                sortDir
            }}
            />
            {/* Modal */}
            <Box
                display="flex"
                justifyContent="space-between"
                elevation={0}
                ml={4} mr={4} pt={3} pb={3}
                borderBottom={1}
                borderColor="grey.500" >
                <h3>Coaches</h3>
                <div>
                <ColorButton
                    onClick={() => childRef.current.getModal()}
                    variant="contained"
                    bgcolor="text.secondary" >
                    New Coach
                    <AddIcon />
                </ColorButton>
                </div>       
            </Box>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table" style={TableStyle}>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell  >
                                <IconButton component="span" onClick={() => handleSorting('firstName')}>
                                    {sortFor === 'firstName' && sortDir === 1 ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                                </IconButton>
                                Coach
                            </StyledTableCell>
                            <StyledTableCell align="center" >
                                <IconButton component="span" onClick={() => handleSorting('role')}>
                                    {sortFor === 'role' && sortDir === 1 ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                                </IconButton>
                                Role
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <IconButton component="span" onClick={() => handleSorting('companyId')}>
                                    {sortFor === 'companyId' && sortDir === 1 ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                                </IconButton>
                                Company
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <IconButton component="span" onClick={() => handleSorting('status')}>
                                    {sortFor === 'status' && sortDir === 1 ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                                </IconButton>
                                Status
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                Actions
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {            
                    data && data.coaches.userFeed.map((coach, i) => {
                        return (
                        <StyledTableRow key={i} >
                            <StyledTableCell >{coach.firstName} {coach.lastName}</StyledTableCell>
                            <StyledTableCell align="center">{coach.role}</StyledTableCell>
                            <StyledTableCell align="center">{coach.companyId.name}</StyledTableCell>
                            <StyledTableCell align="center">
                                <span className="bg-shamrock text-white p-2 rounded-full text-xs">ACTIVE</span>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                <IconButton onClick={() => handleCoachUpdate(coach)}><MoreHorizIcon /></IconButton>
                            </StyledTableCell>
                            {/* AUTO LOAD MORE */}
                            {data.coaches.hasNextPage && i === data.coaches.userFeed.length - 10 && (
                                <Waypoint onEnter={() =>
                                    fetchMore({
                                        variables: {
                                            first: 50,
                                            cursor: data.coaches.userFeed[data.coaches.userFeed.length - 1].id
                                        },
                                        updateQuery: (pv, { fetchMoreResult }) => {
                                            if (!fetchMoreResult) {
                                                return pv;
                                            }
                                            return {
                                                coaches: {
                                                    __typename: "UserFeed",
                                                    userFeed: [
                                                        ...pv.coaches.userFeed,
                                                        ...fetchMoreResult.coaches.userFeed
                                                    ],
                                                    hasNextPage: fetchMoreResult.coaches.hasNextPage
                                                }
                                            };
                                        }
                                    })
                                    }
                                />
                            )}
                        </StyledTableRow >
                        )
                    })
                    }
                    </TableBody>
                </Table>
            </TableContainer>
        </StyledPaper>
    )
}

export default ClientList;