import { alpha, makeStyles, withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';

export const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#fafafa",
    fontSize: "20px",
    lineHeight: "24px",
    display: "flex",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    flex: '1 1 100%',
    boxSizing:"border-box",
    '@media (min-width: 768px)':{
      maxWidth: "300px",
    }
  },
  inputInput: {
    padding: theme.spacing(1.5),
    transition: theme.transitions.create("width"),
    width: "100%",
    fontSize: '18px',
    lineHeight: '1.23',
    height: '100%',
    boxSizing: 'border-box',
    border: '1px solid #bdbdbd',
    fontFamily: 'gesta',
    background: '#fff',
    color: '#000',
    '&::-webkit-input-placeholder': {
      color: '#000',
      opacity: '1',
    },
    '&::-moz-placeholder': {
      color: '#000',
      opacity: '1',
    },
    '&:-ms-input-placeholder': {
      color: '#000',
      opacity: '1',
    },
    '&:-moz-placeholder': {
      color: '#000',
      opacity: '1',
    },
    [theme.breakpoints.up("md")]: {
      width: "300px",
    },
  },
  tableContainer: {
    width: '100%',
    boxShadow: 'none',
  },
  container: {
    maxHeight: '440px',
    '&::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        boxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
        backgroundColor: '#555'
    }
  },
  stickyHeader: {
      backgroundColor: '#fff !important',
      color: '#636363',
      fontWeight: 'bold',
      fontSize: '14px',
      letterSpacing: '0.05em'
  },
  tableData: {
      color: '#636363',
      opacity: 0.8
  },
}), {index: 1});

export const BootstrapButton = withStyles({
  root: {
    boxShadow: "none",
    fontSize: '18px',
    padding: "12px",
    lineHeight: '1.34',
    textTransform: "uppercase",
    borderRadius: 0,
    backgroundColor: "#121e31",
    color: "#fff",
    minWidth: '0',
    "&:hover": {
      backgroundColor: "#121e31",
      color: "#fff",
    },
    // "&:active": {
    //   boxShadow: "none",
    //   backgroundColor: "#444",
    //   borderColor: "#444",
    // },
    // "&:focus": {
    //   boxShadow: "0 0 0 0.2rem rgba(0,0,0,0.1)",
    // },
    
    '&.headerBtn':{
      paddingLeft: '25px',
      paddingRight: '25px',
      '@media(max-width: 767px)':{
        padding:'8px',
      }
    }
  },
})(Button);

