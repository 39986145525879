import { useStyles } from '../../styles/ReportStyles';
// Material
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import EmojiFlagsSharpIcon from '@material-ui/icons/EmojiFlagsSharp';

import SvgIcon from '../SvgIcon';
import { mountainsData } from '../../data/ChallengeData';
import useWindowSize from '../useWindowSize';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import json2mq from 'json2mq';  


export default function ChallengeReport2({keyGaps,importances,isReport,averages,activeChallenge,reportLavel,tabberValues,qData}){

    const mobileDevice = useMediaQuery(
        json2mq({
          maxWidth: 767,
        }),
    );
    const classes = useStyles();
    const rhrWindow = useWindowSize();
    
    function calculateChipPosition(average,importance) {
        const averageVal = Number(average);
        const importanceVal = Number(importance);
        if(averageVal>importanceVal && averageVal - importanceVal <=1 ){   
            return 1;
        }
        if(importanceVal>averageVal && importanceVal - averageVal <=1 ){
            return 2;
        }
    }


    return(
        <>
            
            <Grid container className='report_body relative '>
                <Grid container className='report_body relative'>
                    <Grid item xs={12} className="report_body__content ">
                        <div className={`${classes.reportScale} report_scale ${rhrWindow.height<600 ? ' smallscale ' : ''} `}>           
                            <div className="ruler ruler_1"><span className="scale_number">1</span></div>
                            <div className="ruler ruler_2"><span className="scale_number">2</span></div>
                            <div className="ruler ruler_3"><span className="scale_number">3</span></div>
                            <div className="ruler ruler_4"><span className="scale_number">4</span></div>
                            <div className="ruler ruler_5"><span className="scale_number">5</span></div>
                            <div className="ruler ruler_6"><span className="scale_number">6</span></div>
                            <div className="ruler ruler_7"><span className="scale_number">7</span></div>
                        </div>
                        <div className={`${classes.reportMountainContainer} report_mountainer_container flex items-end md:hidden justify-around`}>
                            {
                                isReport ? <>
                                { mobileDevice && tabberValues[2] === "Greatest Opportunities" ? <>
                                    {
                                        keyGaps.length === 0 ?
                                        <>
                                        {
                                            averages && averages.slice(5,7).map((item, i) => {
                                                var cNum = Number(item[0][1]);
                                                return ( <span className={`${classes.singleReportMountain} single_report_mountain flex-1} `}
                                                style={{ height: `${item[1] > importances[cNum - 1][1] ? item[1]*41 : importances[cNum - 1][1]*41}px` }} key={i}>
                                                <div className=''>
                                                    <div className={`flex absolute bg-bigstone px-0.5 ${classes.chipWrapper}`}
                                                    style={{
                                                        bottom:`${item[1] > importances[cNum - 1][1] ? item[1]*41 : importances[cNum - 1][1]*41}px`
                                                    }}>
                                                        <Chip 
                                                        label={item[1]} 
                                                        className={`${classes.Chip} mountain_label`}
                                                    
                                                        />
                                                        <Chip 
                                                        label={importances[cNum - 1][1]} 
                                                        className={`${classes.Chip} shadow_label`} 
                                                        />
                                                    </div>
                                                </div>
                                                <SvgIcon 
                                                clName={` report_mountain `}
                                                style={{
                                                    transform: `
                                                    scale(1.18, ${(item[1]/1.255)}) translateX(-50%)`,
                                                    // scale(0.85, ${(item[1]/1.59)}) translate(-50%, 43px)`,
                                                }}
                                                icon={`mountain-${cNum}`} 
                                                fill='auto'
                                                />
                                                    
                                                <SvgIcon 
                                                clName='report_shadow z-0'
                                                style={{
                                                    transform: `
                                                    scale(2.4,${(importances[cNum - 1][1]/1.255)}) translateX(-50%)`,
                                                    // scale(0.85,${(importances[cNum - 1][1]/3.33)}) translate(-50%, 8px)`,
                                                }}
                                                icon={`shadow-${cNum}`} 
                                                fill='auto'
                                                />
                                                
                                                {
                                                reportLavel  ? 
                                                <span className={`${classes.mountainText}  absolute mountain_text uppercase gesta-medium `}>
                                                    {mountainsData[cNum - 1]}
                                                </span> : ''
                                                }   
                                            </span>)
                                                
        
        
                                            }) 
                                        }
                                        </>
                                        :
                                        <>
                                        {keyGaps && keyGaps.slice(0,3).map((item, i) => {
                                            var cNum = Number(item[0][1]);
                                            return ( <span className={`${classes.singleReportMountain} single_report_mountain flex-1} `}
                                            style={{ height: `${`${importances[cNum - 1][1] + item[1]}` > importances[cNum - 1][1] ? `${importances[cNum - 1][1] + item[1]}`*41 : importances[cNum - 1][1]*41}px` }} key={i}>
                                            <div className=''>
                                                <div className={`flex absolute bg-bigstone px-0.5 ${classes.chipWrapper}`}
                                                style={{
                                                    bottom:`${`${importances[cNum - 1][1] + item[1]}` > importances[cNum - 1][1] ? `${importances[cNum - 1][1] + item[1]}`*41 : importances[cNum - 1][1]*41}px`
                                                }}>
                                                    <Chip 
                                                    label={`${importances[cNum - 1][1] + item[1]}`} 
                                                    className={`${classes.Chip} mountain_label`}
                                                
                                                    />
                                                    <Chip 
                                                    label={importances[cNum - 1][1]} 
                                                    className={`${classes.Chip} shadow_label`} 
                                                    />
                                                </div>
                                            </div>
                                            <SvgIcon 
                                            clName={` report_mountain `}
                                            style={{
                                                transform: `
                                                scale(1.18, ${(`${importances[cNum - 1][1] + item[1]}`/1.255)}) translateX(-50%)`,
                                                // scale(0.85, ${(item[1]/1.59)}) translate(-50%, 43px)`,
                                            }}
                                            icon={`mountain-${cNum}`} 
                                            fill='auto'
                                            />
                                                
                                            <SvgIcon 
                                            clName='report_shadow z-0'
                                            style={{
                                                transform: `
                                                scale(2.4,${(importances[cNum - 1][1]/1.255)}) translateX(-50%)`,
                                                // scale(0.85,${(importances[cNum - 1][1]/3.33)}) translate(-50%, 8px)`,
                                            }}
                                            icon={`shadow-${cNum}`} 
                                            fill='auto'
                                            />
                                            
                                            {
                                            reportLavel  ? 
                                            <span className={`${classes.mountainText}  absolute mountain_text uppercase gesta-medium `}>
                                                {mountainsData[cNum - 1]}
                                            </span> : ''
                                            }   
                                        </span>)
                                            


                                        }) 
                                    }</>
                                    }
                                </> : <>
                                {
                                    averages && averages.slice(tabberValues[0],tabberValues[1]).map((item, i) => {
                                        var cNum = Number(item[0][1]);
                                        return ( <span className={`${classes.singleReportMountain} single_report_mountain flex-1} `}
                                        style={{ height: `${item[1] > importances[cNum - 1][1] ? item[1]*41 : importances[cNum - 1][1]*41}px` }} key={i}>
                                        <div className=''>
                                            <div className={`flex absolute bg-bigstone px-0.5 ${classes.chipWrapper}`}
                                            style={{
                                                bottom:`${item[1] > importances[cNum - 1][1] ? item[1]*41 : importances[cNum - 1][1]*41}px`
                                            }}>
                                                <Chip 
                                                label={item[1]} 
                                                className={`${classes.Chip} mountain_label`}
                                            
                                                />
                                                <Chip 
                                                label={importances[cNum - 1][1]} 
                                                className={`${classes.Chip} shadow_label`} 
                                                />
                                            </div>
                                        </div>
                                        <SvgIcon 
                                        clName={` report_mountain `}
                                        style={{
                                            transform: `
                                            scale(1.18, ${(item[1]/1.255)}) translateX(-50%)`,
                                            // scale(0.85, ${(item[1]/1.59)}) translate(-50%, 43px)`,
                                        }}
                                        icon={`mountain-${cNum}`} 
                                        fill='auto'
                                        />
                                            
                                        <SvgIcon 
                                        clName='report_shadow z-0'
                                        style={{
                                            transform: `
                                            scale(2.4,${(importances[cNum - 1][1]/1.255)}) translateX(-50%)`,
                                            // scale(0.85,${(importances[cNum - 1][1]/3.33)}) translate(-50%, 8px)`,
                                        }}
                                        icon={`shadow-${cNum}`} 
                                        fill='auto'
                                        />
                                        
                                        {
                                        reportLavel  ? 
                                        <span className={`${classes.mountainText}  absolute mountain_text uppercase gesta-medium `}>
                                            {mountainsData[cNum - 1]}
                                        </span> : ''
                                        }   
                                    </span>)
                                        


                                    }) 
                                }
                                </>}
                                
                                </> : <>
                                    <span 
                                        className={`${classes.singleReportMountain} single_report_mountain flex-1}`}
                                        style={{ height: `${averages*30}px` }}>
                                                        
                                        <Chip  icon={<EmojiFlagsSharpIcon/>}
                                        label={`Current Rating ${averages}`} 
                                        className={`${classes.Chip} absolute gesta font-light mountain_label`} 
                                        style={{ 
                                            // eslint-disable-next-line no-mixed-operators
                                            bottom: `${calculateChipPosition(averages,importances) === 1 && rhrWindow.height>600 || (averages === importances) ? (averages*30 )+ 40 : calculateChipPosition(averages,importances) === 1 && rhrWindow.height<600 || (averages === importances) ? (averages*30)+40 : rhrWindow.height<600 ? (averages*30)+15 : (averages*30)+15 }px`,
                                            
                                        }}
                                        />
                                        <SvgIcon 
                                        clName={`report_mountain mountain-${activeChallenge+1}`}
                                        style={{
                                            transform: `
                                            scale(${(averages/7)+1.2},${averages*1}) translateX(-50%)`,
                                        }}
                                        icon={`mountain-${activeChallenge+1}`} 
                                        fill='auto'
                                        />           
                                        <SvgIcon 
                                        clName='report_shadow '
                                        style={{ opacity: '.5', transform: `
                                            scale(${(averages/7) * 1.6 +2.96},${importances*1}) translateX(-50%)`,
                                        }}
                                        icon={`shadow-${activeChallenge+1}`} 
                                        fill='auto'
                                        />
                                        <Chip 
                                        icon={<EmojiFlagsSharpIcon/>}
                                        style={{ 
                                            bottom: `${calculateChipPosition(averages,importances) === 2 && rhrWindow.height>600 ? ((importances)*30 )+ 40 : calculateChipPosition(averages,importances) === 2 && rhrWindow.height<600 ? ((importances)*30)+50 : rhrWindow.height<600 ? ((importances)*30)+15 : ((importances)*30)+15}px`,
                                            
                                        }}
                                        label={`Importance ${importances}`} 
                                        className={`${classes.Chip} absolute  gesta font-light shadow_label`} 
                                        />
                                    </span>
                                </>
                            }
                        </div>
                        <div className={`${classes.reportMountainContainer} report_mountainer_container items-end md:flex hidden`}>
                            {
                                isReport ? <>
                                {
                                    averages && averages.map((item, i) => {
                                        var cNum = Number(item[0][1]);
                                        return (
                                            <span className={`${classes.singleReportMountain} single_report_mountain flex-1} `}
                                                style={{ height: `${item[1] > importances[cNum - 1][1] ? item[1]*41 : importances[cNum - 1][1]*41}px` }} key={i}>
                                                <div className=''>
                                                    <div className={`flex absolute bg-bigstone px-0.5 ${classes.chipWrapper}`}
                                                    style={{
                                                        bottom:`${item[1] > importances[cNum - 1][1] ? item[1]*41 : importances[cNum - 1][1]*41}px`
                                                    }}>
                                                        <Chip 
                                                        label={item[1]} 
                                                        className={`${classes.Chip} mountain_label`}
                                                    
                                                        />
                                                        <Chip 
                                                        label={importances[cNum - 1][1]} 
                                                        className={`${classes.Chip} shadow_label`} 
                                                        />
                                                    </div>
                                                </div>
                                                <SvgIcon 
                                                clName={` report_mountain `}
                                                style={{
                                                    transform: `
                                                    scale(1.45, ${(item[1]/1.24)}) translateX(-50%)`,
                                                }}
                                                icon={`mountain-${cNum}`} 
                                                fill='auto'
                                                />
                                                    
                                                <SvgIcon 
                                                clName='report_shadow z-0'
                                                style={{
                                                    transform: `
                                                    scale(3,${(importances[cNum - 1][1]/1.24)}) translateX(-50%)`,
                                                }}
                                                icon={`shadow-${cNum}`} 
                                                fill='auto'
                                                />
                                                
                                                {
                                                reportLavel  ? 
                                                <span className={`${classes.mountainText}  absolute mountain_text uppercase gesta-medium `}>
                                                    {mountainsData[cNum - 1]}
                                                </span> : ''
                                                }   
                                            </span>
                                        )
                                    }) 
                                }
                                </> : <>
                                    <span 
                                        className={`${classes.singleReportMountain} single_report_mountain flex-1  }`}
                                        style={{ height: `${averages*30}px` }}>
                                                        
                                        <Chip  icon={<EmojiFlagsSharpIcon/>}
                                        label={`Current Rating ${averages}`} 
                                        className={`${classes.Chip} absolute gesta font-light mountain_label   }`} 
                                        style={{ 
                                            // eslint-disable-next-line no-mixed-operators
                                            bottom: `${calculateChipPosition(averages,importances) === 1 && rhrWindow.height>600 || (averages === importances) ? (averages*30 )+ 40 : calculateChipPosition(averages,importances) === 1 && rhrWindow.height<600 || (averages === importances) ? (averages*30)+40 : rhrWindow.height<600 ? (averages*30)+15 : (averages*30)+15 }px`,
                                            
                                        }}
                                        />
                                        <SvgIcon 
                                        clName={`report_mountain mountain-${activeChallenge+1}`}
                                        style={{
                                            transform: `
                                            scale(${(averages/7)+1.2},${averages*1}) translateX(-50%)`,
                                        }}
                                        icon={`mountain-${activeChallenge+1}`} 
                                        fill='auto'
                                        />           
                                        <SvgIcon 
                                        clName='report_shadow '
                                        style={{ opacity: '.5', transform: `
                                            scale(${(averages/7) * 1.6 +2.96},${importances*1}) translateX(-50%)`,
                                        }}
                                        icon={`shadow-${activeChallenge+1}`} 
                                        fill='auto'
                                        />
                                        <Chip 
                                        icon={<EmojiFlagsSharpIcon/>}
                                        style={{ 
                                            bottom: `${calculateChipPosition(averages,importances) === 2 && rhrWindow.height>600 ? ((importances)*30 )+ 40 : calculateChipPosition(averages,importances) === 2 && rhrWindow.height<600 ? ((importances)*30)+50 : rhrWindow.height<600 ? ((importances)*30)+15 : ((importances)*30)+15}px`,
                                            
                                        }}
                                        label={`Importance ${importances}`} 
                                        className={`${classes.Chip} absolute  gesta font-light shadow_label`} 
                                        />
                                    </span>
                                </>
                            }
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}