import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { useForm } from '../../hooks/form';
import { useMutation } from '@apollo/client';
import { AuthContext } from "../../context/auth";
import { ResultsContext } from "../../context/result";
import { SessionContext } from "../../context/session";

// User Defined
import ChallengeQuestionDialogFooter from "./ChallengeQuestionDialogFooter";
import CurrentlySpendTime1 from "./CurrentlySpendTime1";
import OpenQuestion from "./OpenQuestion";
import ChallengeQuestionDarkBar from './ChallengeQuestionDarkBar';
import ChallengeQuestionBarCompare from './ChallengeQuestionBarCompare';
import {challengeStyles} from '../../styles/ChallengeQuestionModalStyle';
import FinalModal from "./FinalModal";
import TakewaysModal from "./TakewaysModal";
import PrioritySelection from './PrioritySelection';
import SnackBarAlert from '../../components/SnackBarAlert';
import ChallengeQuestionLastChance from './ChallengeQuestionLastChance';
import ChallengeQuestionWellDone from './ChallengeQuestionWellDone';

//QUERIES
import { ADD_CLIENT_NOTES, ADD_CLIENT_DOES, ADD_CLIENT_SHOULD, ADD_CLIENT_PRIORITY,  
        ADD_CLIENT_PRIORITY_NOTES, SEND_REPORT } from '../../util/graphql';

export default function ChallengeQuestionModal(props) {
    const classes = challengeStyles();
    const { user } = useContext(AuthContext);
    const resultsContext = useContext(ResultsContext);
    const sessionContext = useContext(SessionContext);
    const userSession = sessionContext.session;
    const [errors,setErrors] = useState();
    const [severity, setSeverity] = useState('error');
    const [alert, setAlert] = useState(false); 
    const [msg, setMsg] = useState('');
    //Snackbar Alert State
    const [openSnackBar,setOpenSnackBar] = useState(true);
    const [activeModalNumber,setActiveModal] = useState(1);
    const sessionId = (userSession.id || userSession._id);
    const [goNext, setGoNext] = useState(false);
    const [does, setDoes] = useState([0,0,0,0,0,0,0]);
    const [should, setShould] = useState([0,0,0,0,0,0,0]);
    const [priorityChecked, setIsChecked] = useState([false, false, false, false, false, false, false]);
    const history = useHistory();

    console.warn(errors, openSnackBar)

    useEffect(() => {
      let tmp = [0,0,0,0,0,0,0];
      let tmp2 = [0,0,0,0,0,0,0];
      let tmp3 = [false, false, false, false, false, false, false];
      for (let i=0; i < 7; i++) {
        tmp[i] = resultsContext.results['c'+(i+1)].does;
        tmp2[i] = resultsContext.results['c'+(i+1)].should;
        tmp3[i] = (userSession.priority1Id === (i+1) || userSession.priority2Id === (i+1) || userSession.priority3Id === (i+1))
      }
      setDoes(tmp);
      setShould(tmp2);
      setIsChecked(tmp3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(()=>{
      if(props.startAfterChallenges){
        setActiveModal(2);
      }else if(!props.startAfterChallenges){
        setActiveModal(6);
      }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    //Snackbar alert Close Handeler
    const handleCloseSnackbar = (event, reason)=> {
      if (reason === "clickaway") {
          return;
      }
      setOpenSnackBar(false);
    }
  
    const { onChange, values } = useForm(handleNext, {
      id: sessionId,
      notes: userSession.clientNotes,
      alternativeEmail: user.email,
      priority1Note: userSession.priority1Note,
      priority2Note: userSession.priority2Note,
      priority3Note: userSession.priority3Note
    });

    // update Results for this session
    // Modal 1 - OpenQuestion.js
    const [addClientNotes] = useMutation(ADD_CLIENT_NOTES, {
      variables: {id: values.id, notes: values.notes},
      onError(err) {
        console.log('err',err);
        setErrors(err.graphQLErrors[0].extensions.exception.errors);
      }
    });

    const [updateClientDoes] = useMutation(ADD_CLIENT_DOES, {
      variables: {sessionId, does},
      onCompleted: (data) => {
        if (data.updateClientDoes) {
          let consTEMP = {
            ...resultsContext.results, 
            'c1': {...resultsContext.results.c1, 'does': does[0]},
            'c2': {...resultsContext.results.c2, 'does': does[1]},
            'c3': {...resultsContext.results.c3, 'does': does[2]},
            'c4': {...resultsContext.results.c4, 'does': does[3]},
            'c5': {...resultsContext.results.c5, 'does': does[4]},
            'c6': {...resultsContext.results.c6, 'does': does[5]},
            'c7': {...resultsContext.results.c7, 'does': does[6]}
          }
          resultsContext.change(consTEMP);
        }
      },
      onError(err) {
        console.log('err',err);
        setErrors(err.graphQLErrors[0].extensions.exception.errors);
      }
    });

    const [updateClientShould] = useMutation(ADD_CLIENT_SHOULD, {
      variables: {sessionId, should},
      onCompleted: (data) => {
        if (data.updateClientShould) {
          let consTEMP = {
            ...resultsContext.results, 
            'c1': {...resultsContext.results.c1, 'should': should[0]},
            'c2': {...resultsContext.results.c2, 'should': should[1]},
            'c3': {...resultsContext.results.c3, 'should': should[2]},
            'c4': {...resultsContext.results.c4, 'should': should[3]},
            'c5': {...resultsContext.results.c5, 'should': should[4]},
            'c6': {...resultsContext.results.c6, 'should': should[5]},
            'c7': {...resultsContext.results.c7, 'should': should[6]}
          }
          resultsContext.change(consTEMP);
        }
      },
      onError(err) {
        console.log('err',err);
        setErrors(err.graphQLErrors[0].extensions.exception.errors);
      }
    });

    const [updatePriority] = useMutation(ADD_CLIENT_PRIORITY, {
      variables: {sessionId, isChecked: priorityChecked},
      onError(err) {
        console.log('err',err);
        setErrors(err.graphQLErrors[0].extensions.exception.errors);
      }
    });

    const [updatePriorityNotes] = useMutation(ADD_CLIENT_PRIORITY_NOTES, {
      variables: {sessionId, note1: values.priority1Note, note2: values.priority2Note, note3: values.priority3Note },
      onError(err) {
        console.log('err',err);
        setErrors(err.graphQLErrors[0].extensions.exception.errors);
      }
    });

    const [sendPDFReport] = useMutation(SEND_REPORT);

  const sendAlternate = () => {
    sendPDFReport({variables: {id: user.id, email: values.alternativeEmail}});
    setAlert(true);   
    setSeverity('success');
    setMsg("Report sent to "+values.alternativeEmail);
    setTimeout(() => {setAlert(false)}, 3000);   
  }

  function handlePrev(){
  
    if(props.startAfterChallenges && activeModalNumber === 9){
        history.push("/challenges");
        props.handleChallengeCancel(false);

      }else if(activeModalNumber === 6){
        props.handleChallengeCancel(false);
      }else if(activeModalNumber === 1){
        props.handleChallengeCancel(false);
      }else if(activeModalNumber === 4){
        setActiveModal(2);
      }else if(props.startAfterChallenges && activeModalNumber === 2){
          history.push("/challenges");
          props.handleChallengeCancel(false);
      }else if(activeModalNumber>1){
        setActiveModal(activeModalNumber-1);
      }
      props.getActiveModalNumber(activeModalNumber);
      window.scroll(0, 0);
  }


  function handleNext(){
      if(activeModalNumber<11){
        switch (activeModalNumber) {
          case 1:
            break;
          case 2:
            // opened from challenge 6 type 2
            if (!goNext) {
              setAlert(true);  
              setSeverity('error'); 
              setMsg("Please make sure you complete 100%!");
              setTimeout(() => {setAlert(false)}, 3000);
              break;
            }
            updateClientDoes(); 
            break;
          case 3: 
            break;
          case 4:
            if (!goNext) {
              setAlert(true); 
              setSeverity('error');  
              setMsg("Please make sure you complete 100%!");
              setTimeout(() => {setAlert(false)}, 3000);
              break;
            }
            updateClientShould(); 
            break;
          case 5:   
            break;
          case 6:
            // step 2
            if (!goNext) {
              setAlert(true);   
              setSeverity('error');
              setMsg("Please select 3 priorities!");
              setTimeout(() => {setAlert(false)}, 3000);
              break;
            }
            let p1 = false;
            let p1id = 0;
            let p2 = false;
            let p2id = 0;
            let p3 = false;
            let p3id = 0;
            for (let i=0; i< 7; i++) {
              if (priorityChecked[i] && !p1) {
                p1 = true;
                p1id = i+1;
                continue;
              } 
              if (priorityChecked[i] && !p2) {
                p2 = true;
                p2id = i+1;
                continue;
              } 
              if (priorityChecked[i] && !p3) {
                p3 = true;
                p3id = i+1;
                continue;
              } 
            }
            let tmp3 = {
              ...sessionContext.session, 
              'priority1Id': p1id,
              'priority2Id': p2id,
              'priority3Id': p3id,
            }
            sessionContext.progress(tmp3);
            updatePriority();
          break;
          case 7:
            let tmp4 = sessionContext.session;
            tmp4.priority1Note = values.priority1Note;
            tmp4.priority2Note = values.priority2Note;
            tmp4.priority3Note = values.priority3Note;
            sessionContext.progress(tmp4);
            updatePriorityNotes();
            sendPDFReport({variables: {id: user.id, toSend: false}});
            setTimeout(() => {sendPDFReport({variables: {id: user.id}})}, 3000);
            setAlert(true);  
            setSeverity('success'); 
            setMsg("Report sent successfully!");
            setTimeout(() => {setAlert(false)}, 3000);
            break;
        case 8:
            break;
        case 9:
            let sess = {...userSession, 'clientNotes': values.notes, 'status': 'COMPLETED'};
            sessionContext.progress(sess);
            addClientNotes();
            sendPDFReport({variables: {id: user.id, email: user.email, toSend: true}});
            break;
            
        case 10:
            
            if(props.startAfterChallenges){
                props.handleChallengeCancel();
                history.push("/reports");
            }
            break;
          default:
            break;         
        }    
        
        if (goNext) {
          
          if(!props.startAfterChallenges && activeModalNumber === 1){
            setActiveModal(6)
          }else if(props.startAfterChallenges && activeModalNumber === 2){
            setActiveModal(4)
          }else if(props.startAfterChallenges && activeModalNumber === 5){
              setActiveModal(9)
          }else{
            setActiveModal(activeModalNumber+1);
          }
          
        }    
      }
      props.getActiveModalNumber(activeModalNumber);
      window.scroll(0, 0);
    }

    var currentModal= "";  
   
    switch (activeModalNumber) {
        case 1:
            currentModal = <OpenQuestion user={props.user} vals={values.notes} next={setGoNext} chg={onChange} />;
            break;
        case 2:
            currentModal = <CurrentlySpendTime1 user={props.user} vals={does} next={setGoNext} chg={setDoes} isSpendTimeTwo={false}  />;
            break;
        case 3:
            currentModal = <ChallengeQuestionDarkBar user={props.user} next={setGoNext} results={resultsContext.results} />;
            break;
        case 4:
            currentModal = <CurrentlySpendTime1 user={props.user} vals={should} next={setGoNext} chg={setShould} isSpendTimeTwo={true} />;
            break;
        case 5:
            currentModal = <ChallengeQuestionBarCompare user={props.user} next={setGoNext} results={resultsContext.results} />;
            break;
        case 6:
            currentModal = <PrioritySelection user={props.user} chk={priorityChecked} next={setGoNext} setChk={setIsChecked} />;
            break;
        case 7:
            currentModal = <TakewaysModal user={props.user} chg={onChange}  next={setGoNext}
            val1={values.priority1Note} val2={values.priority2Note} val3={values.priority3Note}
            p1={userSession.priority1Id} p2={userSession.priority2Id} p3={userSession.priority3Id} />;
            break;
        case 8:
            currentModal = <FinalModal chg={onChange} vals={values.alternativeEmail} sender={sendAlternate} />;
            break;
        case 9:
            currentModal = <ChallengeQuestionLastChance user={props.user} chg={onChange} vals={values.notes} next={setGoNext} results={resultsContext.results} />;
            break;
        case 10:
            currentModal = <ChallengeQuestionWellDone user={props.user} next={setGoNext} results={resultsContext.results} chg={onChange} vals={values.alternativeEmail} sender={sendAlternate} />;
            break;
        default:
            currentModal = <OpenQuestion user={props.user} />;
            break;
      }

      
  return (
    <>
    {
    alert ? <SnackBarAlert severity={severity} alertMessage={msg} handleCloseSnackbar={handleCloseSnackbar} openSnackBar={alert} vertical='top' horizontal='center' autoHideDuration={6000} />: <></> 
    }
    <div className={classes.challengeDialog}>
      <div className="challenge_dialog_inner md:bg-transparent min-h-screen bg-concrete pb-14 md:pb-0 md:block inline-block w-full">

            { currentModal}
        
            <ChallengeQuestionDialogFooter hasPrevNext={activeModalNumber !== 8 ? true: false} handlePrev={handlePrev} handleNext={handleNext} hasCancelSubmitBtn={activeModalNumber === 9 ? true : false} activeModalNumber={activeModalNumber}/>
      </div>
    </div>
    </>
  );
}
