import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
// Data Sheet
import {challenges} from '../../data/ChallengeData';
// User Defined
import ReportCard from './ReportCard';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import json2mq from 'json2mq';  

function KeyInsights({ averages, keyGaps,tabberValues}) {
    const mobileDevice = useMediaQuery(
        json2mq({
          maxWidth: 767,
        }),
    );

    return (
        <Box className="pt-12 pb-10 pb-2 px-9 md:px-12 w-full">
            <h5 className="gesta-medium text-xl text-mineshaft mb-2">
                Key Insights
            </h5>
            <Grid container spacing={7}>
                { mobileDevice && tabberValues[2] === "Highest" ? 
                    <Grid item sm={12} md={6}>
                        <Grid item xs={12}>
                            <h2 className="gesta-bold md:text-3xl text-mineshaft" style={{fontSize:'27px'}}>
                                Highest Self Ratings    
                            </h2>
                        </Grid>
                        <Grid item xs={12} className="mt-9">
                            {
                                averages && averages[0] ? 
                                <ReportCard 
                                    title={challenges[averages[0][0].charAt(1)-1].challenge} 
                                    text={challenges[averages[0][0].charAt(1)-1].high}
                                    imgIcon={challenges[averages[0][0].charAt(1)-1].imgIcon}
                                    color={challenges[averages[0][0].charAt(1)-1].color}
                                />: ''
                            }
                        </Grid>
                        <Grid item xs={12} className="mt-9">
                            {
                                averages && averages[1] ? 
                                <ReportCard 
                                    title={challenges[averages[1][0].charAt(1)-1].challenge} 
                                    text={challenges[averages[1][0].charAt(1)-1].high}
                                    imgIcon={challenges[averages[1][0].charAt(1)-1].imgIcon}
                                    color={challenges[averages[1][0].charAt(1)-1].color}
                                />: ''
                            }
                        </Grid>
                    </Grid>
                    :
                    !mobileDevice ?              
                    <Grid item sm={12} md={6}>
                        <Grid item xs={12}>
                            <h2 className="gesta-bold md:text-3xl text-mineshaft" style={{fontSize:'27px'}}>
                                Highest Self Ratings    
                            </h2>
                        </Grid>
                        <Grid item xs={12} className="mt-9">
                            {
                                averages && averages[0] ? 
                                <ReportCard 
                                    title={challenges[averages[0][0].charAt(1)-1].challenge} 
                                    text={challenges[averages[0][0].charAt(1)-1].high}
                                    imgIcon={challenges[averages[0][0].charAt(1)-1].imgIcon}
                                    color={challenges[averages[0][0].charAt(1)-1].color}
                                />: ''
                            }
                        </Grid>
                        <Grid item xs={12} className="mt-9">
                            {
                                averages && averages[1] ? 
                                <ReportCard 
                                    title={challenges[averages[1][0].charAt(1)-1].challenge} 
                                    text={challenges[averages[1][0].charAt(1)-1].high}
                                    imgIcon={challenges[averages[1][0].charAt(1)-1].imgIcon}
                                    color={challenges[averages[1][0].charAt(1)-1].color}
                                />: ''
                            }
                        </Grid>
                    </Grid>
                    :<></>
                }
                { mobileDevice && tabberValues[2] === "Lowest" ? 
                    <Grid item sm={12} md={6}>
                        <Grid item xs={12}>
                            <h2 className="gesta-bold md:text-3xl text-mineshaft" style={{fontSize:'27px'}}>
                                Lowest Self Ratings
                            </h2>
                        </Grid>
                        <Grid item xs={12} className="mt-9">
                            {
                                averages && averages[6] ? 
                                <ReportCard 
                                    title={challenges[averages[5][0].charAt(1)-1].challenge} 
                                    text={challenges[averages[5][0].charAt(1)-1].low}
                                    imgIcon={challenges[averages[5][0].charAt(1)-1].imgIcon}
                                    color={challenges[averages[5][0].charAt(1)-1].color}
                                />: ''
                            }
                        </Grid>
                        <Grid item xs={12} className="mt-9">
                            {
                                averages && averages[5] ? 
                                <ReportCard 
                                    title={challenges[averages[6][0].charAt(1)-1].challenge} 
                                    text={challenges[averages[6][0].charAt(1)-1].low}
                                    imgIcon={challenges[averages[6][0].charAt(1)-1].imgIcon}
                                    color={challenges[averages[6][0].charAt(1)-1].color}
                                />: ''
                            }
                        </Grid>
                    </Grid>
                    :
                    !mobileDevice ? 
                    <Grid item sm={12} md={6}>
                        <Grid item xs={12}>
                            <h2 className="gesta-bold md:text-3xl text-mineshaft" style={{fontSize:'27px'}}>
                                Lowest Self Ratings
                            </h2>
                        </Grid>
                        <Grid item xs={12} className="mt-9">
                            {
                                averages && averages[5] ? 
                                <ReportCard 
                                    title={challenges[averages[5][0].charAt(1)-1].challenge} 
                                    text={challenges[averages[5][0].charAt(1)-1].low}
                                    imgIcon={challenges[averages[5][0].charAt(1)-1].imgIcon}
                                    color={challenges[averages[5][0].charAt(1)-1].color}
                                />: ''
                            }
                        </Grid>
                        <Grid item xs={12} className="mt-9">
                            {
                                averages && averages[6] ? 
                                <ReportCard 
                                    title={challenges[averages[6][0].charAt(1)-1].challenge} 
                                    text={challenges[averages[6][0].charAt(1)-1].low}
                                    imgIcon={challenges[averages[6][0].charAt(1)-1].imgIcon}
                                    color={challenges[averages[6][0].charAt(1)-1].color}
                                />: ''
                            }
                        </Grid>
                    </Grid>
                    :<></>
                }
            </Grid>
            { mobileDevice && tabberValues[2] === "Greatest Opportunities" ? 
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <h2 className="gesta-bold md:text-3xl text-mineshaft mt-8 pt-1.5 leading-snug" style={{fontSize:'27px'}}>
                            Greatest Opportunities to Drive Scale
                        </h2>
                    </Grid>
                    <Grid item sm={12} md={4}> 
                        {
                            keyGaps && keyGaps[0] ? 
                            <ReportCard 
                                title={challenges[keyGaps[0][0].charAt(1)-1].challenge} 
                                text={challenges[keyGaps[0][0].charAt(1)-1].gap}
                                imgIcon={challenges[keyGaps[0][0].charAt(1)-1].imgIcon}
                                color={challenges[keyGaps[0][0].charAt(1)-1].color}
                            /> : <ReportCard
                                title=""
                                text="Your responses suggest that you feel confident about your ability to successfully navigate all seven leadership challenges based on your current skills and capabilities. 360 feedback from your colleagues and discussions with your coach may reveal near-term growth opportunities that aren't currently on your radar screen. In the meantime, here are some development insights based on the two challenges where you gave yourself your lowest ratings."
                                icon=""
                                color=""
                            />
                        }
                    </Grid>
                    <Grid item sm={12} md={4}>
                        {
                            (keyGaps && keyGaps[0] && keyGaps[1]) ? 
                            <ReportCard 
                                title={challenges[keyGaps[1][0].charAt(1)-1].challenge} 
                                text={challenges[keyGaps[1][0].charAt(1)-1].gap}
                                imgIcon={challenges[keyGaps[1][0].charAt(1)-1].imgIcon}
                                color={challenges[keyGaps[1][0].charAt(1)-1].color}
                            /> : keyGaps[0] ? '' :
                            <ReportCard 
                                title={challenges[averages[5][0].charAt(1)-1].challenge} 
                                text={challenges[averages[5][0].charAt(1)-1].gap}
                                imgIcon={challenges[averages[5][0].charAt(1)-1].imgIcon}
                                color={challenges[averages[5][0].charAt(1)-1].color}
                            />
                        }
                    </Grid>
                    <Grid item sm={12} md={4}>
                        {
                            keyGaps && keyGaps[0] && keyGaps[2] ? 
                            <ReportCard 
                                title={challenges[keyGaps[2][0].charAt(1)-1].challenge} 
                                text={challenges[keyGaps[2][0].charAt(1)-1].gap}
                                imgIcon={challenges[keyGaps[2][0].charAt(1)-1].imgIcon}
                                color={challenges[keyGaps[2][0].charAt(1)-1].color}
                            /> : keyGaps[0] ? '' :
                            <ReportCard 
                                title={challenges[averages[6][0].charAt(1)-1].challenge} 
                                text={challenges[averages[6][0].charAt(1)-1].gap}
                                imgIcon={challenges[averages[6][0].charAt(1)-1].imgIcon}
                                color={challenges[averages[6][0].charAt(1)-1].color}
                            />
                        }
                    </Grid>
                </Grid>
                : 
                !mobileDevice ? 
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <h2 className="gesta-bold md:text-3xl text-mineshaft mt-8 pt-1.5 leading-snug" style={{fontSize:'27px'}}>
                            Greatest Opportunities to Drive Scale
                        </h2>
                    </Grid>
                    <Grid item sm={12} md={4}>
                        {
                            keyGaps && keyGaps[0] ? 
                            <ReportCard 
                                title={challenges[keyGaps[0][0].charAt(1)-1].challenge} 
                                text={challenges[keyGaps[0][0].charAt(1)-1].gap}
                                imgIcon={challenges[keyGaps[0][0].charAt(1)-1].imgIcon}
                                color={challenges[keyGaps[0][0].charAt(1)-1].color}
                            /> : <ReportCard
                                title=""
                                text="Your responses suggest that you feel confident about your ability to successfully navigate all seven leadership challenges based on your current skills and capabilities. 360 feedback from your colleagues and discussions with your coach may reveal near-term growth opportunities that aren't currently on your radar screen. In the meantime, here are some development insights based on the two challenges where you gave yourself your lowest ratings."
                                icon=""
                                color=""
                            />
                        }
                    </Grid>
                    <Grid item sm={12} md={4}> 
                        {
                            (keyGaps && keyGaps[0] && keyGaps[1]) ? 
                            <ReportCard 
                                title={challenges[keyGaps[1][0].charAt(1)-1].challenge} 
                                text={challenges[keyGaps[1][0].charAt(1)-1].gap}
                                imgIcon={challenges[keyGaps[1][0].charAt(1)-1].imgIcon}
                                color={challenges[keyGaps[1][0].charAt(1)-1].color}
                            /> : keyGaps[0] ? '' :
                            <ReportCard 
                                title={challenges[averages[5][0].charAt(1)-1].challenge} 
                                text={challenges[averages[5][0].charAt(1)-1].gap}
                                imgIcon={challenges[averages[5][0].charAt(1)-1].imgIcon}
                                color={challenges[averages[5][0].charAt(1)-1].color}
                            />
                        }
                    </Grid>
                    <Grid item sm={12} md={4}>
                        {
                            keyGaps && keyGaps[0] && keyGaps[2] ? 
                            <ReportCard 
                                title={challenges[keyGaps[2][0].charAt(1)-1].challenge} 
                                text={challenges[keyGaps[2][0].charAt(1)-1].gap}
                                imgIcon={challenges[keyGaps[2][0].charAt(1)-1].imgIcon}
                                color={challenges[keyGaps[2][0].charAt(1)-1].color}
                            /> : keyGaps[0] ? '' :
                            <ReportCard 
                                title={challenges[averages[6][0].charAt(1)-1].challenge} 
                                text={challenges[averages[6][0].charAt(1)-1].gap}
                                imgIcon={challenges[averages[6][0].charAt(1)-1].imgIcon}
                                color={challenges[averages[6][0].charAt(1)-1].color}
                            />
                        }
                    </Grid>
                </Grid>
                :<></>
            }
        </Box>
    );
}

export default KeyInsights;