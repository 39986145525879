import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';



export default function CoachSignUpStep2(props) {

 

    return (
        <>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h4 className='font-light text-gray-500 text-lg opacity-100 pt-5 pb-10'>Contact Information</h4>
                    </Grid>
                    {/* Client First Name */}
                    <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth={true} >
                            <TextField 
                            id="email" 
                            name="email" 
                            label="Email" 
                            onChange={props.chg} 
                            value={props.vals.email || ''} 
                            variant="outlined" 
                            fullWidth={true} />
                        </FormControl>
                    </Grid>
                    {/* coach Last Name */}
                    <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth={true} >
                            <TextField 
                            id="confirmEmail" 
                            name="confirmEmail" 
                            label="Confirm Email" 
                            onChange={props.chg} 
                            value={props.vals.confirmEmail || ''} 
                            variant="outlined" fullWidth={true} />
                        </FormControl>
                    </Grid>
                
                    {/* Select Company */}
                    <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth={true} >
                            <TextField 
                            id="phone" 
                            name="phone" 
                            label="Contact Number" 
                            onChange={props.chg} 
                            value={props.vals.phone || ''} 
                            variant="outlined" fullWidth={true} />
                        </FormControl>
                        
                    </Grid>
                   
                    {/* coach Last Name */}
                    <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth={true} >
                            <TextField 
                            id="password" 
                            type="password"
                            name="password" 
                            label="Password" 
                            onChange={props.chg} 
                            value={props.vals.password || ''} 
                            variant="outlined" fullWidth={true} />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}