import React from 'react';
import { Helmet } from "react-helmet";


function Layout(props) {

    return (
        <>
            <Helmet>
                <title>Scalelt </title>
                <link rel="icon" href="/favicon.png" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                ></meta>
                <link
                    href="https://fonts.googleapis.com/css?family=Playfair+Display:700|Poppins:200,300,400&display=swap"
                    rel="stylesheet"
                ></link>

            </Helmet>
            <div className="">
                {props.children}
            </div>
        </>
    )
}

export default Layout;