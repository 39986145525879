import { useEffect} from "react";
// Material Ui
import ChallengeQuestionDialogHead from "./ChallengeQuestionDialogHead";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

// User Defined
import { challengeStyles,ChallengeCheckbox } from "../../styles/ChallengeQuestionModalStyle";
import { challenges } from "../../data/ChallengeData";

export default function PrioritySelection({ chk, setChk, next }) {
  const classes = challengeStyles();

  useEffect(() => {
    let numTrues = 0;
    for (let i=0; i< 7; i++) {
      if (chk[i]) numTrues++;
    }
   
    next(numTrues === 3)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (event) => {
    let index = Number(event.target.name.slice(-1));
    let tmp = [false, false, false, false, false, false, false]
    let numTrues = 0;
    for (let i=0; i< 7; i++) {
      if (i === index) tmp[i] = event.target.checked;
      else tmp[i] = chk[i];
      if (tmp[i]) numTrues++;
    }
    
    setChk(tmp);
    next(numTrues === 3)
  };

  

  return (
    <div
      className={`${classes.challengeDialogContent} challenge_dialog_inner_content spendtime prioritySection scaleit__fadeIn`}
    >
      <ChallengeQuestionDialogHead
        headingTitle="Your Priorities"
        headingContent="Now that you've had a chance to review your individual challenge scores and step back to view the full picture, which development priorities seem the most critical to you? The top three priorities you identify will inform your initial discussions with your coach and help to pinpoint the focus of your coaching sprints."
        hasHeadingContent={true}
      />
      <div className="challenge_question_dialog_body text-center mt-12">
        <div
          className={`single_question_parent text-center ${classes.singleQuestionParent}`}
        >
          {challenges &&
            challenges.map((item, i) => {
              return (
                <div className={`single_question flex items-center justify-between relative ${classes.prioritySectionSingle}`} key={i}>
                    <FormControlLabel
                        control={<ChallengeCheckbox 
                        checked={chk[i]} 
                        icon={<CheckBoxOutlineBlankIcon />} 
                        onChange={handleChange} 
                        name={`c${i}`} />}
                        label={item.challenge}
                    />
                  <span
                    className={`question_border   bg-${item.color} absolute`}
                  ></span>       
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
