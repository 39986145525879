import { useState, useEffect } from 'react';

// Material Ui
import ChallengeQuestionDialogHead from "./ChallengeQuestionDialogHead";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import Typography from "@material-ui/core/Typography";

// User Defined
import { challengeStyles } from "../../styles/ChallengeQuestionModalStyle";
import { challenges } from "../../data/ChallengeData";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

export default function CurrentlySpendTime1({ vals, chg, next, isSpendTimeTwo }) {
  const classes = challengeStyles();
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let tmpTotal = 0;
    for (let i=0; i < 7; i++) {
      let challengeValue = vals[i];
      tmpTotal += challengeValue;
    }
    
    setTotal(tmpTotal);
    next(tmpTotal === 100);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vals])

  const handleChange = (e) => {
    var key = e.target.name;
    let index = (key.slice(-1) - 1);
    var value = e.target.value;
    if (value !== isNaN) value = Number(value);
    else value = 0;

    let tmpTotal = 0;
    for (let i=0; i < 7; i++) {
      let challengeValue = vals[i];
      if (index === i) tmpTotal += value;
      else tmpTotal += challengeValue;
    }
    
    setTotal(tmpTotal);

    next(tmpTotal === 100);

    chg(prev => [
      ...prev.slice(0,index),
      value,
      ...prev.slice(index+1)
    ]);
  }

  return (
    <div
      className={`${classes.challengeDialogContent} challenge_dialog_inner_content timeAllocation spendtime mx-auto my-0 scaleit__fadeIn md:p-0 pb-0 px-9 pt-6`}
    >
      {
        isSpendTimeTwo ? <>
        <h4 className='text-emperor subHeading'>Time Allocation: Part 2</h4>
        <ChallengeQuestionDialogHead
        headingTitle="How SHOULD you be allocating your time?"
        headingContent="Please estimate the percentage of time you'd like to allocate to each of these challenges. The total should add up to 100%."
        hasHeadingContent={true} />
        </> : <>
        <h4 className='text-emperor subHeading'>Time Allocation: Part 1</h4>
        <ChallengeQuestionDialogHead
          headingTitle="How are you CURRENTLY allocating your time?"
          headingContent="Please estimate the percentage of time dedicated to each challenge. The total should add up to 100%."
          hasHeadingContent={true} />
        </> 
      }
      
      <div className="challenge_question_dialog_body text-center mt-6 md:mt-12 mx-auto md:pb-14" style={{maxWidth: '900px'}}>
        <div
          className={`single_question_parent text-center ${classes.singleQuestionParent}`}
        >
          {challenges &&
            challenges.map((item, i) => {
              return (
                <div className="single_question flex items-center justify-between relative md:pl-8 md:pr-6 py-2.5 md:py-3.5 pl-6 pr-2 text-left" key={i}>
                  <span
                    className={`question_border   bg-${item.color} absolute`}
                  ></span>
                  <Typography variant="h6" className={``}>
                    {item.challenge}
                  </Typography>
                  <FormControl className='question_parecent_input_parent' fullWidth>
                    <Select 
                      value={vals[i]}
                      name={`challenge-${item.id}`}
                      onChange={handleChange}
                      id={`challenge-${item.id}`}
                      // MenuProps={MenuProps}
                      className="question_parecent_input w-full text-sm md:text-base gesta-regular"
                      displayEmpty
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        }
                      }}
                      >
                      <MenuItem value={0}>0%</MenuItem>
                      <MenuItem value={5}>5%</MenuItem>
                      <MenuItem value={10}>10%</MenuItem>
                      <MenuItem value={15}>15%</MenuItem>
                      <MenuItem value={20}>20%</MenuItem>
                      <MenuItem value={25}>25%</MenuItem>
                      <MenuItem value={30}>30%</MenuItem>
                      <MenuItem value={35}>35%</MenuItem>
                      <MenuItem value={40}>40%</MenuItem>
                      <MenuItem value={45}>45%</MenuItem>
                      <MenuItem value={50}>50%</MenuItem>
                      <MenuItem value={55}>55%</MenuItem>
                      <MenuItem value={60}>60%</MenuItem>
                      <MenuItem value={65}>65%</MenuItem>
                      <MenuItem value={70}>70%</MenuItem>
                      <MenuItem value={75}>75%</MenuItem>
                      <MenuItem value={80}>80%</MenuItem>
                      <MenuItem value={85}>85%</MenuItem>
                      <MenuItem value={90}>90%</MenuItem>
                      <MenuItem value={95}>95%</MenuItem>
                      <MenuItem value={100}>100%</MenuItem>
                    </Select>
                  </FormControl>
                  {/* <span className="question_parecent_input_parent">
                    <select onChange={handleChange} value={vals[i]} name={`challenge-${item.id}`} id={`challenge-${item.id}`} className="w-full text-sm md:text-base gesta-regular">
                      
                    </select>
                  </span> */}
                </div>
              );
            })}

          <div
            className={`single_question total flex items-center justify-between relative border-t-0`}
          >
            <Typography variant="h6" className="">
              Total
            </Typography>
            <span className={`question_parecent_input_parent relative flex items-center justify-center pl-0 text-left mr-2 ${classes.Total}`}>
              <span className="total_value text-lg text-left pr-2">{total}%</span>
              {/* <span className="question_parecent_label -mr-2"></span> */}
              <span className={`question_validation absolute  `}>
                {
                  (total === 100) ?  <CheckCircleIcon style={{
                    color: '#00A086',
                  }}/> : <CancelIcon style={{
                    color: '#EF404E',
                  }}/>
                }
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
