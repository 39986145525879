//import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
//import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert variant="filled" {...props} />;
}

function SnackBarAlert(props) {
  const { severity, alertMessage, handleCloseSnackbar, openSnackBar, vertical, horizontal,autoHideDuration } = props;


  return (
    <Snackbar
      open={openSnackBar}
      anchorOrigin={{ vertical, horizontal }} severity={severity}
      autoHideDuration={autoHideDuration? autoHideDuration : 6000}
      onClose={handleCloseSnackbar}
    >
      <Alert onClose={handleCloseSnackbar} severity={severity}>
        {alertMessage}
      </Alert>
    </Snackbar>
  );
}

export default SnackBarAlert;
