import { useEffect, useState } from "react";
// Material Ui

// User Defined
import Box from '@material-ui/core/Box';
import { challengeStyles } from "../../styles/ChallengeQuestionModalStyle";
import trophy from "../../assets/imgaes/trophy.svg";

export default function ChallengeQuestionWellDone({ next, user, chg, vals, sender }) {
  const classes = challengeStyles();
  const [enableAlternateEmail, setEnableAlternateEmail] = useState(false);

  useEffect(() => {
    next(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const enableAlternateEmailHandler = () => {
    setEnableAlternateEmail(true);
  }

  return (
    <div
      className={`${classes.challengeDialogContent} challenge_dialog_inner_content spendtime bg-white`}
    >
      
      <div className="challenge_question_dialog_body text-center pb-4 mx-auto completionMessage" style={{maxWidth:'830px'}}>
            <Box >
              <Box className='flex items-center justify-center'>
                {/* <SvgIcon clName="trophyicon h-20" icon="trophy-2"  /> */}
                <img src={trophy} alt="" width="115" className="w-20 md:w-auto"/>
              </Box>
              <h4 className='text-dovegray gesta-bold text-2xl md:text-3xl mt-6 md:mt-12 text-emperor py-1 center mb-10 md:mb-12'>
                Thank you for completing the Seven Navigation Challenges!
              </h4>
              <Box className='px-6 py-4 bg-concrete text-left md:mx-0 -mx-9'>
                <Box className="flex justify-between md:flex-row flex-col text-center md:text-left py-3.5 ">
                  <p className="md:text-lg text-base">We sent your results to the following email: <strong className="break-words">{user.email}</strong></p>
                  <button className="md:mt-0 mt-5 md:text-lg text-base" onClick={enableAlternateEmailHandler}><em className="md:whitespace-nowrap">Send to a different email</em></button>
                </Box>
                {
                    enableAlternateEmail 
                    &&
                    <Box className="flex justify-between md:items-center md:flex-row flex-col md:mt-2">
                        <label className="font-medium text-black md:text-lg text-base md:mb-0 mb-3">New Email Address</label>
                        <Box className="form-group flex w-full" style={{maxWidth:'460px', flex:'1 1 100%'}}>
                            <input
                                name="alternativeEmail"
                                id="alternativeEmail"
                                type="email" 
                                className="py-3.5 px-5 leading-5 bg-white text-customgray border border-customgray2 border-solid w-full" 
                                onChange={chg}
                                // defaultValue={vals}
                                placeholder="Enter email address"
                            />
                            <button onClick={sender} className="text-alabaster bg-bigstone uppercase font-medium py-3.5 leading-5 px-8 ml-4">Send</button>
                        </Box>
                    </Box>
                }
              </Box>
              {/* <p className='text-base font-normal gesta'>
                The next few screens will take you through an exercise to define your priorities. This information will be used to frame your next coaching conversation and outline a backlog of next steps. 
              </p>
              <p className='text-base font-normal gesta'>
                When you are ready, please complete the survey by proceeding through the final few questions.
              </p> */}
            </Box>
      </div>
    </div>
  );
}
