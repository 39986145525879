import { useState } from "react";
import { challenges } from "../data/ChallengeData";

export const useResult = (initialState = {}) => {
  const [values, setValues] = useState(initialState);
  const [questionDone, setQuestionDone] = useState(initialState);

  const onChange = (challenge, name, value) => {
    const challengeIndex = Number(challenge.slice(-1)) - 1;
    let updatedChallenge = { ...values[challenge], [name]: value };
    let updatedQuestion = { ...questionDone[challenge], [name]: "Y" };
    if (name.length === 2) {
      //It is a question not importance
      var average = values[challenge].average;
      let questCount = challenges[challengeIndex].questions.count;
      let total = 0;
      for (let i = 0; i < questCount; i++) {
        total += updatedChallenge["q" + (i + 1)];
      }
      average = (total / questCount).toFixed(1);
      updatedChallenge = { ...updatedChallenge, average: Number(average) };
    }
    setValues({ ...values, [challenge]: updatedChallenge });
    setQuestionDone({ ...questionDone, [challenge]: updatedQuestion });
  };

  return {
    onChange,
    values,
    questionDone,
  };
};
