import {useEffect, useState} from "react";

// Material Ui
// import ChallengeQuestionDialogHead from "./ChallengeQuestionDialogHead";

// User Defined
import { challengeStyles } from "../../styles/ChallengeQuestionModalStyle";
import Box from "@material-ui/core/Box";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import json2mq from 'json2mq';
import { challenges } from "../../data/ChallengeData";

import itemOneIcon from "../../assets/challenges/stat-logo-color.svg";
import itemTwoIcon from "../../assets/challenges/user-icon-color.svg";
import itemThreeIcon from "../../assets/challenges/hand-icon-color.svg";
import itemFourIcon from "../../assets/challenges/brain-icon-color.svg";
import itemFiveIcon from "../../assets/challenges/bookmark-icon-color.svg"
import itemSixIcon from "../../assets/challenges/building-icon-color.svg";
import itemSevenIcon from "../../assets/challenges/culture-icon-color.svg";



export default function ChallengeQuestionBarCompare({ results, next }) {
  const classes = challengeStyles();

  const mobileDevice = useMediaQuery(
    json2mq({
      maxWidth: 767,
    }),
  );
  const barHeight = mobileDevice ? 2.8 : 4;
  useEffect(() => {
    next(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [currentIndex, setCurrentIndex] = useState();

  const setFocus = (index) => {
    setCurrentIndex(index);
  }

  return ( 
    <div
      className={`${classes.challengeDialogContent} challenge_dialog_inner_content spendtime`}
    >
      {/* <ChallengeQuestionDialogHead
        headingTitle="Time Allocation"
        headingContent="Does this graph of your responses look accurate? If not, click the “previous” button to revise your responses"
        hasHeadingContent={true}
      /> */}
      <Box component="div" className={`${classes.timeAllocationTItle} md:pl-14 md:pr-10 md:pt-0 pt-14`}>
        <h2 className="px-6 md:pr-0 md:pl-1 md:pt-0 pt-5">Time Allocation</h2>
        <p className="px-6 md:pr-0 md:pl-1 text-emperor">Does this graph of your responses look accurate? If not, click the “previous” button to revise your responses</p>
      </Box>
      <div className="challenge_question_dialog_body text-center md:mt-14 mt-12 pt-20">
        <div className={`challenge_bar_parent flex items-end relative border-b border-gray-50 dark  ${classes.challenge_bar_parent}` }>
          <span className="partition-group">
            <span className="partition1">25<span className="md:inline-block hidden">%</span></span>
            <span className="partition2">50<span className="md:inline-block hidden">%</span></span>
            <span className="partition3">75<span className="md:inline-block hidden">%</span></span>
            <span className="partition4">100<span className="md:inline-block hidden">%</span></span>
          </span>
          <div className="challenge_bar_indicator absolute right-0">
              <span className='mr-3.5 mb:mr-4'>Actual %</span>
              <span className='optional'>Ideal %</span>
          </div>
          {challenges &&
            challenges.map((item, i) => {
              var cNum = item.id
              return (
                <div
                  className={`single_challenge_bar justify-center flex items-end flex-1 relative ${currentIndex === i ? 'focus' : ""}`}
                  key={i}
                  onClick={ () => setFocus(i)}
                >
                  
                  <span className={`challenge_bar_parent_value bg-bigstone absolute ${classes.challenge_bar_parent_value}`}>
                    {/* Start Darker bar code */}
                    <span className="caret" />
                    {/* lighter bar parcent */}
                    <span className="parcent lighter text-white">
                      {results['c'+(cNum)].does}%
                    </span>
                    {/* End Lighter bar */}
                    <span className="parcent darker text-customgray2">
                      {results['c'+(cNum)].should}%
                    </span>

                  </span>
                  {/* lighter bar percent */}


                  {/* Start Lighter bar */}
                  <span
                    className={`bar lighter bg-${item.color}`}
                    style={{
                      opacity: '1',
                      display: "inline-block",
                      marginRight: '0px',
                      height: `${results['c'+(cNum)].does * barHeight}px`,
                      width: "31px",
                    }}
                  ></span>

                  {/* Start Darker bar code */}

                  <span
                    className={`bar darker bg-bigstone`}
                    style={{
                      display: "inline-block",
                      marginRight: '0',
                      height: `${results['c'+(cNum)].should * barHeight}px`,
                      width: "31px",
                    }}
                  ></span>
                  {/* End Darker bar code */}
                  
                  
                  
                </div>
              );
            })}
        </div>
        <div className={`challenge_bar_label_indicator flex items-start pt-4 md:pt-10 bg-white pb-20 md:pb-32 ${classes.challengeBarLabelIndicator}`}>
          {challenges &&
            challenges.map((item, i) => {
                let itemIcon = "", height = "", width = "";
                if(item.id === 1){
                  itemIcon = itemOneIcon;
                  height = '23';
                  width = '27';
                }
                else if(item.id === 2){
                  itemIcon = itemTwoIcon;
                  height = '17';
                  width = '27';
                }
                else if(item.id === 3){
                  itemIcon = itemThreeIcon;
                  height = '17';
                  width = '27';
                }
                else if(item.id === 4){
                  itemIcon = itemFourIcon;
                  height = '24';
                  width = '22';
                }
                else if(item.id === 5){
                  itemIcon = itemFiveIcon;
                  height = '24';
                  width = '24';
                }
                else if(item.id === 6){
                  itemIcon = itemSixIcon;
                  height = '24';
                  width = '26';
                }
                else if(item.id === 7){
                  itemIcon = itemSevenIcon;
                  height = '24';
                  width = '25';
                }
              return (
                <div className="single_indicator flex md:items-center items-start flex-col flex-1 relative" key={i} onClick={ () => setFocus(i)}>
                  <img src={itemIcon} alt="" height={height} width={width} className="block m-auto md:hidden" />
                  <span className={`text ${currentIndex === i ? 'focus' : ""}`}>{item.challenge}</span>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
