import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Waypoint } from "react-waypoint";
import { Redirect } from 'react-router-dom';
// Material  Ui
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import json2mq from "json2mq";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { BootstrapButton } from "../styles/CoachDashboardStyles";

// User Defined
import {
  useStyles,
  StyledTableCell,
  StyledTableRow,
  StyledPaper,
} from "../styles/ClientListStyles";
import { GET_CLIENTS } from "../util/graphql";

function ClientList(props) {
  const isMobile = useMediaQuery(
    json2mq({
      maxWidth: 767,
    })
  );
  const [scroll, setScroll] = useState(false)
  function scrollbars() {
    setScroll(!scroll);
  }
  useEffect(() => {
    if (isMobile) {
    }
  }, [isMobile]);

  const classes = useStyles();
  // Sorting and Searching states, create company modal open state etc
  const [sortFor, setSortFor] = useState("_id");
  const [sortDir, setSortDir] = useState(-1);
  const [clientId, setClientId] = useState("");
  const { searchFor, haveListHeader, refetchRequired, refetchHandler } = props;

  // Get Clients as infinite scroll with fetchMore
  const { data, fetchMore, refetch } = useQuery(GET_CLIENTS, {
    variables: {
      limit: 10,
      searchFor,
      sortFor,
      sortDir,
    },
  });

  useEffect(() => { 
    if(refetchRequired){
        refetch();
        refetchHandler(false);
    }
  }, [refetchRequired, refetch])

  // Show loading if needed
  if (!data || !data.clients) return <div>Loading...</div>;

  // Sets sorting column and direction
  function handleSorting(col) {
    setSortFor(col);
    setSortDir((prev) => (prev = prev === 1 ? -1 : 1));
  }

  if(clientId !== ''){
    return <Redirect
      to={{
        pathname: "/dashboard/clients/overview",
        search: "",
        state: { clientId }
      }}
    />
  }

  return (
    <StyledPaper className={`${classes.root} ${scroll ? 'faddedOff' : 'faddedOn'} ${haveListHeader ? '' : ' rounded-none '}`}>
      <>
          <TableContainer className={`${classes.container} ${scroll ? 'scrollBarOn' : 'scrollBarOff'} ${haveListHeader ? ' ' : ' rounded-none '}`}>
            <Table stickyHeader className="md:pr-12 md:pl-11 md:pt-4">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left" className="pl-6">
                    Client
                    <IconButton
                      component="span"
                      size="medium"
                      // iconStyle={styles.largeIcon}
                      onClick={() => handleSorting("firstName")}
                      className={classes.iconButton}
                    >
                      {sortFor === 'firstName' && sortDir === 1 ? (
                        <KeyboardArrowDownIcon sx={{ fontSize: "2rem" }} />
                      ) : (
                        <KeyboardArrowUpIcon  sx={{ fontSize: "2rem" }}/>
                      )}
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Role
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Company
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    Self-Audit Status
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Account Status
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Actions
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.clients.userFeed.map((client, i) => {
                    var bgColor = (client.status === "CANCELLED" || client.status === "SUSPENDED") ? "bg-brandColor text-white" : "text-black bg-btnGray";
                    bgColor += " px-3 py-1 inline-block font-medium leading-tight rounded-full text-base";
                    return (
                      <StyledTableRow key={i}>
                        <StyledTableCell align="left" className="pl-6">
                          {client.firstName} {client.lastName}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {client.role}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {client.companyId && client.companyId.name}
                        </StyledTableCell>
                        <StyledTableCell align="left">{client.currentSessionId.status.replace("_", " ").toLowerCase()}</StyledTableCell>
                        <StyledTableCell align="center">
                          <span className={bgColor}>
                            {client.status.toLowerCase()}
                          </span>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <IconButton className="p-2" onClick={() => setClientId(client.id)}>
                            <MoreHorizIcon />
                          </IconButton>
                        </StyledTableCell>

                        {data.clients.hasNextPage &&
                          i === data.clients.userFeed.length - 10 && (
                            <Waypoint
                              onEnter={() =>
                                fetchMore({
                                  variables: {
                                    first: 50,
                                    cursor:
                                      data.clients.userFeed[
                                        data.clients.userFeed.length - 1
                                      ].id,
                                  },
                                  updateQuery: (pv, { fetchMoreResult }) => {
                                    if (!fetchMoreResult) {
                                      return pv;
                                    }

                                    return {
                                      clients: {
                                        __typename: "UserFeed",
                                        userFeed: [
                                          ...pv.clients.userFeed,
                                          ...fetchMoreResult.clients.userFeed,
                                        ],
                                        hasNextPage:
                                          fetchMoreResult.clients.hasNextPage,
                                      },
                                    };
                                  },
                                })
                              }
                            />
                          )}
                      </StyledTableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
            {!scroll && isMobile && data.clients.userFeed.length !== 0 ? 
              <>
                <BootstrapButton className={`headerBtn rounded-3xl absolute mx-auto py-3 pl-5 pr-4 text-sm font-regular left-0 right-0 bottom-12`} style={{zIndex:'50', maxWidth: '204px'}} 
                  onClick={() => scrollbars()}
                  >Scroll for More Info
                  <ArrowForwardIosIcon fontSize="small" className="ml-1 -mr-1 scale_7" />
                </BootstrapButton>
              </> : <></>
            }
            {data.clients.userFeed.length !== 0 ? <></>
              : <div className={`flex flex-col items-center justify-center mx-auto whitespace-normal px-4 relative ${scroll ? ' ' : 'w-full mx-0'} `} style={{zIndex:'50', maxWidth: `${isMobile ? '332px' : '382px'}`, minHeight: `${isMobile ? 'calc(100vh - 323px)' : 'calc(100vh - 233px)'} ` }}>
                <h3 className="gesta-medium mb-3 text-xl md:text-3xl text-black">No Clients Found</h3>
                <p className="text-center text-base md:text-lg">Click the "new client +" button to add invite your first client to Founder's Journey<sup>SM</sup></p>
              </div>
            }
          </TableContainer>
        </>
    </StyledPaper>
  );
}

export default ClientList;
