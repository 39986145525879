import { forwardRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from '../hooks/form';
import Box from "@material-ui/core/Box";

import {
    Link
  } from "react-router-dom";
//Material-UI
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import LazyLoad from 'react-lazyload';

import FormControl from '@material-ui/core/FormControl';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Slide from '@material-ui/core/Slide';
//Components
import ResetConfirmation from '../components/loginaccess/ResetConfirmation';
import SnackBarAlert from '../components/SnackBarAlert';
import { useStyles } from '../styles/WelcomeStyles';
import {CssTextField} from '../styles/CommonStyles';
import logoOutline from "../assets/imgaes/rhr-outline.svg";
import logo from "../assets/imgaes/rhrLogo.svg";

//QUERIES
import { SET_PASSWORD } from '../util/graphql';
import passwordValidate from '../util/validators';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function Welcome(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(true);  
    const [alert, setAlert] = useState(false); 
    const [msg, setMsg] = useState('');
    const [confirm, setConfirm] = useState(false);
    const [accepted, setAccepted] = useState(false);
    //Snackbar Alert State
    

    const { onChange, onSubmit, values } = useForm(setPasswordCallback, {
        newPassword: ''
        
    });

    

    const [setPassword] = useMutation(SET_PASSWORD, {
        variables: {
            email: props.email,
            newPassword: values.newPassword
        },
        update(_,{ data: { setPassword: userData }}) {
            setConfirm(true);
            sessionStorage.removeItem('jwtToken');
        },
        onError(err) {
            setAlert(true);
            setMsg('Password could not be set at this time!!');
            setTimeout(() => {setAlert(false)}, 3000);
        }
    });

    //Snackbar alert Close Handeler
    const handleCloseSnackbar = (event, reason)=> {
        if (reason === "clickaway") {
            return;
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    const CheckBoxLabel = () => {
        return (
            <div className={classes.checkBoxLabel}>
                <span className='text-black md:text-lg text-base md:leading-6 leading-6'  >
                    <span>I have read and agree to the Founder’s Journey </span>
                    <Link to='/terms-of-services' className='text-bigstone gesta-medium link md:inline-block' target="_blank">
                        Terms of Service
                    </Link> 
                    &nbsp;and acknowledge the RHR International LLP&nbsp;  
                    <a className='text-bigstone gesta-medium link md:inline-block' href='https://www.rhrinternationalconsultants.eu/privacy-policy' rel="noreferrer" target="_blank">
                        Privacy Policy
                    </a>
                </span>
            </div>
        )
    }

    function setPasswordCallback() {
        let tmp = values.newPassword.trim;
        if (tmp === '' || !passwordValidate(values.newPassword)) {
            setAlert(true);
            setMsg('Please enter a valid password!');
            setTimeout(() => {setAlert(false)}, 3000);
        } else {
            if (!accepted) {
                setAlert(true);   
                setMsg('Please read and agree to the Terms & Conditions!');
                setTimeout(() => {setAlert(false)}, 3000);
            } else {
                setPassword();
            }
        }
    }

        
    return (
        <>
        {
        alert ? <SnackBarAlert severity='error' alertMessage={msg} handleCloseSnackbar={handleCloseSnackbar} openSnackBar={alert} vertical='top' horizontal='center' autoHideDuration={6000} />: <></> 
        }
        <LazyLoad height={200} className='lg:auto max-w-full fixed right-0 md:left-auto left-16 md:right-16 bottom-0 md:block hidden'>
            <img className=' lg:auto max-w-full float-right' src={`${logoOutline}`} alt="" width='535' />
        </LazyLoad>
        <div className="rhr-login-header md:mb-14 mt-0 lg:py-10 lg:pl-4 py-4">
            <Link to={'/'}>
                <img className='d-inline-flex pl-8 box-content' src={logo} alt="" width='57' />
            </Link>
        </div>
        <div className={classes.root}>
            <Grid container>
                <Box
                    TransitionComponent={Transition}
                    className={`${classes.dialog} setPassword mx-auto`}
                    onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                    <div className="dialog_inner relative py-4 md:py-9">
                        <div onClose={handleClose}>
                            {props.invited ?
                                <>
                                {!confirm ? 
                                <>
                                    <h2 className={`${classes.resetTitle} mb-8 md:mb-11 text-3xl md:text-center text-black`}> 
                                        Welcome, {props.name} 
                                    </h2>   </> : <></>}                            
                                </>: !confirm ? "" : <>
                                
                                    <h2 className={`${classes.resetTitle} mb-6 md:mb-9 mt-4 md:mt-0 text-3xl text-center text-black`}> 
                                        FAILED TO VALIDATE ACCESS!
                                    </h2>  
                                </>
                            }    
                        </div>
                        
                        {!props.invited ? 
                            <>
                                <div className='text-center'>
                                    <p className='pb-4'>{props.message}</p>
                                </div>  
                                <div className='text-center'>
                                    <Button variant="contained" href="/"  className={`${classes.resetButton} redicrect_button mt-4 mb-4 `}>
                                        Go to login
                                    </Button>  
                                </div>  
                            </> : <>
                                {!confirm ? 
                                    <>
                                        <div>
                                            <div>
                                                <p className='text-black text-base md:text-lg mb-2'>
                                                    {
                                                        props.isSet ? 
                                                        'First, please create a password that includes the following:'
                                                        :
                                                        'Please enter a new password, to reset.'
                                                    }
                                                </p>

                                                <ul className={classes.resetPassRulesList}>
                                                    <li>
                                                        At least 8 characters
                                                    </li>
                                                    <li>
                                                        Both uppercase and lowercase characters
                                                    </li>
                                                    <li>
                                                        Both letters and numbers
                                                    </li>
                                                    <li>
                                                        At least one special character, e.g., ! @ # ? ]
                                                    </li>
                                                </ul>

                                                <FormControl variant="standard" className="w-full">
                                                    <CssTextField
                                                        id="newPassword"
                                                        type="password"
                                                        name="newPassword"
                                                        label={props.isSet ? 'Create Your Password' : 'Reset Your Password'}
                                                        defaultValue={values.firstName}
                                                        onChange={onChange}
                                                        fullWidth={true}
                                                        className={classes.passwordField}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </FormControl>    
                                            </div>
                                            <div className={`${classes.checkBoxParent} pt-3 pb-3`}>
                                                {/* <h6 className={classes.resetPassRulesTitle}>Your Password must include:</h6> */}
                
                                                <p className={`${classes.resetRulesNote}`}>
                                                    <b>Note:</b> Do not use &lt; and &gt; in your password, as both can cause problems in web browsers
                                                </p>
                                            </div>
                                            <div className={`${classes.checkBoxParent} pt-4 pb-7`}>
                                                <FormGroup className={`flex md:flex-row flex-col items-start justify-start ${classes.checkBoxGroup}`} >
                                                    <FormControlLabel
                                                        control={<Checkbox className={classes.checkBox} 
                                                        checked={accepted} 
                                                        onChange={(e) => setAccepted(!accepted)} 
                                                        name="accepted" />}
                                                        label={<CheckBoxLabel />}
                                                    />
                                                    
                                                    
                                                </FormGroup>
                                            </div>
                                        
                                            <div className="mt-8 text-center">
                                                <Button 
                                                onClick={onSubmit}
                                                variant="outlined" 
                                                className={`${classes.resetButton} w-100 md:w-auto `}
                                                >
                                                {
                                                    props.isSet ? 'Create Password' : 'Confirm'
                                                }
                                                </Button>
                                            </div>
                                        </div>
                                    </> : <div className="text-center">
                                        <ResetConfirmation />
                                        <Button variant="contained"   href="/"  className={`${classes.resetButton} w-full bg-bigstone `}>
                                            Go to login
                                        </Button>
                                    </div>
                                }
                            </>
                        }
                    </div>

                </Box>
            </Grid>

        </div>
        </>
    )
}

export default Welcome;
