import { useEffect } from "react";

// Material Ui

import TextField from "@material-ui/core/TextField";

// User Defined
import ChallengeQuestionDialogHead from "./ChallengeQuestionDialogHead";
//import CTAButton from "../../components/challenges/ChallengeButton";

export default function OpenQuestion(props) {

  useEffect(() => {
    props.next(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  return (
    <div className="scaleit__fadeIn">
      <ChallengeQuestionDialogHead
        headingTitle="Is there any context you'd like to provide about your self-ratings ON The Seven Navigation Challenges?"
        headingContent=""
        hasHeadingContent={false}
      />
      <div className="px-10 md:px-10 py-10">
        <TextField
          id="filled-multiline-flexible"
          label="Your answer"
          name="notes"
          value={props.vals ?? ""}
          onChange={props.chg}
          multiline
          maxRows={4}
          variant="outlined"
          fullWidth={true}
          autoresize='true'
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    </div>
  );
}
