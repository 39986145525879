import {marks, marks2} from '../../data/ChallengeData';
import {useEffect, useRef, useState} from "react";
//Local content and components
import { useStyles, ChallengeSliderRange } from '../../styles/ChallengeCardContentStyles';
import useWindowSize from '../useWindowSize';
import json2mq from "json2mq";
import useMediaQuery from "@material-ui/core/useMediaQuery";


function ariatext(val) {
    return val;
}

export default function ChallengeCardContent(props){
    const [rangeVal, setRangeVal] = useState(false);
    const classes = useStyles();
    const rhrWindow = useWindowSize();
    const challengeType = props.challengeType;
    const activeChallenge = props.activeChallenge;
    const errorClass = props.quesStatus.toString().toLowerCase() !== "Y".toLowerCase() && props.showCardError ? classes.challengeCardError : "";
    const showCardErrorTime = props.showCardErrorTime;
    const errorQuestionNumber = props.errorQuestionNumber;
    const quesName = props.name;
    const myRef = useRef(null);

    const isMobile = useMediaQuery(
        json2mq({
          maxWidth: 1023,
        })
    );

    function valueLabelText() {
        if(challengeType === 1){
            return `${marks[props.vals - 1].label}`;
        }
        return `${marks2[props.vals - 1].label}`;  
    }

    const handleChange = (event, newValue) => {    
        setRangeVal(true);    
        let c = "c" + (activeChallenge + 1);
        props.chg(c, quesName, newValue);
        
    };

    const isMarkSelected = props.isMarkSelected;
    useEffect(() => {
        setRangeVal(isMarkSelected);
    }, [isMarkSelected, activeChallenge]);

    useEffect(() => {
        if(errorClass !== "" && quesName === errorQuestionNumber){
            const headerHeight = isMobile ? 180 : 120; 
            const scrollToEl = myRef.current;
            const topOfElement = window.pageYOffset + scrollToEl.getBoundingClientRect().top - headerHeight;
            window.scroll({ top: topOfElement, behavior: "smooth" });
        }
    }, [errorClass, quesName, errorQuestionNumber, showCardErrorTime, isMobile]);

    return(
        <>
            <div ref={myRef} className={` ${classes.challengeCardContent} challenge_card__content ${errorClass} overflow-hidden shadow-sm  transition-all hover:shadow-md lg:px-12 lg:py-8 md:p-6 `}>            
                {
                    challengeType === 2 ? <>
                    </> : 
                    <>
                        <div className="challenge_card__question pb-10 ">
                            <h6  className={`gesta font-normal text-black text-base md:text-lg  ${props.type === "main" ? classes.challengeTitleBold : classes.challengeTitleRegular}` }>{props.qData}</h6>
                        </div>
                    </>
                }
                <div className={`challenge_slider_wrapper ${classes.challenge_slider_wrapper} md:pl-6 md:pr-6 pl-2 pr-2 ${rhrWindow.height<600? ' smallslider ': ''}  ${props.vals === 6 ? ' medium_right ' : props.vals === 7 ? ' very_right ' : ''}`}>
                    <ChallengeSliderRange
                        getAriaValueText={ariatext}
                        aria-labelledby="discrete-slider"
                        valueLabelDisplay="on"
                        id={quesName}
                        name={quesName}
                        step={1}
                        marks={challengeType === 1 ? marks : marks2}
                        min={1}
                        max={7}
                        track={false}
                        className={rangeVal ? 'selected' : ''}
                        value={rangeVal ? props.vals : 0}
                        onChangeCommitted={handleChange}  //(e, val) => props.chg(e, val, props.c, props.q)
                        onChange={handleChange}  //(e, val) => props.chg(e, val, props.c, props.q)
                        valueLabelFormat={valueLabelText}
                    />
                </div>
            </div>
        </>
    )   
}