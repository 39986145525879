//Material-UI
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

export const BootstrapButton = withStyles({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 18,
        padding: '14px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#EF4050',
        borderRadius: '0',
        borderColor: '#EF4050',
        fontFamily: [
            '-apple-system',
            'Gesta',
        ].join(','),
        // '&:hover': {
        //     backgroundColor: '#ffffff',
        //     borderColor: '#EF4050',
        //     color: '#EF4050',
        //     boxShadow: 'none',
        // },
        // '&:active': {
        //     boxShadow: 'none',
        //     backgroundColor: '#EF4050',
        //     borderColor: '#EF4050',
        // },
        // '&:focus': {
        //     boxShadow: '0 0 0 0.2rem #EF4050',
        // },
    },
})(Button);

export const useStyles = makeStyles((theme) => ({
    root: {
        // minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
}), {index: 1});

export const CssTextField = withStyles({
    root: {
        // backgroundColor: '#fff',
        '& label': {
            fontFamily: "gesta",
            color: '#4F4F4F',
            lineHeight: 'normal',
            position: 'static',
            transform: 'translate(0,0)',
            letterSpacing: '0'
        },
        '& label.Mui-focused': {
            // color: '#EF4050',
            color: '#4F4F4F',
        },
        '& label + .MuiInput-formControl': {
            marginTop: '5px'
        },
        '& .MuiInput-underline:after, & .MuiInput-underline:before': {
            content: 'none'
        },
        '& .MuiOutlinedInput-root': {
            fontFamily: "gesta",
            
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#EF4050',
            },
        },
        '& .MuiInput-input': {
            backgroundColor: '#fff',
            padding: '13px 20px',
            fontFamily: 'Gesta',
            lineHeight: '1.25',
            fontSize: '1rem',
            height: 'auto',
            letterSpacing: '0', 
            border: '1px solid #bdbdbd',
        }
    },
})(TextField);