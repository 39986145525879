import { gql } from '@apollo/client';

export const GET_COMPANIES = gql`
    query companies($searchFor: String,$sortFor: String,$sortDir:Int, $cursor: String, $limit: Int!){
        companies(searchFor:$searchFor,sortFor: $sortFor,sortDir:$sortDir,  cursor: $cursor, limit: $limit) {
            companyFeed {
                id name clients city state country foundYear status
            }
            hasNextPage
        }
    }
`;

export const GET_COMPANY_NAMES = gql`
    query companyNames{
        companyNames {
            id name
        }
    }
`;

export const GET_CLIENTS = gql`
    query clients($searchFor: String,$sortFor: String,$sortDir:Int, $cursor: String, $limit: Int!){
        clients(searchFor:$searchFor,sortFor: $sortFor,sortDir:$sortDir,  cursor: $cursor, limit: $limit) {
            userFeed {
              id firstName lastName email role status progress
              companyId { name }
              currentSessionId { status }
              coachId { firstName lastName }
            }
            hasNextPage
        }
    }
`;

export const GET_COACHES = gql`
    query coaches($searchFor: String, $sortFor: String, $sortDir:Int, $cursor: String, $limit: Int!){
        coaches(searchFor:$searchFor,sortFor: $sortFor,sortDir:$sortDir,  cursor: $cursor, limit: $limit) {
            userFeed {
              id firstName lastName email role bio avatar
              companyId { name }
            }
            hasNextPage
        }
    }
`;

export const GET_COACH = gql`
    query getCoach($id: ID!){
        getCoach(id: $id) {
          firstName lastName role bio email phone
        }
    }
`;

export const GET_CLIENT = gql`
  query getClient($id: ID!, $sessionId: ID) {
    getClient(id: $id, sessionId: $sessionId) {
      id firstName lastName progress age gender ethnic
      companyId {
        name foundYear headCount funding address currentSeries industrySectors
      }
      coachId { firstName lastName bio }
      currentSessionId {
        id sessionNo status coachNotesPublic coachNotesPrivate clientNotes 
        priority1Id priority1Note priority2Id priority2Note priority3Id priority3Note
        meetingDate surveyActive surveyDue isLocked createdAt updatedAt
      }
      currentResultId {
        id isLocked createdAt updatedAt questionAnsweredTill completedOn
        c1 { q1 q2 q3 q4 q5 average importance does should }
        c2 { q1 q2 q3 q4 q5 average importance does should }
        c3 { q1 q2 q3 q4 q5 average importance does should }
        c4 { q1 q2 q3 q4 q5 average importance does should }
        c5 { q1 q2 q3 q4 q5 average importance does should }
        c6 { q1 q2 q3 q4 q5 average importance does should }
        c7 { q1 q2 q3 q4 q5 average importance does should }
      }
      isAdmin isCoach email phone company createdAt updatedAt status
    }
  }
`;

export const GET_COACH_NAMES = gql`
    query coachNames{
      coachNames {
            id firstName lastName
        }
    }
`;

export const ADD_USER_COMPANY = gql`
mutation createUserCompany($input: createCompanyInput) {
  createUserCompany(
    input: $input
  ) {
    id name clients city state country foundYear status
  }
}
`;

// WILL BE NEEDED IN FUTURE
export const ADD_COMPANY = gql`
mutation createCompany($input: createCompanyInput) {
  createCompany(
    input: $input
  ) {
    id name clients city state country foundYear status
  }
}
`;

export const ADD_CLIENT = gql`
mutation createClient($input: signUpInput) {
  clientSignup(input: $input) {
    id firstName token
  }
}
`;

export const UPDATE_USER = gql`
mutation updateUser($input: updateInput) {
  updateUser(input: $input)
}
`;

export const ADD_COACH = gql`
mutation createCoach($input: signUpInput) {
  coachSignup(
    input: $input) {
    id firstName token
  }
}
`;

export const LOGIN_USER = gql`
  mutation clientLogin($email: String!, $password: String!) {
    clientLogin(email: $email, password: $password) {
      id firstName lastName
      companyId {
        name foundYear headCount funding address
      }
      coachId { 
        firstName lastName avatar bio 
      }
      currentSessionId {
        id sessionNo status coachNotesPublic coachNotesPrivate clientNotes 
        priority1Id priority1Note priority2Id priority2Note priority3Id priority3Note
        meetingDate surveyActive surveyDue isLocked createdAt updatedAt
      }
      currentResultId {
        id isLocked createdAt updatedAt questionAnsweredTill completedOn
        c1 { q1 q2 q3 q4 q5 average importance does should }
        c2 { q1 q2 q3 q4 q5 average importance does should }
        c3 { q1 q2 q3 q4 q5 average importance does should }
        c4 { q1 q2 q3 q4 q5 average importance does should }
        c5 { q1 q2 q3 q4 q5 average importance does should }
        c6 { q1 q2 q3 q4 q5 average importance does should }
        c7 { q1 q2 q3 q4 q5 average importance does should }
      }
      token isAdmin isCoach email company createdAt updatedAt
    }
  }
`;

export const LOGIN_COACH = gql`
  mutation coachLogin($email: String!, $password: String, $avatar: String ) {
    coachLogin(email: $email, password: $password, avatar: $avatar ) {
      id firstName lastName isAdmin isCoach email createdAt token
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  query forgotPassword($email: String!) {
    forgotPassword(email: $email)
}
`;

export const WELCOME_CLIENT = gql`
  query welcomeClient {
  welcomeClient{
    id firstName lastName isAdmin isCoach email createdAt token isPasswordCreated isPasswordReseted
  }
}
`;

export const SET_PASSWORD = gql`
  mutation setPassword($email: String!, $newPassword: String! ) {
    setPassword(email: $email, newPassword: $newPassword) {
      id token firstName lastName
    }
  }
`;

export const UPDATE_RESULTS = gql`
mutation updateResults($input: updateResultInput) {
  updateResults( input: $input ) 
}
`; 

export const ADD_DEMOGRAPHIC_DATA = gql`
mutation addDemoData($input: demoInput) {
  addDemoData(input: $input)
}
`;

export const ADD_CLIENT_NOTES = gql`
mutation addClientNotes($id: ID!, $notes: String) {
  addClientNotes(id: $id, notes: $notes)
}
`;

export const ADD_CLIENT_DOES = gql`
mutation updateClientDoes($sessionId: ID!, $does: [Int]) {
  updateClientDoes(sessionId: $sessionId, does: $does)
}
`;

export const ADD_CLIENT_SHOULD = gql`
mutation updateClientShould($sessionId: ID!, $should: [Int]) {
  updateClientShould(sessionId: $sessionId, should: $should)
}
`;

export const ADD_CLIENT_PRIORITY = gql`
mutation updateClientPriority($sessionId: ID!, $isChecked: [Boolean]) {
  updateClientPriority(sessionId: $sessionId, isChecked: $isChecked)
}
`;

export const ADD_CLIENT_PRIORITY_NOTES = gql`
mutation updateClientPriorityNotes($sessionId: ID!, $note1: String, $note2: String, $note3: String) {
  updateClientPriorityNotes(sessionId: $sessionId, note1: $note1, note2: $note2, note3: $note3)
}
`;

export const SEND_REPORT = gql`
mutation sendReport($id: ID!, $email: String, $toSend: Boolean, $sessionId: ID) {
  sendReport(id: $id, email: $email, toSend: $toSend, sessionId: $sessionId)
}
`;

export const SET_STATUS = gql`
mutation setStatus($id: ID!, $status: Progress!) {
  setStatus(id: $id, status: $status)
}
`;

export const SUSPEND_USER = gql`
mutation suspendUser($id: ID!) {
  suspendUser(id: $id)
}
`;

export const CANCEL_INVITATION = gql`
mutation cancelInvitation($id: ID!) {
  cancelInvitation(id: $id)
}
`;

export const RESEND_INVITATION = gql`
mutation resendInvitation($id: ID!) {
  resendInvitation(id: $id)
}
`;

export const SAVE_MEETING = gql`
mutation saveMeeting($id: ID!, $coachNotesPublic: String, $coachNotesPrivate: String) {
  saveMeeting(id: $id, coachNotesPublic: $coachNotesPublic, coachNotesPrivate: $coachNotesPrivate)
}
`;

export const GET_REPORTS = gql`
  query dashReports {
  dashReports{
    reportValues csvDone
    lastUpdates { id status clientId { firstName lastName }}
    upcomingSessions { id status clientId { firstName lastName role status company }}
  }
}
`;

export const UPLOAD_FILE = gql`
mutation uploadFile($file: Upload!) {
  uploadFile(file: $file) {
    url
  }
}
`; 

export const REMOVE_FILE = gql`
mutation removeFile($id: ID!) {
  removeFile(id: $id)
}
`; 

export const GET_QUESTION_ANSWERED_TILL = gql`
  query getQuestionAnsweredTill($sessionId: ID!) {
    getQuestionAnsweredTill(sessionId: $sessionId)
}
`;

export const CHANGE_PASSWORD = gql`
mutation changePassword($email: String!, $oldPassword: String!, $newPassword: String!, $confirmPassword: String!) {
    changePassword(email: $email, oldPassword: $oldPassword, newPassword: $newPassword, confirmPassword: $confirmPassword){
        id email
    }
}
`; 

export const DOWNLOAD_REPORT = gql`
mutation downloadReport($id: ID!, $sessionId: ID,) {
    downloadReport(id: $id, sessionId: $sessionId)
}
`;

export const GET_CSV = gql`
  query getCsv {
    getCsv
}
`;

export const GET_COMPLETED_SELF_AUDIT_LIST = gql`
  query getCompletedSelfAuditList($id: ID!) {
    getCompletedSelfAuditList(id: $id){
        id status
        resultId { id completedOn }
    }
}
`;

export const START_NEW_SELF_AUDIT = gql`
mutation startNewSelfAudit($id: ID!) {
    startNewSelfAudit(id: $id)
}
`;