import { useContext } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AuthProvider } from "./context/auth";
import { ResultsProvider } from "./context/result";
import { SessionProvider } from "./context/session";
import AuthRoute from "./util/AuthRoute";
import AuthDashRoute from "./util/AuthDashRoute";
import { AuthContext } from "./context/auth";

// user imports
import CoachHome from './dashboard/Home';
//import CoachLogin from "./dashboard/Login";

import Client from "./dashboard/Client";
import ClientOverview from "./dashboard/ClientOverview";

// FrontEnd auth
import Home from './pages/HomeDashboard';
import Login from './pages/Login';
import Start from './pages/Start';
import Reset from './pages/Reset';
import Welcome from './pages/Start';
import Challenges from './pages/Challenges';
import NavigationChallenges from './pages/NavigationChallenges';
import GeneralInstruction from './pages/GeneralInstruction';
import Report from './pages/Report';
import Report3 from './pages/Report3';
//Terms of Services page
import TermsService from "./pages/TermsService";
import Error from "./pages/Error";
import ChangePassword from "./pages/ChangePassword";

import NotFound from "./pages/NotFound";
import { ErrorBoundary } from "react-error-boundary";

function App() {
  const { user, logout } = useContext(AuthContext);

  const errorHandler = (error, errorInfo) => {
    console.log("Logging", error, errorInfo);
  }

  return (
    <AuthProvider>
    <ResultsProvider>
    <SessionProvider>
    <BrowserRouter>
    <ErrorBoundary FallbackComponent={Error} onError={errorHandler}>
        <Switch>
            {/* Self-Audit Routes */}
            <Route exact path='/' component={Login} />
            <AuthRoute exact path='/dashboard' component={() => (<Home logout={logout} />)} />
            <AuthRoute exact path='/home' component={() => (<Home logout={logout} />)} />
            <Route exact user={user} path='/start' component={Start} />
            <Route exact user={user} path='/reset' component={Reset} />
            <Route exact user={user} path='/welcome' component={Welcome} />
            <Route exact user={user} path="/terms-of-services" component={TermsService} />
            <Route exact user={user} path="/changePassword" component={ChangePassword} />
            <AuthRoute exact user={user} path="/challenges" component={Challenges} />
            <AuthRoute exact user={user} path="/terms-of-services" component={TermsService} />
              <AuthRoute exact user={user} path="/navigation-challenges" component={NavigationChallenges} />
              <AuthRoute exact user={user} path="/general-instruction" component={GeneralInstruction} />
              <AuthRoute exact user={user} path="/reports" component={Report} />
              <AuthRoute exact user={user} path="/report-studies" component={Report3} />

              {/* Coach Dashboard Routes */}
              {/* <Route exact path="/dashboard" component={CoachLogin} /> */}
              {/* <Route exact path="/dashboard/login" component={CoachLogin} /> */}
              <AuthDashRoute exact path="/dashboard/home" component={CoachHome} />
              
              <AuthDashRoute exact user={user} path="/dashboard/clients" component={Client} />
              <AuthDashRoute exact user={user} logout={logout} path="/dashboard/clients/overview" component={ClientOverview} />
              <Route exact path="/error" component={Error} />

              <Route path="" component={NotFound} />

            </Switch>
            </ErrorBoundary>
          </BrowserRouter>
        </SessionProvider>
      </ResultsProvider>
    </AuthProvider>
   
  );
}

export default App;
