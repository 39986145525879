import { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
//Material ui
import Button from "@material-ui/core/Button";
import { Box } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import json2mq from "json2mq";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import userSuspended from "../assets/imgaes/userSuspended.svg";
import Moment from 'moment';
import SnackBarAlert  from '../components/SnackBarAlert';

import { SUSPEND_USER, CANCEL_INVITATION, RESEND_INVITATION, GET_COMPLETED_SELF_AUDIT_LIST, START_NEW_SELF_AUDIT } from "../util/graphql";
import PreLoader from '../components/PreLoader';

function ClientOverViewContent(props) {
  const client = props.client;
  const [sessionStatus, setSessionStatus] = useState(client.currentSessionId.status);
  const [userStatus, setUserStatus] =  useState(client.status);
  const [isFirstSelfAudit, setIsFirstSelfAudit] = useState(true);

  const [alert, setAlert] = useState(false); 
  const [msg, setMsg] = useState('');
  const [severity, setSeverity] = useState('error');
  let isInProgressStatus = false;
  let isCompletedStatus = false;
  let isCancelledStatus = false;
  let inProgressDate = Moment(client.currentSessionId.updatedAt).format("M/D/YYYY");

  const showAlertMessage = (msg, severity) => {
    setAlert(true);
    setMsg(msg);
    setSeverity(severity);
    setTimeout(() => {
        setAlert(false);
        setSeverity("error");
    }, 4000);
  }

  if(client.currentResultId.completedOn !== null){
    if(Moment(client.updatedAt).isAfter(Moment(client.currentResultId.completedOn))){
        inProgressDate = Moment(client.currentResultId.completedOn).format("M/D/YYYY");
      }
  }

  if(sessionStatus === "COMPLETED"){
    isCompletedStatus = true;
  }
  else if(sessionStatus === "IN_PROGRESS"){
    isInProgressStatus = true;
  }
  if(sessionStatus === "CANCELLED"){
    isCancelledStatus = true;
  }

  const clientName = client.firstName + " " + client.lastName;
  const { viewReport } = props;

  const { data: selfAuditList, loading } = useQuery(GET_COMPLETED_SELF_AUDIT_LIST, {
    variables: { id: client.id },
    fetchPolicy: "no-cache"
  })

  const [suspendUser] = useMutation(SUSPEND_USER, {
    onCompleted: (data) => {
      if (data.suspendUser) {
        (userStatus === "SUSPENDED") ? setUserStatus("ACTIVE") : setUserStatus("SUSPENDED");
        if(userStatus === "SUSPENDED"){
            showAlertMessage("User is activated successfully!", "success");
          }else{
            showAlertMessage("User is suspended successfully!", "success");
          }
        }
    },
  });

  const [cancelInvitation] = useMutation(CANCEL_INVITATION, {
    onCompleted: (data) => {
      if (data.cancelInvitation) {
        setSessionStatus("CANCELLED");
        showAlertMessage("User's invitation is cancelled successfully!", "success");
      }
    },
  });

  const [resendInvitation] = useMutation(RESEND_INVITATION, {
    onCompleted: (data) => {
      if (data.resendInvitation) {
        setSessionStatus("INVITED");
        showAlertMessage("Invitation is resended successfully!", "success");
      }
    },
  });

  const [startNewSelfAudit] = useMutation(START_NEW_SELF_AUDIT, {
    onCompleted: (data) => {
      if (data.startNewSelfAudit) {
        setSessionStatus("INVITED");
        showAlertMessage("New self audit is inititated successfully!", "success");
      }
    },
  });

  function handleSuspend() {
    suspendUser({ variables: { id: client.id } });
  }

  function handleCancel() {
    cancelInvitation({ variables: { id: client.id } });
  }

  function handleResend() {
    resendInvitation({ variables: { id: client.id } });
  }

  function handleStartNewSelfAudit() {
    startNewSelfAudit({ variables: { id: client.id } });
  }

  const isMobile = useMediaQuery(
    json2mq({
      maxWidth: 767,
    })
  );

  useEffect(() => {
    if (isMobile) {
    }
  }, [isMobile]);

  const selfAuditListCount = selfAuditList?.getCompletedSelfAuditList?.length;
  useEffect(() => {
    if (selfAuditListCount > 1) {
        setIsFirstSelfAudit(false);
    }
    if (selfAuditListCount === 1 && sessionStatus !== "COMPLETED") {
        setIsFirstSelfAudit(false);
    }
  }, [selfAuditListCount, sessionStatus]);

  if(loading){
    return <PreLoader />;
  }

  return (
    <>
    {
        alert ? 
        <SnackBarAlert 
        severity={severity} 
        alertMessage={msg}
        openSnackBar={alert} 
        vertical='top' 
        horizontal='center' 
        autoHideDuration={4000} />
        : <></> 
    }
    <Box className="bg-white md:pl-9 pl-7 md:pr-9 pr-6 md:pt-12 pt-7 md:pb-4 pb-12">
      {/* Head */}
      <Box className="md:border-b md:border-concrete pb-6 mb-0 md:mb-7">
        <Box className="inline-flex items-center justify-between w-full">
          <div className="span client_avatar_content">
            <h2 className="gesta-medium  inline-flex  text-mineshaft tracking-tight leading-none " style={{fontSize:`${isMobile ? '30px' : '42px'}`}}>
              {clientName} 
            </h2>
          </div>
          <div className="flex">
            <div className="span">
              {isMobile 
                ? (userStatus === "SUSPENDED") ? <img src={userSuspended} alt="" onClick={handleSuspend} className="cursor-pointer" /> : <img src={userSuspended} alt="" onClick={handleSuspend} className="cursor-pointer disabled" />
                : <button
                  onClick={handleSuspend}
                  className="resend_link gesta-medium bg-bigstone text-alabaster text-lg py-2.5 px-7 uppercase"
                >
                  {(userStatus === "SUSPENDED") ? 'ACTIVATE USER' : 'SUSPEND USER'}
                </button>
              }
            </div>
          </div>
        </Box>

      </Box>
      { !isFirstSelfAudit ?
        <Box className="pb-3 pt-2.5 md:pt-0 md:pb-2.5 mb-0 md:mb-8">
          <Box className="inline-flex items-center justify-between w-full">
              <div className="span client_avatar_content">
                <h2 className="gesta-medium  inline-flex  text-mineshaft tracking-tight leading-none " style={{fontSize:`${isMobile ? '20px' : '30px'}`}}>
                  Latest Self-Audit Status 
                </h2>
            </div>
          </Box>
        </Box> : ""
      }
      {/* Head */}

      <TableContainer className="shadow-none md:mx-0 -ml-7 -mr-6 md:w-full w-auto">
        <Table aria-label="simple table" className="shadow-none">
          <TableHead>
            <TableRow>
              <TableCell className="border-concrete pl-7 md:pl-2.5 pr-2.5 py-2.5 md:py-4 text-sm md:text-lg gesta-bold uppercase text-bigstone" align="left">
                Date
              </TableCell>
              <TableCell className="border-concrete p-2.5 text-sm md:text-lg gesta-bold uppercase text-bigstone whitespace-nowrap" align="left">
              Self-Audit Status
              </TableCell>
              <TableCell className="border-concrete p-2.5 text-sm md:text-lg gesta-bold uppercase text-bigstone" align="left">
              </TableCell>
              <TableCell className="border-concrete p-2.5 text-sm md:text-lg gesta-bold uppercase text-bigstone" align="left">
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell className="border-concrete pl-7 md:pl-2.5 pr-2.5 py-4 md:py-4 text-black text-base md:text-lg" align="left">
                {Moment(client.currentSessionId.createdAt).format("M/D/YYYY")}
              </TableCell>
              <TableCell className="border-concrete px-2.5 py-4 md:py-4 text-black text-base md:text-lg" align="left">
                {!isCancelledStatus ? "Invited" : "Cancelled"}
              </TableCell>
              <TableCell className="border-concrete px-2.5 py-4 md:py-4 text-black text-base md:text-lg  md:text-right" align="left">
              {
                (!isInProgressStatus && !isCompletedStatus) ? 
                <Button
                    onClick={handleCancel}
                    className="resend_link gesta-medium text-bigstone text-base md:text-lg cursor-pointer capitalize bg-transparent p-0 link"
                    disabled={isCancelledStatus}
                >
                    Cancel Invitation
                </Button> : ""
              }
              </TableCell>
              <TableCell className="border-concrete px-2.5 py-4 md:py-4 text-black text-base md:text-lg  md:text-right" align="left">
              {
                (!isInProgressStatus && !isCompletedStatus) ? 
                <Button
                    onClick={handleResend}
                    className="resend_link gesta-medium text-bigstone text-base md:text-lg cursor-pointer capitalize bg-transparent p-0 link"
                    disabled={!isCancelledStatus}
                >
                    Resend link
                </Button> : ""
              }
              </TableCell>
            </TableRow>
            {   
                isInProgressStatus || isCompletedStatus ? 
                <TableRow>
                    <TableCell className="border-concrete pl-7 md:pl-2.5 pr-2.5 py-4 md:py-4 text-black text-base md:text-lg" align="left">
                        {inProgressDate}
                    </TableCell>
                    <TableCell className="border-concrete px-2.5 py-4 md:py-4 text-black text-base md:text-lg" align="left">
                        In Progress
                    </TableCell>
                    <TableCell className="border-concrete px-2.5 py-4 md:py-4 text-black text-base md:text-lg" align="left">
                        
                    </TableCell>
                    <TableCell className="border-concrete px-2.5 py-4 md:py-4 text-black text-base md:text-lg" align="left">
                    
                    </TableCell>
                </TableRow> : ""
            }
            {
                isCompletedStatus ?
                <TableRow>
                    <TableCell className="border-concrete pl-7 md:pl-2.5 pr-2.5 py-4 md:py-4 text-black text-base md:text-lg" align="left">
                    {Moment(client.currentResultId.completedOn).format("M/D/YYYY")}
                    </TableCell>
                    <TableCell className="border-concrete px-2.5 py-4 md:py-4 text-black text-base md:text-lg" align="left">
                    Completed
                    </TableCell>
                    <TableCell className="border-concrete px-2.5 py-4 gesta-medium text-bigstone text-base md:text-lg md:text-right" align="left">
                      <span
                        onClick={() => viewReport(client.currentSessionId.id, 2)}
                        className="gesta-medium text-bigstone text-base md:text-lg cursor-pointer whitespace-nowrap	link"
                      >
                        View Results
                      </span>
                    </TableCell>
                    <TableCell className="border-concrete px-2.5 py-4 gesta-medium text-bigstone text-base md:text-lg md:text-right" align="left">
                      <span
                        onClick={handleStartNewSelfAudit}
                        className="gesta-medium text-bigstone text-base md:text-lg cursor-pointer whitespace-nowrap	link md:inline hidden"
                      >
                        Start New Self-Audit
                      </span>
                    </TableCell>
                </TableRow> : ""
            }
          </TableBody>
        </Table>
      </TableContainer>
      {
        isCompletedStatus ? <>
          <div className="text-right block md:hidden">

            <span
              onClick={handleStartNewSelfAudit}
              className="gesta-medium text-bigstone text-lg cursor-pointer whitespace-nowrap link"
            >
              Start New<br/>Self-Audit
            </span>
          </div>

        </> : ''

      }
      {
        (selfAuditList && selfAuditList.getCompletedSelfAuditList && selfAuditList.getCompletedSelfAuditList.length > 1) ||
        (selfAuditList && selfAuditList.getCompletedSelfAuditList && selfAuditList.getCompletedSelfAuditList.length === 1 && sessionStatus !== "COMPLETED")
        ? 
        <>
          
          <Box className="border-b border-concrete mt-8 md:mt-14 md:pt-1 pb-3 md:pb-8 mb-0">
            <Box className="inline-flex items-center justify-between w-full">
              <div className="span client_avatar_content">
                <h2 className="gesta-medium  inline-flex  text-mineshaft tracking-tight leading-none " style={{fontSize:`${isMobile ? '20px' : '30px'}`}}>
                  Self-Audit History 
                </h2>
              </div>
            </Box>
          </Box>

          <TableContainer className="shadow-none md:mx-0 -ml-7 -mr-6 md:w-full w-auto">
            <Table aria-label="simple table" className="shadow-none">
              <TableBody>
                {
                  selfAuditList.getCompletedSelfAuditList?.map(
                    (data, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell className="border-concrete pl-7 md:pl-2.5 pr-2.5 py-1.5 md:py-4 text-black text-base md:text-lg" align="left">
                            Founder's Journey Self-Audit - <span className="block md:inline-block">{Moment(data.resultId.completedOn).format("MMMM D, YYYY")}</span>
                          </TableCell>
                          <TableCell className="border-concrete p-2.5 gesta-medium text-bigstone text-base md:text-lg  md:text-right" align="left">
                            <span
                              onClick={() => viewReport(data.id, 2)}
                              className="gesta-medium text-bigstone text-base md:text-lg cursor-pointer whitespace-nowrap	link"
                            >
                              View Report
                            </span>
                          </TableCell>
                        </TableRow>
                      )
                    }
                  )
                }
              </TableBody>
            </Table>
          </TableContainer> 
        </>       
        : ""
      }

    </Box>
    </>
  );
}

export default ClientOverViewContent;
