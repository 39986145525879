import React from "react";
import App from "./App";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

var host = window.location.origin + "/graphql";
if (host.substring(7, 12) === "local") host = "http://localhost:5001/graphql";
const httpLink = new createUploadLink({
  uri: host,
});

const authLink = setContext(() => {
  var token = sessionStorage.getItem("jwtToken");
  return {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authLink, errorLink, httpLink]),
});

const ApolloProviderComponent = () => {
  return (
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
  );
};

export default ApolloProviderComponent;
