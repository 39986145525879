import { useState, useContext, useImperativeHandle, forwardRef } from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from '../hooks/form';
import { AuthContext } from '../context/auth';

// Material Ui
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { Typography } from '@material-ui/core';

// User Defined
import { ADD_CLIENT, GET_CLIENTS } from '../util/graphql';
import ClientSignUpStep from './ClientSignUpStep';
import SnackBarAlert from './SnackBarAlert';
import attention from '../assets/imgaes/warning.svg';
import {emailValidate} from "../util/validators";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ClientSignUpModal = forwardRef((props, ref) => {
    // Errors and Open states
    const { user } = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState(false); 
    const [msg, setMsg] = useState('');
    const [severity, setSeverity] = useState('error');
    //Snackbar Alert State
    const [setOpenSnackBar] = useState(true);
    const [showErrorDiv, setShowErrorDiv] = useState(false);
    const [errorMessage, setErrorMessage] = useState(["Empty Fields", "You must fill out all of the required fields before creating a new client"]);
    const [isFirstNameError, setIsFirstNameError] = useState(false);
    const [isLastNameError, setIsLastNameError] = useState(false);
    const [isEmailError, setIsEmailError] = useState(false);
    const [isConfirmEmailError, setIsConfirmEmailError] = useState(false);

    //Snackbar alert Close Handeler
    const handleCloseSnackbar = (event, reason)=> {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false);
    }

    //Form input handling
    const { onChange, onSubmit, values, setValues } = useForm(addClientCallback, {
        firstName: '',
        lastName: '',
        companyId: '',
        role: 'Founder',
        phone: '',
        email: '',
        confirmEmail: '',
        sessions: 1,
        coachId: user.id
    });

    // Add client mutation // Values from the form
    const [addClient] = useMutation(ADD_CLIENT, {
        variables: { input: values },
        onCompleted: (data) => {
            setValues({
                firstName: '',
                lastName: '',
                companyId: '',
                role: 'Founder',
                phone: '',
                email: '',
                confirmEmail: '',
                sessions: 1,
                coachId: user.id
            });
            setAlert(true);   
            setMsg("Client created successfully!");
            setSeverity("success");
            setTimeout(() => {
                setAlert(false);
                setSeverity("error");
            }, 3000);
            props.refetchHandler(true);
        },
        refetchQueries: [{ query: GET_CLIENTS, variables: props.variables }],
        onError(err) {
            setAlert(true);   
            setMsg("Client could not be created at this time!");
            setTimeout(() => {setAlert(false)}, 3000);
        }
    });

    // Once form submitted, Client is created then closes the modal
    function addClientCallback() {
        let isValid = true;
        setIsFirstNameError(false);
        setIsLastNameError(false);
        setIsEmailError(false);
        setIsConfirmEmailError(false);
        if (values.firstName.trim() === '') {
            setErrorMessage(["Empty Fields", "You must fill out all of the required fields before creating a new client"]);
            setShowErrorDiv(true);
            setIsFirstNameError(true);
            isValid = false;
        }
        if (values.lastName.trim() === '') {
            setErrorMessage(["Empty Fields", "You must fill out all of the required fields before creating a new client"]);
            setShowErrorDiv(true);
            setIsLastNameError(true);
            isValid = false;
        }
        if (values.email.trim() === '') {
            setErrorMessage(["Empty Fields", "You must fill out all of the required fields before creating a new client"]);
            setShowErrorDiv(true);
            setIsEmailError(true);
            isValid = false;
        }
        if (values.confirmEmail.trim() === '') {
            setErrorMessage(["Empty Fields", "You must fill out all of the required fields before creating a new client"]);
            setShowErrorDiv(true);
            setIsConfirmEmailError(true);
            isValid = false;
        }
        if(!isValid){
            return isValid;
        }
        if (!emailValidate(values.email)) {
            setErrorMessage(["Invalid Value", "Invalid Email! Please provide a valid email"]);
            setShowErrorDiv(true);
            setIsEmailError(true);
            isValid = false;
        }
        if (!emailValidate(values.confirmEmail)) {
            setErrorMessage(["Invalid Value", "Invalid Email! Please provide a valid email"]);
            setShowErrorDiv(true);
            setIsConfirmEmailError(true);
            isValid = false;
        }
        if(!isValid){
            return isValid;
        }
        if (values.email !== values.confirmEmail) {
            setErrorMessage(["Different Values", "Emails don't match! Please enter matching email addresses"]);
            setShowErrorDiv(true);
            setIsEmailError(true);
            setIsConfirmEmailError(true);
            isValid = false;
        }
        if(!isValid){
            return isValid;
        }
        addClient();
        setOpen(false);
        setShowErrorDiv(false);
    }

    // New Client modal control
    useImperativeHandle(ref, () => ({
        getModal() {
            setOpen(true);
        }
    }));

    // New Client modal close
    const handleClose = () => {
        setShowErrorDiv(false);
        setIsFirstNameError(false);
        setIsLastNameError(false);
        setIsEmailError(false);
        setIsConfirmEmailError(false);
        setOpen(false);
    };

    return (
        <>
        {
        alert ? <SnackBarAlert severity={severity} alertMessage={msg} handleCloseSnackbar={handleCloseSnackbar} openSnackBar={alert} vertical='top' horizontal='center' autoHideDuration={6000} />: ''
        }
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            maxWidth="md"
            className='clientListModal z-50'
        >
            {
                showErrorDiv ?
                <div className='errorMessage'>
                    <div className='text-left md:p-4 px-9 py-4 gesta-medium bg-yellow-200 md:items-center flex md:flex-row flex-col'>
                        <h4 className='text-lg md:text-xl gesta-bold mr-5 text-black inline-flex whitespace-nowrap md:mb-0 mb-2'><img src={attention} alt="" className='mr-2' />{errorMessage[0]}</h4>
                        <p className='text-sm md:text-base'>{errorMessage[1]}</p>
                    </div>
                </div> : ""
            }
            <DialogContent
            className='flex justify-center flex-col p-9 md:p-14'>
                <Typography       
                component=''
                className='font-medium text-dovegray text-3xl mb-7' style={{fontFamily: 'gesta',}}>
                    Create Client Account
                </Typography>
                <Box>
                    <form action="#">
                        <ClientSignUpStep 
                            chg={onChange} 
                            sub={onSubmit} 
                            vals={values} 
                            handleClose={handleClose} 
                            isFirstNameError={isFirstNameError}
                            isLastNameError={isLastNameError}
                            isEmailError={isEmailError}
                            isConfirmEmailError={isConfirmEmailError}
                        />
                    </form>
                </Box>
            </DialogContent>
        </Dialog>
        </>
    )
});

export default ClientSignUpModal;