import { useState } from "react";
// Material Ui
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import json2mq from "json2mq";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
// User Defined
import SvgIcon from "../SvgIcon";
import ChallengeQuestionDialogHead from "./ChallengeQuestionDialogHead";
// Data
import { challenges } from "../../data/ChallengeData";

function TakewaysModal({ chg, val1, val2, val3, p1, p2, p3 }) {
  const [activeId,setActiveId] = useState(1);
  const isMobile = useMediaQuery(
    json2mq({
      maxWidth: 1023,
    })
  );


 

  var title1 = '';
  var icon1 = '';
  var color1 = '';
  var title2 = '';
  var icon2 = '';
  var color2 = '';
  var title3 = '';
  var icon3 = '';
  var color3 = '';
  
  challenges && challenges.map(c => {
    if (c.id === p1) {
      title1 = c.challenge;
      icon1 = c.icon;
      color1 = c.color;
    }
    if (c.id === p2) {
      title2 = c.challenge;
      icon2 = c.icon;
      color2 = c.color;
    }
    if (c.id === p3) {
      title3 = c.challenge;
      icon3 = c.icon;
      color3 = c.color;
    }
    return false;
  })

  return (
    <div className="scaleit__fadeIn">
      <ChallengeQuestionDialogHead
        headingTitle="As you review your scores,  
                what are your priorities?"
        headingContent=""
        hasHeadingContent={false}
      />
      <Box  className="flex justify-center pt-8 flex-col items-center">
        <Box 
        className={`flex justify-aorund items-center bg-${color1} p-2 pl-3 rounded-t w-4/5 mb-5`}
        onClick={()=>setActiveId(1)}
        >
   
          <SvgIcon
            clName='takewayicon'
            icon={icon1}
           />
          <Typography
            component=""
            className="gesta font-medium text-xs sm:text-sm md:text-sm pl-2 text-white uppercase  mr-auto "
          >
            {title1}
          </Typography>
          {
            activeId !== 1 ? <ExpandMoreIcon className='text-white cursor-pointer'/> 
            : <ExpandLessIcon className='text-white cursor-pointer'/>
          }
        </Box>

        <Box className={`w-4/5 pb-4 ${activeId !== 1 ? 'hidden' : 'flex'}`}>
          <TextField
            id="outlined-helperText"
            label="Why is this a priority?"
            placeholder="Why is this a priority?"
            variant="outlined"
            className="w-full"
            name="priority1Note"
            value={val1}
            onChange={chg}
            size={isMobile ? 'small': 'medium'}
          />
        </Box>
      </Box>
      <Box  className="flex justify-center items-center flex-col">
        <Box 
        className={`flex justify-between items-center bg-${color2}  rounded-t p-2 pl-3 w-4/5 mb-5`}
        onClick={()=>setActiveId(2)}
        >
        <SvgIcon
            clName='takewayicon'
            icon={icon2}
           />
          <Typography
            component=""
            className="gesta font-medium  text-xs sm:text-sm md:text-sm pl-2 text-white uppercase mr-auto"
          >
            {title2}
          </Typography>
          {
            activeId !== 2 ? <ExpandMoreIcon className='text-white cursor-pointer'/> 
            : <ExpandLessIcon className='text-white cursor-pointer'/>
          }
        </Box>

        <Box className={`w-4/5 pb-4 ${activeId !== 2 ? 'hidden' : 'flex'}`}>
        <TextField
          id="outlined-helperText2"
          label="Why is this a priority?"
          placeholder="Why is this a priority?"
          variant="outlined"
          className="w-full"
          name="priority2Note"
          value={val2}
          onChange={chg}
          size={isMobile ? 'small': 'medium'}
        />
        </Box>
      </Box>
      <Box  className="flex justify-center pt-2 items-center flex-col">
        <Box 
        className={`flex justify-aorund items-center bg-${color3} rounded-t p-2 pl-3 w-4/5 mb-5`}
        onClick={()=>setActiveId(3)}
        >
        <SvgIcon
            clName='takewayicon'
            icon={icon3}
           />
          <Typography
            component=""
            className="gesta font-medium text-xs sm:text-sm md:text-sm pl-2 text-white mr-auto"
          >
            {title3}
          </Typography>
          {
            activeId !== 3 ? 
            <ExpandMoreIcon className='text-white cursor-pointer'/> 
            : <ExpandLessIcon className='text-white cursor-pointer'/>
          }
        </Box>

        <Box className={`w-4/5 ${activeId !== 3 ? 'hidden' : 'flex'}`}>
        <TextField
          id="outlined-helperText3"
          label="Why is this a priority?"
          placeholder="Why is this a priority?"
          variant="outlined"
          className="w-full"
          name="priority3Note"
          value={val3}
          onChange={chg}
          size={isMobile ? 'small': 'medium'}
        />
        </Box>
      </Box>
    </div>
  );
}

export default TakewaysModal;
