import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
// import { translate } from 'pdfkit';
// import { Translate } from '@material-ui/icons';

export const challengeStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "15px",
  },
  challengeDialog: {
    transition: 'all 1s linear',
    "& .MuiDialog-paper": {
      transition: 'all 1s linear',
      maxWidth: "750px",
    },
  },
  challengeDialogContent: {
    transition: 'all 1s linear',
    '&.timeAllocation': {
      '& .challenge_question_dialog_head':{
        maxWidth: '940px',
        textAlign: 'left !important',
      },
      '& h4.subHeading': {
        maxWidth: '940px',
        margin: 'auto',
        padding: '0 20px',
        fontWeight: '500',
        textTransform: 'uppercase',
        '@media (max-width: 767px)': {
          padding: '0',
          fontSize: '15px',
        },
      },
    },
    '&.submitResponse':{
      paddingTop: '101px !important',
      '&:before':{
        content: '""',
        position: 'absolute',
        height: '100%',
        width: '100%',
        background: '#fff',
        top: '0',
        zIndex: '1',
        left: '0',
        pointerEvents: 'none',
      },
      '& > div': {
        position: 'relative',
        zIndex: '2',
      },
      '@media (max-width:767px)':{
        paddingTop: '86px !important',
        '& .challenge_question_dialog_head': {
          '& h2':{
            textAlign: 'left !important',
            fontSize: '18px',
            fontWeight: '400 !important'
          }

        }
      }
    },
    '& .completionMessage': {
      paddingTop: '220px',
      '&:before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        background: '#fff',
        height: '100%',
        width: '100%',
        zIndex: '1',
      },
      '& > div':{
        position: 'relative',
        zIndex: '2',
      },
      '@media (min-width:768px) and (max-height:768px)':{
        paddingTop: '100px'
      },
      '@media (max-width:767px)':{
        paddingTop: '93px',
        paddingLeft: '36px',
        paddingRight: '36px',
      }
    },
    "&.spendtime": {
      "& .challenge_question_dialog_head": {
        "& h2": {
          // maxWidth: "60%",
          // display: "inline-block",
        },
      },
    },
  },
  fieldParent:{
    // height:'150px',
    '& label': {
      display: 'none',
    },
    '& .MuiInputBase-root':{
      padding: '0',
      borderRadius: '0',
      '& textarea': {
        minHeight: '234px',
        border: '1px solid #bdbdbd',
        fontFamily: 'gesta',
        padding: '1rem',
        boxSizing: 'border-box',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        display: 'none'
      }
    },
  },
  singleQuestionParent: {
    "& .single_question": {
      background: "#fff",
      borderTop: '1px solid #f2f2f1',
      padding: "0px 0px",
      '@media (max-width: 767px)':{
        minHeight: '57px',
      },
      '&:first-child':{
        borderTop: 0,
      },
      '&.total':{
        background: 'transparent',
        padding:'34px 22px 34px 34px',
        '& h6':{
          fontSize:'1.25rem',
          fontWeight:'700',
        },
        '& .question_parecent_input_parent':{
          background: '#4f4f4f',
          border: 'none',
          borderRadius: '0',
          padding: '5px !important',
          color: '#fff',
          fontSize: '18px',
          fontWeight: '700',
          textAlign:'center',
        },
        '& .total_value':{
          paddingRight: '0 !important',
        },
        '@media (max-width:767px)':{
          padding: '25px 20px 25px 14px',
          '& .question_parecent_input_parent':{
            width: '58px',
            maxWidth: '58px',
            paddingTop: '2px !important',
            paddingBottom: '2px !important',
          },
        }
      },
      "& h6": {
        fontSize: "18px",
        lineHeight: "38px",
        fontFamily: "gesta",
        letterSpacing: "0",
        margin: "0",
        color: "#000",
        '@media (max-width:767px)':{
          fontSize:'15px',
          lineHeight: '1.2'
        }
      },
      "& .question_border": {
        // height: "100%",
        width: "5px",
        position: "absolute",
        left: "0",
        top: '0',
        bottom: '0',
      },
      "& .question_parecent_input_parent": {
        maxWidth: "73px",
        width:'73px',
        display: "inline-flex",
        justifyContent: 'flex-end',
        fontFamily: 'Gesta',
        marginBottom: '0',
        padding: '1px 0',
        border: '1px solid #bdbdbd',
        // paddingRight: '8px',
        '@media (max-width:767px)':{
          width: '68px',
          maxWidth: '68px',
          flex: '0 0 68px',
        },
        '& .MuiInput-underline':{
          '&:before':{
            display: 'none !important',
          },
          '&:after':{
            display: 'none !important',
          }
        },
        "& .question_parecent_label": {
          // padding: "5px",
          background: '#fff',
          fontSize: "18px",
          fontFamily: "Gesta",
          color: "#4F4F4F",
          '@media (max-width: 767px)':{
            fontSize:'15px',
            lineHeight: '2'
          }
        },
        "& input": {
          textAlign: 'right',
          '@media (max-width:767px)':{
            fontSize:'15px',
          }
        },
        '& .MuiSelect-select':{
          paddingLeft: '5px',
          paddingTop: '5px',
          paddingBottom: '5px',
          '&:focus':{
            background:'none',
          }
        },
        TextField: {
            background: '#fff',
          "& input": {
            background: '#fff',
            height: "40px",
            padding: "0px 0 0px",
          },
        },
      },
    },
  },
  Total: {
    "& p": {
        fontSize: "14px",
        color: "#333333",
        fontFamily: "gesta",
    },
    "& .total_value": {},
    "& .question_validation": {
      transition: "all .30s linear",
      right: "-26px",
      top: "6px",
    },
  },
  headingStyle: {
    maxWidth: '884px',
    margin: 'auto',
    padding: '0 20px',
    '@media (max-width: 767px)': {
      padding: '0',
    },
    "& h2": {
      fontSize: "30px",
      fontFamily: "gesta",
      color: "#4f4f4f",
      lineHeight: "1.4",
      marginBottom:'0.75rem', 
      '@media (max-width: 767px)': {
        fontSize: '20px',
        marginBottom: '4px',
      }
    },
    "& p": {
      color: "#000",
      fontSize: "18px",
      fontFamily: "Gesta",
      lineHeight: "1.39",
    },
  },
  timeAllocationTItle:{
    '& h2':{
      fontSize:'42px',
      fontWeight: '500',
      lineHeight: 'normal',
      color:'#4f4f4f',
    },
    '& p': {
      fontSize: '18px',
      lineHeight: '1.39',
    },
    '@media (max-width: 767px)':{
      textAlign: 'center',
      '& h2':{
        fontSize: '20px',
        fontWeight: '700',
        marginBottom: '12px'
      },
      '& p': {
        fontSize: '14px',
      }
    }
  },
  reportTitle:{
    '& .challenge_bar_indicator':{
      display:'flex',
      padding:'7.5px 18px',
      fontSize: '18px',
      fontWeight: '500',
      background: '#121e31',
      color: '#fff',
      lineHeight: '1.34',
      bottom: 'calc(100% + 48px)',
      '& span':{
        display: 'block',
        letterSpacing: '0 !important',
        fontWeight: '700',
        '&.optional':{
          color: '#bdbdbd'
        },
      },
      '@media (max-width: 767px)':{
        padding:'5px 8px',
        fontSize: '14px',
        fontWeight: '700',
        left: '0',
        margin: '0 auto',
        width: '160px',
        textAlign: 'center',
        bottom: 'calc(100% + 80px)',
        justifyContent: 'center',
      }
    },
  },
  challenge_bar_parent: {
    height: "400px",
    background: '#f2f2f1',
    marginLeft: '24px',
    marginRight: '40px',
    '@media (max-width: 767px)':{
      marginLeft: '18px',
      marginRight: '26px',
      height: '280px',
    },
    '& .partition-group': {
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'space-between',
      height: '100%',
      width: '40px',
      textAlign: 'left',
      '@media (max-width: 767px)':{
        width: '30px'
      },
      '&:before':{
        content:'""',
      },
      '& > span':{
        color: '#bdbdbd',
        fontSize: '12px',
        fontWeight: '500',
        display: 'inline-block',
        marginTop: '-9px',
        textAlign: 'right',
        paddingRight: '10px',
        '&:after':{
          content: '""',
          position: 'absolute',
          left:'40px',
          height:'2px',
          background: '#bdbdbd',
          width: 'calc(100% - 40px)',
          marginTop: '8px'
        },
        '@media (max-width: 767px)':{
          paddingRight: '8px',
          '&:after':{
            width: 'calc(100% - 30px)',
            left:'30px',
          }
        },
      }
    },
    '& .challenge_bar_indicator':{
      display:'flex',
      padding:'7.5px 18px',
      fontSize: '18px',
      fontWeight: '500',
      background: '#121e31',
      color: '#fff',
      lineHeight: '1.34',
      bottom: 'calc(100% + 48px)',
      '& span':{
        display: 'block',
        letterSpacing: '0 !important',
        fontWeight: '700',
        '&.optional':{
          color: '#bdbdbd'
        },
      },
      '@media (max-width: 767px)':{
        padding:'5px 8px',
        fontSize: '14px',
        fontWeight: '700',
        left: '0',
        margin: '0 auto',
        width: '160px',
        textAlign: 'center',
        bottom: 'calc(100% + 80px)',
        justifyContent: 'center',
    }
    },
    '& .single_challenge_bar': {
          flex: "0 0 14.28%",
          display: "flex",
          position: "relative",
          maxWidth: "14.28%",
          justifyContent: "center",
          // marginBottom: "2.5rem",
          '@media (max-width: 767px)':{
            '&:before':{
              content: "''",
              position: 'absolute',
              top: 'auto',
              bottom: '-56px',
              left: '0',
              right: '0',
              border: '1px solid #121e31',
              borderRadius: '4px',
              display: 'none',
              minHeight: '356px',
            },
            '&.focus':{
              '&:before':{
                display: 'block',
              },
              '& .challenge_bar_parent_value':{
                display: 'flex',
              },
            },
          },
        '& .parcent': {
          fontSize: "14px",
          lineHeight: "15px",
          color: "#828282",
          fontFamily: "Gesta",
          fontWeight: '700',
          padding: '0 5px',
        },
        '& .bar': {
          '@media (max-width: 767px)':{
            width: '12px !important'
          },
          '&.lighter': {
              marginRight: '8px',
          }
        }
    },
  },
  challenge_bar_parent_value: {
    // width: '80px',
    bottom: 'calc(100% + 15px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '7px 5px',
    '& .caret':{
      position: 'absolute',
      height: 0,
      width:0,
      top: '100%',
      border: '6px solid transparent',
      borderTopColor: '#000',
      left: 0,
      right:0,
      margin: 'auto',
    },
    '@media (max-width:767px)':{
      padding: '7px 5px',
      display: 'none',
      bottom: '315px'
    }
  },
  challengeBarParent: {
      '& .single_challenge_bar': {
      
        paddingBottom: "20px",
        marginBottom: "16px",
      },
  },
  challengeBarLabelIndicator: {
    paddingBottom: "75px",
    background: "#fff",
    paddingLeft: '64px',
    paddingRight: '40px',
    '@media (max-width: 767px)':{
      paddingLeft: '48px',
      paddingRight: '26px',
    },
    '& .single_indicator': {
      display: "flex",
      alignItems: "flex-start",
      flexFlow: "column",
      '&:last-child': {
        '& .text':{
          width: '100px',
        }
      },
      '& .indicator': {
        height: "5px",
        width: "100%",
        display: "block",
      },
      '& .text': {
        fontSize:" 14px",
        lineHeight: "normal",
        letterSpacing: "0",
        textTransform: "uppercase",
        fontWeight: '500',
        '@media (max-width: 767px)':{
          display: 'none',
          marginTop: '25px',
          position: 'absolute',
          top: '22px',
          width: '110px',
          transform: 'translateX(-50%)',
          left: '50%',
          textTransform: 'capitalize',
          '&.focus':{
            display: 'block'
          }
        }
      }
    }
  },
  FinalModalInfoText: {
    color: "#263238",
    fontSize: "13px",
    fontFamily: "gesta",
    '& b':{
      fontFamily: "gesta",
    }
  },
  prioritySectionSingle: {
      paddingLeft: '15px !important',
      '& .MuiFormControlLabel-label': {
        fontSize: "14px",
        lineHeight: "40px",
        fontFamily: "gesta",
        letterSpacing: "0",
        marginLeft: '0',
        color: "#333333",
      },
      '& .MuiSvgIcon-root': {

      }
  },  
  challenge_question_dialog_body:{
    '& .challenge_bar_parent':{
      margin: 0,
      padding: '27px 3rem 0',
      height: '177px',
      '@media (max-width:767px)':{
        padding: '85px 34px 0 12px',
        height: '367px',
      },

    },
    '& .partition-group':{
      '& > span':{
        '&:after':{
          left: '88px',
          width: 'calc(100% - 136px)',
          height: '1px',
          '@media (max-width:767px)':{
            left: '43px',
            width: 'calc(100% - 77px)',
          }
        }
      },
    },
    '& .challenge_bar_label_indicator':{
      '@media (max-width: 767px)':{
        paddingLeft: '42px',
        paddingRight: '35px',
      }
    }
    
  },
}), {index: 1});


export const ChallengeCheckbox = withStyles({
  root: {
    color: '#323232',
    '&$checked': {
      color: '#323232',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
