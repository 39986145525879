import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';



export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        height: 'calc(100vh - 150px)',
        '@media (max-width: 767px)':{
            height: 'calc(100vh - 272px)',
            paddingBottom: '30px',
            '&.scrollBarOff':{
                overflow: 'hidden',
            }
        }
    },
    iconButton: {
        padding: '0 10px 0 0'
    }
}), {index: 1});
export const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: '#121e31',
        padding: '12px 24px',
        letterSpacing: '0',
        fontWeight: "700",
        borderRadius: '0',
        fontSize: '16px',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
        position: 'static',
        '& .MuiSvgIcon-root': {
            // fontSize: '17px'
            fill: '#000'
        },
        '@media (max-width:767px)':{
            fontSize: '16px',
        }
        // '@media (max-width:767px)':{
        //     '&:last-child':{
        //         position: 'relative',
        //         zIndex: '52',
        //     }
        // }
    },
    body: {
        fontSize: 16,
        fontWeight: "400",
        borderRadius: '0',
        color: '#000',
        padding: '12px 24px',
        letterSpacing: '0',
        textTransform: 'Capitalize',
        whiteSpace: 'nowrap',
        '@media (max-width:767px)':{
            fontSize: '16px',
        }
    },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
    root: {
        // '&:nth-of-type(odd)': {
        //   backgroundColor: theme.palette.action.hover,
        // },
        // backgroundColor: "#F7F9FA",
        border: "0px",
        borderRadius: "0",
    },
}))(TableRow);

export const StyledPaper = withStyles((theme) => ({
    root: {
        padding: '0',
        backgroundColor: '#fff',
        borderRadius: "0",
        boxShadow: 'none',
        '@media (max-width: 767px)':{
            position: 'relative',
            marginTop: '64px',
            '&.faddedOn': {
                '&:before':{
                    content:"''",
                    position: 'absolute',
                    top: '51px',
                    bottom: '0',
                    width: '100%',
                    background: 'linear-gradient(90deg, rgba(238, 238, 238, 0) 0%, rgba(255,255,255,1) 100%)',
                    right: '0',
                    pointerEvents: 'none',
                    zIndex: '50',
                }
            }
        }
    }
}))(Paper);

export const TableStyle = {
    borderCollapse: 'separate',
    borderSpacing: '0px 10px',
}

export const ColorButton = withStyles((theme) => ({
    root: {
      color: '#fff',
      backgroundColor: '#000000',
      fontSize: '14px',
      '&:hover': {
        backgroundColor: '#737D85',
      },
    },
  }))(Button);