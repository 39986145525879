import { PieChart } from 'react-minimal-pie-chart';

function DashboardPieChart({vals}) {
    let val1 = 0;
    let val2 = 0;
    let val3 = 0;
    if (((vals[0] / vals[1]) * 100).toFixed(0) !== 'NaN' && ((vals[0] / vals[1]) * 100).toFixed(0) !== 'infinity') {
        val1 = ((vals[0] / vals[1]) * 100).toFixed(0);
    }
    if (((vals[4] / vals[1]) * 100).toFixed(0) !== 'NaN' && ((vals[4] / vals[1]) * 100).toFixed(0) !== 'infinity') {
        val2 = ((vals[4] / vals[1]) * 100).toFixed(0);
    }
    if (((vals[5] / vals[1]) * 100).toFixed(0) !== 'NaN' && ((vals[5] / vals[1]) * 100).toFixed(0) !== 'infinity') {
        val3 = ((vals[5] / vals[1]) * 100).toFixed(0);
    }
    if (val1+val2+val3 > 100.00) {
        if (val1 !== 0) {
            val1 = val1 --;
        } else if (val2 !== 0) {
            val2 = val2 --;
        } else if (val3 !== 0) {
            val3 = val3 --;
        }
    }

    if(val1 === 0 && val2 === 0 && val3 === 0){
        return <p>No data to show!</p>
    }

    return (
        <>
            <PieChart
                style={{ maxHeight: "28vh" }}
                viewBoxSize={[140, 140]}
                animate={true}
                lineWidth={50}
                paddingAngle={2}
                label={({ dataEntry }) => {return(dataEntry.title+' '+dataEntry.value.toString())}}
                labelPosition={112}
                center={[70, 70]}  
                labelStyle={{
                    fill: "#777",
                    fontSize: ".5rem",
                    fontFamily: "Gesta"
                }}
                data={[
                    { title: 'Invited', value: parseInt(val1), color: '#E38627' },
                    { title: 'In Progress', value: parseInt(val2), color: '#C13C37' },
                    { title: 'Completed', value: parseInt(val3), color: '#6A2135' }
                ]}
            />
        </>
    );
}

export default DashboardPieChart;