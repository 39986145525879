import React, {useContext} from 'react';
import Sidebar from './Sidebar';
import ContentArea from '../components/ContentArea';
import Layout from '../components/Layout';
import { Helmet } from "react-helmet";
import { AuthContext } from "../context/auth";

const PAGE_NAME = 'Clients';

function Client() {
    const { logout } = useContext(AuthContext);
    return (
        <Layout>
            <Helmet>
                <title>Client Dashboard</title>
            </Helmet>
            <Sidebar logout={logout} />
            <ContentArea pageName={PAGE_NAME} />
        </Layout>

    )
}

export default Client;