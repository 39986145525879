import React, {useContext, useEffect} from 'react';
import { Link } from "react-router-dom";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {useStyles} from '../styles/ErrorStyles';
import Container from '@material-ui/core/Container';
import SidebarClient from '../components/homeDashboard/Sidebar';
import SidebarAdmin from '../dashboard/Sidebar';
import { AuthContext } from '../context/auth';

import bg from "../assets/imgaes/desktop-mountain-bg.png";
import mobBg from "../assets/imgaes/mobile-mountain-header.png";
import exclamation from "../assets/imgaes/exclamation.png";

const NotFound = () => {
    const classes = useStyles();
    const {user, logout} = useContext(AuthContext);
    const dashboardUrl = user ? (user.isAdmin ? "/dashboard/home" : "/dashboard") : "";
    const buttonText = user ? (user.isAdmin ? "Go to Dashboard" : "Go to Dashboard") : "";

    useEffect(() => {
        if(!user){
            logout()
        }
    }, [])
    
    return (
    <Container maxWidth={false} disableGutters={true} className={`${classes.error} mx-auto flex justify-between w-100 flex-col lg:flex-row relative`} >
    {
        user ? (user.isAdmin ? 
        <SidebarAdmin user={user} logout={logout} /> : 
        <SidebarClient user={user} logout={logout} reportsActive={ user.currentSessionId.status === "COMPLETED" ? true : false } />) : ""
    }
    <Grid container className={`ml-0 lg:ml-52  bg-concrete overflow-auto`}>
        <Grid item xs={12} >
            <main className={'error_page bg-contain bg-no-repeat bg-center-top min-h-screen bg-concrete '} >
                <picture>
                    <source media="(max-width:767px)" srcSet={mobBg} />
                    <img src={bg} alt="Flowers"/>
                </picture>
                <Box className='flex items-center justify-center flex-col '>
                    <Box className='w-full lg:w-2/4 flex items-center justify-center flex-col px-10 '>
                        <Box className='exclamation-icon'>
                            <img src={exclamation} alt='' />
                        </Box>
                        <Box className=''>
                            <h1 className='text-black text-6xl font-medium md:font-bold rajdhani'>
                            404 page not found
                            </h1>
                        </Box>
                        <Box className='center text-center'>
                            <p className='text-black text-lg gesta md:mt-6 md:mb-16 mt-3 mb-12'>
                            We are sorry but the page you are looking for does not exist.
                            </p>
                            <Link to={dashboardUrl} className='text-white text-lg gesta-medium leading-relaxed px-12 uppercase py-3.5 bg-bigstone inline-block'>
                                {buttonText}
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </main>
        </Grid>
    </Grid>
    </Container>
    )
}
export default NotFound