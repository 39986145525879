
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    sidebarTitle: {
        color: '#636363',
    },

    sidebarButton: {
        width: '60px',
        height: '60px',
        backgroundColor: '#FA8C36'
    },
    mobileSidebar:{
        '& .MuiPaper-root':{
            display: 'flex',
            paddingBottom: '40px',
            flexDirection: 'column',
            justifyContent: 'space-between'
        },
    },
    navUl: {
        a: {
            transition: 'all 0.3s ease-in-out',

            '&:hover': {
                color: '#EF4050',
                transition: 'all 0.3s ease-in-out'
            },

            '&:active': {
                transition: 'all 0.3s ease-in-out',
                color: '#EF4050'
            }
        },

        active: {
            color: '#EF4050'
        }
    },
    homeSidebarMenu: {
        '& li': {
            '& a': {
                fontSize: "18px",
                lineHeight: "22px",
                fontWeight: '500',
                whiteSpace: 'break-spaces',
                '&.active':{
                    color: '#EF4050',
                }
            }
        }
    },
    adminPopup: {
        '& li': {
            fontSize: '17px',
        }
    }

   
}), {index: 1});