// Material  Ui
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// User Defined
import {
  useStyles,
  StyledTableCell,
  StyledTableRow,
  StyledPaper,
  TableStyle,
} from "../styles/ClientListStyles";
/* import AdminTopTabMenu from "./AdminTopTabMenu";
import DashboardReport from "./DashboardReport"; */

function ClientList(props) {
  const classes = useStyles();
  // Sorting and Searching states, create company modal open state etc
  /* const [sortFor, setSortFor] = useState("_id");
  const [sortDir, setSortDir] = useState(-1);
  const [clientId, setClientId] = useState("");
  const childRef = useRef();
  const { searchFor, haveListHeader } = props; */
  const data = props.showData;
  const haveListHeader = true;

  //console.log("data", data)

  // Get Clients as infinite scroll with fetchMore
  /* const { data, fetchMore } = useQuery(GET_CLIENTS, {
    variables: {
      limit: 10,
      searchFor,
      sortFor,
      sortDir,
    },
  }); */

  // Show loading if needed
  //if (!data || !data.clients) return <div>Loading...</div>;

  // Sets sorting column and direction
  /* function handleSorting(col) {
    setSortFor(col);
    setSortDir((prev) => (prev = prev === 1 ? -1 : 1));
  } */

  return (
    <StyledPaper className={`${classes.root} ${haveListHeader ? '' : ' rounded-none '}`}>
        <TableContainer className={`${classes.container} ${haveListHeader ? ' ' : ' rounded-none '}`}>
          <Table stickyHeader aria-label="sticky table" style={TableStyle}>
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">
                  Client
                </StyledTableCell>
                <StyledTableCell align="left">
                  Role
                </StyledTableCell>
                <StyledTableCell align="left">
                  Company
                </StyledTableCell>
                <StyledTableCell align="left">
                  Journey Status
                </StyledTableCell>
                <StyledTableCell align="left">
                  Status
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((client, i) => {
                var bgColor = (client.status === "CANCELLED" || client.status === "SUSPENDED") ? "bg-brandColor" : "bg-shamrock";
                bgColor += " text-white p-2 rounded-full text-xs";
                return (
                  <StyledTableRow key={i}>
                    <StyledTableCell>
                      {client.clientId.firstName} {client.clientId.lastName}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {client.clientId.role}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {client.clientId.company}
                    </StyledTableCell>
                    <StyledTableCell align="left">{client.status}</StyledTableCell>
                    <StyledTableCell align="left">
                      <span className={bgColor}>
                        {client.clientId.status}
                      </span>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
    </StyledPaper>
  );
}

export default ClientList;
