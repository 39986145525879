import { Fragment } from "react";

//Material Design
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Link } from "react-router-dom";
// User Defined
import {OverViewStyle} from '../styles/ClientOverviewStyle';

export default function AdminTopTabMenu(props) {
    const classes = OverViewStyle();
    const { tabMenuItem, handleTab, activeTabItem } = props;

  return (
    <Fragment>
      <Box component="div" className="flex md:items-center md:justify-between pb-2 border-b-4 border-customgray2 md:pt-24 pt-20 md:px-9 px-6 md:flex-row	flex-col" style={{background:'#f2f2f1'}}>
        <Box component="div" className="inline-flex md:items-center md:justify-center">
            <Link to='/dashboard/clients' className='text-bigstone font-medium uppercase text-sm md:text-lg inline-flex items-center justify-center'>
                <ArrowBackIosIcon className="inline-flex mr-1 text-base"/>
                Return to client list
            </Link>
        </Box>
        <Box component="div" className="md:mx-0 -mx-6">
          <ButtonGroup variant="text" className="w-full md:mt-0 mt-7 justify-between">
              {
                  tabMenuItem
                  .map(
                      (item,i)=> {
                          let assessmentDisabled = false;
                          if(item.text.toLowerCase() === "assessment" && props.userProgress !== "COMPLETED"){
                            assessmentDisabled = true;
                          }
                          return(
                            <Button 
                                key={i}
                                style={{border: 'none'}}
                                onClick={() => handleTab(item.id)}
                                className={` ${classes.tabButton} ${activeTabItem === item.id ? ' active ' : ''}`}
                                disabled={assessmentDisabled}
                            >
                                {item.text}
                            </Button>
                          )
                      }
                  )
              }
                
          </ButtonGroup>
        </Box>
      </Box>
    </Fragment>
  );
}
