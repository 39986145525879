// Material Ui
import Box from "@material-ui/core/Box";
import CloseIcon from '@material-ui/icons/Close';
import {cModalStyle} from '../styles/ConfirmationModalStyle'
import attention from '../assets/imgaes/warning.svg'




function ConfirmationModal(props) {
    const classes = cModalStyle(props);
   //Destrucring the props
    const {message, handleConfirmationModal} = props;

    return (
      <Box className={`${classes.attention} attention`}>
        <div id="alert-dialog-slide-title" className='text-left md:px-8 px-9 gesta-medium bg-yellow-200 pt-5 pb-2'>
          <span className='flex text-lg gesta-bold text-black'>
            <img src={attention} alt="Attention" className='mr-2'/>
            Unanswered Questions
          </span>
        </div>
        <div className={` ${classes.root} md:px-8 text-left bg-yellow-200 gesta-regular text-lg text-black pb-5 px-9`} >
          <div id="alert-dialog-slide-description" className='md:text-lg text-sm gesta text-black'>
           {message}
            <CloseIcon onClick={() => handleConfirmationModal(true)} style={{fill:'#333'}} className="p-2.5 h-12 w-12 absolute right-0 top-0 cursor-pointer" />
          </div>
        </div>
      </Box>
    );
}

export default ConfirmationModal;