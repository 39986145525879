import React from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

function ScrollTop({gotoTop}) {
    return (
        <>
            <span 
            className="scroll_top inline-flex h-10 items-center cursor-pointer justify-center w-6 border border-dovegray bg-dovegray fixed bottom-10 right-1"
            onClick={gotoTop}
            title="Click here to Go to Top"
            >
                <ExpandLessIcon className='text-white'/>
            </span>
        </>
    );
}

export default ScrollTop;