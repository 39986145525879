// To check a password between 8 to 20 characters which contain at least one 
// numeric digit, one uppercase, one lowercase letter and one special character(not "<" and ">")

export default function passwordValidate(pass) {
    if(pass.match(/[><]/)){
        return false;
    }
    const regEx = /^(?=.{8,20})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[`~!@#$%^&*()-+={[}\]|:;"',.?\\/_]).*$/;
    return (pass.match(regEx));
}

export function emailValidate(email) {
    const regEx = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
    return (email.match(regEx));
}