import { useState } from "react";
// Material Ui
import Typography from "@material-ui/core/Typography";
import { Box, Link } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// User Defined
import { challengeStyles } from "../../styles/ChallengeQuestionModalStyle";
import ChallengeQuestionDialogHead from "./ChallengeQuestionDialogHead";
import SvgIcon from "../../components/SvgIcon";

function FinalModal({chg, vals, sender}) {
  const classes = challengeStyles();
  const [alternate, setAlternate] = useState(false);

  return (
    <div className="scaleit__fadeIn">
      <Typography className="px-10 md:py-10 py-2 flex justify-center">
        <SvgIcon clName="trophyicon animate-bounce h-12 md:h-28"  icon="trophy" />
      </Typography>
      <ChallengeQuestionDialogHead
        headingTitle="Thank you for completing the Seven Navigation Challenges!"
        headingContent=""
        hasHeadingContent={false}
      />
      <Box className="lg:px-7  px-4 border-t border-b border-cerulean py-5">
        <Box className="flex items-center justify-between w-full">
          <Typography
            component=""
            className={`font-bold text-gray-800 gesta-regular text-xs break-all ${classes.FinalModalInfoText}`}
          >
            We sent your results to the following email:{" "}
            <Link className="text-gray-800" color="inherit">
              <b>&nbsp;{vals}</b>
            </Link>
          </Typography>
          <Typography
            className={`text-cerulean text-xs  `}
          >
            <Link
              className='text-xs gesta '
              color="inherit"
              onClick={() => setAlternate(true)}
            >
              &nbsp;Send to a different email
            </Link>
          </Typography>
        </Box>
        {
          // condition for alternative text
          alternate ? (
            <Box className="flex items-center justify-between w-full pt-3">
              <Typography
                component=""
                className={`font-bold text-gray-800 text-xs break-all ${classes.FinalModalInfoText}`}
              >
                New Email Address:
              </Typography>
              <span className="alternative_email">
                <TextField
                  name="alternativeEmail"
                  id="alternativeEmail"
                  label="Email"
                  size="small"
                  onChange={chg}
                  value={vals}
                  variant="outlined"
                />
              </span>
              <Button onClick={sender} className="bg-cerulean rounded-none text-white">Send</Button>
            </Box>
          ) : (
            ""
          )
        }
      </Box>
    </div>
  );
}

export default FinalModal;
