import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

// Importing Client Steps Cmponent
import CoachSignUpStep1 from "./signupsteps/CoachSignUpStep1";
import CoachSignUpStep2 from "./signupsteps/CoachSignUpStep2";
import { ChallengeButtonStyle as ChallengeBtn } from "../styles/CommonStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Profile Details", "Contact Information", "Confirmation"];
}

function getStepContent(step, props) {
  switch (step) {
    case 0:
      return (
        <CoachSignUpStep1 chg={props.chg} aChg={props.aChg} sub={props.sub} vals={props.vals} coach={props.coach} />
      );
    case 1:
      return (
        <CoachSignUpStep2 chg={props.chg} sub={props.sub} vals={props.vals} coach={props.coach} />
      );
    default:
      return (
        <CoachSignUpStep1 chg={props.chg} aChg={props.aChg} sub={props.sub} vals={props.vals} coach={props.coach} />
      );
  }
}

export default function CoachSignUpStep(props) {
  const { coach } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  var createText = "Create Coach";
  if (coach.id !== undefined) {
    createText = "Update Coach";
  }
  
  

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      setActiveStep(0);
      props.opn(false);
    } else if (activeStep === steps.length - 2) {
      props.sub();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  // return function
  return (
    <div className={`${classes.root}`}>
      <div>
        {
          <div>
            <div className={classes.instructions}>
              {getStepContent(activeStep, props)}
            </div>
            <Box className="mt-20 flex justify-center gap-x-5">
              {
                activeStep === 0 ? <ChallengeBtn
                onClick={props.handleModalClose}
                variant="contained"
                className={`uppercase flex items-center justify-center border border-black`}
                style={{
                  backgroundColor: "white",
                  color: "black",
                  borderColor: "black",
                }}
              >
                Close
              </ChallengeBtn> :  <ChallengeBtn
                disabled={activeStep === 0}
                onClick={handleBack}
                variant="contained"
                className={`uppercase flex items-center justify-center border border-black`}
                style={{
                  backgroundColor: "white",
                  color: "black",
                  borderColor: "black",
                }}
              >
                <KeyboardArrowLeftIcon />
                Previous
              </ChallengeBtn>
              }
             

              <ChallengeBtn
                onClick={handleNext}
                variant="contained"
                className={`uppercase flex items-center justify-center border`}
                style={{
                  backgroundColor: "white",
                  color: "black",
                  borderColor: "black",
                }}
              >
                <KeyboardArrowRightIcon />
                {activeStep === steps.length - 1 ? "Finish" : 
                activeStep === steps.length - 2 ? createText : "Next"}
              </ChallengeBtn>
            </Box>
          </div>
        }
      </div>
    </div>
  );
}
