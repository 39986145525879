import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,width: '631px',
        maxWidth: '100%',
        boxShadow: 'none',
        background: '#f9f9f9',
        transition: 'all .30s linear',
        borderRadius: '0',
        padding: '1rem',
        pointerEvents: 'all',
        margin: '0 auto 50px',
        position: 'relative',
        '@media only screen and (max-width:767px)': {
            background: '#fff',
            padding: '0 2rem 0rem',
        }
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    dialog: {
        '&.setPassword':{
            '& .MuiDialog-paper': {
                
            },
            '& .MuiBackdrop-root': {
                backgroundColor: 'transparent',
                pointerEvents: 'none',
            },
            '& .MuiPaper-elevation24': {
            },
            '& .MuiDialog-scrollPaper': {
                alignItems: 'flex-start',
                paddingTop: '66px',
                pointerEvents: 'none',
                '@media (min-width: 768px) and (min-height: 767px)':{
                    paddingTop: '153px'
                }
            },
        },
        '& .MuiDialog-paper': {
            width: '876px',
            maxWidth: '876px',
            height: 'initial',
            maxHeight: 'initial',
            transition: 'all .30s linear',
            '@media only screen and (max-width:1023px)': {
                overflow: 'scroll',
                height: '100vh',
            }
        },
        '& .dialog_inner': {
            maxWidth: '450px',
            margin: '0 auto',
        }
    },
    resetTitle: {
        fontFamily: 'gesta',
        fontWeight: '500',
        '@media (max-width: 767px)':{
            fontWeight: '700',
        }
    },
    passwordField: {
        '& label': {
            fontSize: '16px',
            fontFamily: 'gesta',
            fontWeight: '400',
        },
        '& input': {
            width: '345px'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#cdcecd',
            },
            '&:hover fieldset': {
                borderColor: '#0096D1',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#0096D1',
            },
        },
    },
    checkBoxParent: {
        borderBottom: '1px solid #bdbdbd'
    },
    checkBoxGroup: {
        '& .MuiFormControlLabel-root': {
            alignItems: 'flex-start',
            marginRight: '11px',
        }
    },
    checkBoxLabel: {
        color: '#000',
        fontSize: '18px',
        fontFamily: 'gesta',
        fontWeight: '400',
        marginRight: '0',
        lineHeight: '24px',        
        '@media (max-width: 767px)':{
            fontSize: '16px',
        },
        '& a': {
            color: '#000'
        }
    },
    checkBox: {
        padding: '0 9px',
        color: '#121e31 !important',
        '&$checked': {
            color: '#121e31 !important',
        },
        '& .MuiSvgIcon-root': {
            height: '1.25rem',
            width: '1.25rem',
            marginTop: '1px',
        }
    },
    resetPassRulesTitle: {
        color: '#000',
        fontSize: '18px',
        fontFamily: 'gesta',
        fontWeight: '700',
        marginBottom: '10px'
    },
    resetPassRulesList: {
        paddingLeft: '40px',
        listStyle: 'disc',
        marginBottom: '1.5rem',
        '& li': {
            color: '#000',
            fontSize: '16px',
            fontFamily: "gesta",
            fontWeight: '400',
            display: 'list-item',
            '& .MuiSvgIcon-root': {
                fontSize: '5px',
                display: "inline-block",
                marginRight: '5px'
            }
        },
        '@media (max-width: 767px)':{
            paddingLeft: '30px',
        }
    },
    resetRulesNote: {
        color: '#000000',
        fontSize: '16px',
        lineHeight: '1.5',
        fontFamily: 'gesta',
        marginTop: '0.75rem',
        marginBottom: '0.5rem',
        '@media (max-width: 767px)': {
            fontStyle: 'italic',
        }
    },
    resetButton: {
        color: '#fff',
        letterSpacing: '0',
        fontSize: '18px',
        fontFamily: "gesta",
        fontWeight: '500',
        lineHeight: '1',
        padding: '15px 47px',
        backgroundColor: '#121e31',
        borderRadius: '0px',
        border: '0',
        boxShadow: 'none',
        '&:hover':{
            backgroundColor: '#121e31',
            boxShadow: 'none',
        },
        '& .MuiTouchRipple-root': {
            display: 'none'
        },
        '@media (max-width: 767px)':{
            padding: '15px 20px',
            width: '100%',
        }
    }
}), {index: 1});

export const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

export const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        minWidth: '528px'
    },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);