import { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from '../hooks/form';
// Material UI
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { Typography } from '@material-ui/core';

// User Defined
import { ADD_COACH, GET_COACHES, UPDATE_USER } from '../util/graphql';
import CoachSignUpStep from './CoachSignUpStep';
import SnackBarAlert from './SnackBarAlert';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CoachSignUpModal = forwardRef((props, ref) => {
    // Errors and Open states
    const { coach } = props;
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState(false); 
    const [msg, setMsg] = useState('');
    //Snackbar Alert State
    const [setOpenSnackBar] = useState(true);
    //const [errors, setErrors] = useState();

    //Snackbar alert Close Handeler
    const handleCloseSnackbar = (event, reason)=> {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackBar(false);
    }

    // New Client modal control
    useImperativeHandle(ref, () => ({
        getModal() {
            setOpen(true);
        }
    }));
  
    //Form input handling
    const { onChange, onAvtChange, onSubmit, values, setValues } = useForm(addCoachCallback, {
        firstName: '',
        lastName: '',
        phone: '',
        role: '',
        avatar: '',
        email: '',
        password: '',
        confirmEmail: '',
        bio: ''
    });

    useEffect(() => {
        if (coach.id) {
            setValues({ ...values, 
                'firstName': coach.firstName,
                'lastName': coach.lastName,
                'phone': coach.phone,
                'role': coach.role,
                'avatar': coach.avatar,
                'email': coach.email,
                'confirmEmail': coach.email,
                'bio': coach.bio,
                'id': coach.id
            });
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coach])

    // Add coach mutation // Values from the form
    const [addCoach] = useMutation(ADD_COACH, {
        variables: { input: values },
        refetchQueries: [{ query: GET_COACHES, variables: props.variables }],
        onError(err) {
            //console.log('err', err);
            setAlert(true);   
            setMsg("Client could not be created at this time!");
            setTimeout(() => {setAlert(false)}, 3000);
            //setErrors(err.graphQLErrors[0].extensions.exception.errors);
        }
    });

       // Add coach mutation // Values from the form
       const [updateCoach] = useMutation(UPDATE_USER, {
        variables: { input: values },
        refetchQueries: [{ query: GET_COACHES, variables: props.variables }],
        onError(err) {
            //console.log('err', err);
            setAlert(true);   
            setMsg("Client could not be created at this time!");
            setTimeout(() => {setAlert(false)}, 3000);
            //setErrors(err.graphQLErrors[0].extensions.exception.errors);
        }
    });

    // Once form submitted, Company is created then closes the modal
    function addCoachCallback() {
        if (values.firstName.trim() === '') {
            setAlert(true);   
            setMsg("Please enter a first name for the coach!");
            setTimeout(() => {setAlert(false)}, 3000);
            return false;
        } else if (values.bio.trim() === '') {
            setAlert(true);   
            setMsg("Please enter biography of the coach!");
            setTimeout(() => {setAlert(false)}, 3000);
            return false;
        } else if (values.email !== values.confirmEmail) {
            setAlert(true);   
            setMsg("Emails don't match! Please enter matching email addresses");
            setTimeout(() => {setAlert(false)}, 3000);
            return false;
        }
        if (coach.id) {
            updateCoach();
        } else {
            addCoach();
        }
            
        //setOpen(false);
    }

    // New Client modal close
    const handleModalClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    return (
        <>
        {
        alert ? <SnackBarAlert severity='error' alertMessage={msg} handleCloseSnackbar={handleCloseSnackbar} openSnackBar={alert} vertical='top' horizontal='center' autoHideDuration={6000} />: <></> 
        }
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        >
            <DialogContent className='flex justify-center flex-col text-center'
            style={{padding: '100px 50px'}}>
                <Typography
                className='font-light text-gray-500 text-4xl opacity-100 border-b pb-5' >
                    {coach.id ? 'Update Coach' : 'Create Coach'}
                </Typography>
                <Box>
                    <form action="#">
                        <CoachSignUpStep 
                        chg={onChange} sub={onSubmit} vals={values} 
                        coach={coach} aChg={onAvtChange}
                        opn={setOpen} handleModalClose={handleModalClose} />
                    </form>
                </Box>
                
            </DialogContent>
        </Dialog>
        </>
    )
});

export default CoachSignUpModal;