//
import NavigationChallengeCard from "./NavigationChallengeCard";

function index({number,color,title,text,imgIcon}) {
    return (
        <NavigationChallengeCard
        number={number}
        color={color}
        title={title}
        text={text}
        imgIcon={imgIcon}
        />
    );
}

export default index;