import { makeStyles } from '@material-ui/core/styles';


export const ReportAccordionStyle = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    reportAccordion: {
        // border: "1px solid #E0E0E0",
        borderRadius: "0 !important",
        margin: "9px 0 0 !important",
        boxShadow: "none",
        '&:before':{
            display: 'none'
        },
        '& .MuiButtonBase-root': {

        },
        '& .MuiAccordionSummary-content': {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "0px 0",
        },
        "& .question_heading": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            fontFamily: 'Gesta',
            maxWidth: "100%",
            flex: "100%",
            letterSpacing: '0',
            fontSize: '18px',
            lineHeight: '1.4',
            '& img':{
                marginRight: '18px'
            },
            "& svg": {
                height: "27px",
                width: "44px",
            },
            '@media (max-width: 767px)':{
                fontSize: '15px',
            }
        },
        "& .capabilities": {
            fontFamily: 'gesta',
            fontSize: "18px",
            color: "#333333",
            lineHeight: "40px",
            maxWidth: '20%',
            flex: '20%',
        },
        "& .important": {
            fontFamily: 'gesta',
            fontSize: "18px",
            color: "#333333",
            lineHeight: "40px",
            maxWidth: '20%',
            flex: '20%',
        },
        "& .question_border":{
            width: "5px",
            transition: 'all .30s ease-in',
        },
        '& .report_accordion_body': {
            background: '#fff',
            padding: "0px 0px 0px",
        },
        
    },
    questionListAnswers: {
        '& ul':{
            padding: '0px !important',
            '& li':{
                borderBottom: 'solid 1px #f2f2f2',
                '&:last-child':{
                    borderBottom: 'none'
                }
            }
        },
        '& .inner_question_title': {
            fontFamily: 'gesta',
            fontSize: "18px",
            color: "#636363",
            maxWidth: "80%",
            flex: "0 0 80%",
            lineHeight: '1.3',
            '@media (max-width: 767px)':{
                maxWidth: "100%",
                flex: "0 0 100%",
                fontSize: '14px',
            }
        },
        '& .inner_question_capability': {
            fontFamily: 'gesta',
            fontSize: "17px",
            lineHeight: '1.3',
            maxWidth: "20%",
            flex: "0 0 20%",
            '@media (max-width: 767px)':{
                maxWidth: "100%",
                flex: "0 0 100%",
                fontSize: '16px',
                marginTop: '11px',
            }
        },
        '& .inner_question_importance': {
            fontFamily: 'gesta',
            fontSize: "17px",
            lineHeight: '1.3',
            maxWidth: "20%",
            flex: "0 0 20%",
            '@media (max-width: 767px)':{
                maxWidth: "100%",
                flex: "0 0 100%",
                fontSize: '16px',
                marginTop: '11px',
            }
        }
    }
    
}), {index: 1});
  