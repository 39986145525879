import React from 'react';
import KeyInsights from './KeyInsights';

function index({ averages, keyGaps,tabberValues}) {
    return (
        <KeyInsights
            averages={averages}
            keyGaps={keyGaps}
            tabberValues={tabberValues}
        />
    );
}

export default index;