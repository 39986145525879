import React, { useRef, useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { CSVLink } from "react-csv";

// Material Ui
import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";

import ClientList from "./ClientList";
import CompanyList from "./CompanyList";
import CoachList from "./CoachList";
import { useStyles, BootstrapButton } from "../styles/CoachDashboardStyles";
import AddIcon from "@material-ui/icons/Add";
import ClientSignUpModal from "./ClientSignUpModal";
import json2mq from "json2mq";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import newClient from "../assets/imgaes/addUser.svg";
import exportData from "../assets/imgaes/database-export.svg";
import { GET_CSV } from "../util/graphql";


function ContentArea(props) {
  const [searchFor, setSearchFor] = useState("");
  const [refetchRequired, setRefetchRequired] = useState(true);
  const childRef = useRef();

  const [csvData, setCsvData] = useState([]);
  const [fileDownloadReady, setFileDownloadReady] = useState(false);
  const csvLink = useRef();

  useEffect(() => {
    if (csvLink && csvLink.current && fileDownloadReady) {
        csvLink.current.link.click();
        setFileDownloadReady(false);
        setCsvData([]);
    }
  }, [fileDownloadReady]);

  const [loadCsvData] = useLazyQuery(GET_CSV, {
    fetchPolicy: 'network-only', 
    onCompleted: (data) => {
        setCsvData(JSON.parse(data.getCsv));
        setFileDownloadReady(true);
    }
  });

  const isMobile = useMediaQuery(
    json2mq({
      maxWidth: 767,
    })
  );
  
  useEffect(() => {
    if (isMobile) {
    }
  }, [isMobile]);
  
  const refetchHandler = (value) => {
    setRefetchRequired(value);
  }

  var page = "";

  switch (props.pageName) {
    case "Clients":
      page = <ClientList searchFor={searchFor} haveListHeader={true} refetchRequired={refetchRequired} refetchHandler={refetchHandler} />;
      break;
    case "Companies":
      page = <CompanyList searchFor={searchFor} />;
      break;
    case "Coaches":
      page = <CoachList searchFor={searchFor} />;
      break;
    default:
      page = <CompanyList searchFor={searchFor} />;
  }

  const classes = useStyles();

  return (
    <Box className="content_area lg:ml-52">
      {/* Modal */}
      <ClientSignUpModal
        ref={childRef}
        variables={{
          limit: 10,
          searchFor,
        }}
        refetchHandler={refetchHandler}
      />
      {/* Modal */}
      <div className="content_area__inner h-screen">
        <Grid container className="content_area__top_bar relative flex justify-between flex-row md:pt-16 md:pb-8 md:px-12 pt-24 pb-16 px-9 md:flex-wrap flex-nowrap">
          <Grid item
          className="content_area__top_bar_left xl:pl-6 flex items-center">
            <h1 className="content_area__top_bar_left_label font-medium text-mineshaft">
              {props.pageName}
            </h1>
          </Grid>

          <Grid
            item
            className="content_area__top_bar_right lg:text-right pt-0 flex"
          >
            <form className="d-inline-flex md:static absolute inset-x-8 bottom-0 z-50 justify-center md:mb-0 -mb-6">
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                </div>
                <InputBase
                  placeholder="Search Client Name…"
                  onChange={(e) => setSearchFor(e.target.value)}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                />
                <BootstrapButton className={classes.searchBtn}>
                  <SearchIcon />  
                </BootstrapButton>
              </div>
            </form>
            {!isMobile ?
              (<><BootstrapButton className="ml-5 headerBtn" 
                onClick={() => childRef.current.getModal()}
                >
                  
                New Client
                <AddIcon fontSize="small" className="ml-1 -mr-1" />
              </BootstrapButton>
              <BootstrapButton className="ml-5 headerBtn" onClick={loadCsvData}>
                Export Data
              </BootstrapButton>
              <CSVLink
                    data={csvData}
                    filename='output.csv'
                    className='hidden'
                    ref={csvLink}
                    target='_blank'
                /></>)
            : <><BootstrapButton className="ml-2 h-12	w-12 headerBtn" 
                onClick={() => childRef.current.getModal()}
                >
                  
                <img src={newClient} alt=""/>
              </BootstrapButton>
              <BootstrapButton className="ml-2 h-12	w-12 headerBtn">
                <img src={exportData} alt=""/>
              </BootstrapButton></>
            }
          </Grid>
        </Grid>

        <Grid item className="content_area__container relative bg-white" xs={12}>
          {page}
        </Grid>
      </div>
    </Box>
  );
}

export default ContentArea;
