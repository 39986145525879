import React from 'react';
import Box from '@material-ui/core/Box';

function ReportCard({ title, text, icon, color, imgIcon }) {

   const makeCapitalize = (text) =>{
        if(text){
            let lowerCased = text.toLowerCase();
            let capilize = lowerCased.charAt(0).toUpperCase()+lowerCased.slice(1);
            return capilize;
        }
        return text;
   }

    return (
        <Box className="mt-0 md:mt-0">
            { title !== "" ?
            <div className="flex items-center justify-start py-1 mb-7">
                <span className={`bg-${color} inline-flex h-11 w-11 mr-4 items-center justify-center`}>
                    {/* <SvgIcon clName='cicon' icon={icon}/> */}
                    <img src={imgIcon} alt="" className='imgIcon' />
                </span>
                <h6 className="gesta font-normal text-mineshaft capitalize reportTitle" >
                    {makeCapitalize(title)}
                </h6>
            </div> : ''
            }  
            <div>
                <p className="gesta font-normal text-minishaft reportDesc">
                    {text}
                </p>
            </div>
        </Box>
    );
}

export default ReportCard;